import mixpanel from "mixpanel-browser";
import { faPersonDigging } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { UserPageContainer } from "./common";

export function useComingSoonAlert() {
  return (caption: string, event: string) => {
    try {
      mixpanel.track(event);
    } finally {
      alert(caption);
    }
  };
}

export function ComingSoon({ caption }: { caption: string }): JSX.Element {
  const [cnt, setCnt] = React.useState(0);
  React.useEffect(() => {
    let timer = setTimeout(() => setCnt((cnt + 1) % 3), 500);
    return () => clearTimeout(timer);
  }, [cnt]);
  return (
    <UserPageContainer>
      <div className=" flex justify-center align-middle flex-row h-full">
        <div className="flex-0 text-center">
          <div style={{ fontSize: "10rem" }} className="mb-3">
            <FontAwesomeIcon icon={faPersonDigging} />
          </div>

          <div className="text-4xl ">
            {caption}
            {[...Array(3)].map((_, i) => (
              <span className={i > cnt ? "opacity-0" : ""}>.</span>
            ))}
          </div>
        </div>
      </div>
    </UserPageContainer>
  );
}
