import {
  Feature_Flags_Enum,
  useForceAddAttendeeMutation,
} from "shared/dist/__generated__/components";

import React from "react";
import { SpinnerButton } from "../../../widgets/spinner-button";
import clsx from "clsx";
import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";

export function ForceAddAttendee({ event_id }: { event_id: string }): JSX.Element {
  const isEnabled = useIsFeatureEnabled(Feature_Flags_Enum.EventsForceAddAttendeeV00);
  const [inviteeSlug, setInviteeSlug] = React.useState("");
  const [error, setError] = React.useState<null | string>(null);
  const [addUserToEvent, addUserToEventResults] = useForceAddAttendeeMutation();
  const onSubmit = React.useCallback(
    async (e: Pick<Event, "stopPropagation" | "preventDefault">) => {
      try {
        e.stopPropagation();
        e.preventDefault();
        setError(null);
        if (!event_id || event_id.trim().length < 12) {
          setError("missing event Id");
          return;
        }
        if (!inviteeSlug || inviteeSlug.trim().length < 3) {
          setError("Invitee cannot be blank");
          return;
        }
        await addUserToEvent({
          variables: { event_id, user_slug: inviteeSlug },
        }).catch((e) => setError("could not add user"));
        setInviteeSlug("");
      } catch (e) {
        alert("An error occurred. Please try again.");
      }
    },
    [inviteeSlug]
  );
  if (!isEnabled) {
    return <></>;
  }
  const loading = addUserToEventResults.loading;
  return (
    <div className="w-full gap-2 lg:w-1/2">
      <form onSubmit={onSubmit} className="">
        <div className="form-control min-w-auto w-full">
          <label className="label">
            <span className="label-text">Force Add Attendee</span>
          </label>
          <label className="join">
            <span className={clsx("join-item")}>@</span>
            <input
              type="text"
              placeholder="art_vandelay"
              value={inviteeSlug}
              onChange={(e) => setInviteeSlug(e.target.value)}
              className="join-item input input-bordered focus:outline-none w-full"
            />
            <SpinnerButton className="btn " disabled={loading} loading={loading} onClick={onSubmit}>
              submit
            </SpinnerButton>
          </label>
          <label className="label">
            <span className="text-error label-text-alt">{error ?? "\u00A0"} </span>
          </label>
        </div>
      </form>
    </div>
  );
}
