import { Feature_Flags_Enum, L_Vouch_Level } from "shared/dist/__generated__/components";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { faHeart, faShieldHeart, faStars } from "@fortawesome/pro-regular-svg-icons";

import { ContentBox } from "../../../widgets/content-box";
import { Maybe } from "shared/dist/util";
import clsx from "clsx";
import { match } from "ts-pattern";
import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";

export type VouchInfoBoxProps = { screenName: string; level: Maybe<L_Vouch_Level> };
export function VouchInfoBox({ level, screenName }: VouchInfoBoxProps): null | JSX.Element {
  const enabled = useIsFeatureEnabled(Feature_Flags_Enum.VouchingV00);
  if (!level || !enabled) return null;
  return (
    <ContentBox className="px-3 pb-1">
      {match(level)
        .with(L_Vouch_Level.TopUser, () => <TopUser screenName={screenName} />)
        .with(L_Vouch_Level.HighlyRecommended, () => <HighlyRecommended screenName={screenName} />)
        .with(L_Vouch_Level.Recommended, () => <Recommended screenName={screenName} />)
        .exhaustive()}
    </ContentBox>
  );
}

function RecommendationBox({
  title,
  body,
  iconClassName,
  icon,
}: { iconClassName?: string; title: string; body: string } & Pick<
  FontAwesomeIconProps,
  "icon"
>): null | JSX.Element {
  return (
    <div className="flex flex-row justify-start items-center gap-2 p-2">
      <FontAwesomeIcon className={clsx(iconClassName)} icon={icon} size="2x" />
      <div className="flex-col items-start justify-center text-left gap-1">
        <p className={clsx("font-bold")}>{title}</p>
        <p className={clsx("")}>{body}</p>
      </div>
    </div>
  );
}

function HighlyRecommended({
  screenName,
}: Pick<VouchInfoBoxProps, "screenName">): null | JSX.Element {
  return (
    <RecommendationBox
      title="Highly Recommended"
      body={`${screenName} is vouched for by a large number of Candid users`}
      iconClassName="text-accent"
      icon={faShieldHeart}
    />
  );
}

function Recommended({ screenName }: Pick<VouchInfoBoxProps, "screenName">): null | JSX.Element {
  return (
    <RecommendationBox
      title="Recommended"
      body={`${screenName} is vouched for by Candid users`}
      iconClassName="text-secondary"
      icon={faHeart}
    />
  );
}

function TopUser({ screenName }: Pick<VouchInfoBoxProps, "screenName">): null | JSX.Element {
  return (
    <RecommendationBox
      title="Top User"
      body={`${screenName} amongst the most highly vouched for users on Candid`}
      iconClassName="text-[color-mix(in_oklab,oklch(var(--a)),black_7%)]"
      icon={faStars}
    />
  );
}
