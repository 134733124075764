import { Maybe, filterNulls, sortBy, uniqBy } from "shared/dist/util";
import { ProfileList, ProfileListItem } from "../../widgets/profile-list";
import {
  Thread_List_Details_412Fragment,
  useUserThreads412Query,
} from "shared/dist/__generated__/components";
import { faFaceParty, faImage } from "@fortawesome/pro-solid-svg-icons";

import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { allRoutes } from "../../util/routes";
import clsx from "clsx";
import { useMyId } from "shared/dist/auth-data";

type ThreadData = Thread_List_Details_412Fragment;
function threadToListItem(myId: string) {
  return (
    thread: Maybe<ThreadData>
  ): null | { thread_id: string; last_msg_dt?: DateTime; item: ProfileListItem } => {
    if (!thread) return null;
    const unblockedMembers = thread.members?.filter(
      (member) =>
        !(member.member_id === myId) &&
        !member.user_public?.this_user_blocked_by_users?.length &&
        !member.user_public?.users_blocked_by_this_user?.length
    ).length;
    if (unblockedMembers < 1 && !thread.event?.id) return null;

    const msg = thread?.messages?.[0];
    const caption = (
      <span>
        {msg?.message_media_uploads?.[0] && (
          <FontAwesomeIcon icon={faImage} className={clsx("mr-2")} />
        )}
        {msg?.content?.slice(0, 200)}
      </span>
    );
    return {
      thread_id: thread.id,
      last_msg_dt: thread.messages?.[0]?.created_at
        ? DateTime.fromISO(thread.messages?.[0]?.created_at)
        : undefined,
      item: {
        avatarOverride: thread.event?.id ? (
          <div className="w-full flex h-full justify-center items-center">
            <FontAwesomeIcon
              icon={faFaceParty}
              className="rounded-full  mx-auto w-full h-full fixed-width"
            />
          </div>
        ) : undefined,
        caption,
        subCaption: thread?.messages?.[0]?.created_at
          ? DateTime.fromISO(thread?.messages?.[0]?.created_at).toLocaleString(
              DateTime.DATETIME_SHORT
            )
          : undefined,
        slug: thread?.members
          // ?.filter((m) => m.user_public?.id !== myId)
          ?.map((m) => m.user_public?.slug)
          ?.filter(Boolean)?.[0],
        screenName:
          thread.event?.name ??
          (
            thread?.members
              // ?.filter((m) => m.user_public?.id !== myId)
              ?.map((m) => m.user_public?.screen_name)
              ?.filter(Boolean) ?? []
          ).join(", "),
      },
    };
  };
}

export function ThreadList(): JSX.Element {
  const myId = useMyId()!;
  const { data, loading } = useUserThreads412Query({
    skip: !myId,
    variables: { limit: 100, my_id: myId },
    fetchPolicy: "cache-and-network",
    pollInterval: 1000 * 60,
  });
  const threads = filterNulls([
    ...(data?.event_attendees?.flatMap((ea) => ea.event?.threads) ?? []),
    ...(data?.thread_members?.map((tm) => tm.thread) ?? []),
    ...(data?.events?.flatMap((tm) => tm.threads) ?? []),
  ]).filter((t) => t.event?.id || !!t.messages?.length);
  console.log("🚀 - file: thread-list.tsx:81 - ThreadList - data:", data);
  console.log("🚀 - file: thread-list.tsx:91 - ThreadList - threads:", threads);
  const threadListItems: Array<{
    item: ProfileListItem;
    thread_id: string;
  }> = React.useMemo(
    () =>
      sortBy(
        uniqBy(filterNulls(threads.map(threadToListItem(myId))), (t) => t.thread_id),
        (t) => -1 * (t.last_msg_dt?.toMillis() ?? 0)
      ),
    [data]
  );
  const unreadSet = React.useMemo(
    () =>
      new Set(
        threads
          ?.filter(
            (thread) =>
              thread.messages?.[0]?.sender_id !== myId &&
              (!thread.last_viewed_at?.[0]?.last_viewed_at ||
                thread.messages?.[0]?.created_at > thread.last_viewed_at?.[0]?.last_viewed_at)
          )
          .map((t) => t.id)
      ),
    [data]
  );
  console.log("🚀 - file: thread-list.tsx:88 - ThreadList - threads:", threads);
  return (
    <div className="overflow-y-auto pb-10 max-h-full">
      {loading && !threadListItems?.length ? (
        <SpinnerCentered />
      ) : threadListItems.length > 0 ? (
        <ProfileList
          columnCountOverride={1}
          className="p-4"
          hasBadge={(_profile, idx) => {
            return unreadSet.has(threadListItems[idx]?.thread_id);
          }}
          mkRoute={({}, idx) => ({
            to: allRoutes.MESSAGING.LIST.THREAD.buildPath({
              thread_id: threadListItems[idx]?.thread_id ?? "foo",
            }),
          })}
          profiles={threadListItems.map(({ item }) => item)}
        />
      ) : (
        <div className="text-white py-2 text-lg font-semibold text-center">No messages yet..</div>
      )}
    </div>
  );
}

// return (
//   <FullScreenSubMenuSideBar>
//     <div className="tabs flex w-full flex-nowrap mb-4 justify-center">
//       <a
//         onClick={() => setTab("in_network")}
//         className={classNames(
//           "tab tab-bordered text-white ",
//           tab === "in_network"
//             ? "border-white" //  text-white"
//             : "border-candid-gray-700" // text-candid-gray-700"
//         )}
//       >
//         Main
//       </a>
//       <a
//         onClick={() => setTab("external")}
//         className={classNames(
//           "tab tab-bordered text-white ",
//           tab === "external"
//             ? "border-white " //  text-white"
//             : "border-candid-gray-700" // text-candid-gray-700"
//         )}
//       >
//         Other
//       </a>
//     </div>
//     <div className="overflow-y-auto">
//       {loading ? (
//         <SpinnerCentered />
//       ) : profiles.length > 0 ? (
//         <ProfileList
//           columnCountOverride={1}
//           className="p-4"
//           hasBadge={(_profile, idx) => {
//             return unreadSet.has(profiles[idx]?.thread_id);
//           }}
//           mkRoute={({ screenName, slug }, idx) => ({
//             state: { title: screenName, slug },
//             to: allRoutes.MESSAGING.THREAD.buildPath({
//               thread_id: profiles[idx]?.thread_id ?? "foo",
//             }),
//           })}
//           // profiles={[...profiles, ...profiles, ...profiles, ...profiles].map(
//           profiles={profiles.map(({ item, thread_id }) => item)}
//         />
//       ) : (
//         <div className="text-white py-2 text-lg font-semibold text-center">
//           No messages yet..
//         </div>
//       )}
//     </div>
//   </FullScreenSubMenuSideBar>
// );
