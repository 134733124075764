import {
  L_Bio_Field,
  useSetUrlMutation,
  useValidateFieldLazyQuery,
} from "shared/dist/__generated__/components";
import React, { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ObScreen } from "./widgets";
import { ObScreenProps } from "../../widgets/bio/bio-types";
import { ScreenNameEditor } from "../../widgets/pickers/screen-name-editor";
import { classNames } from "shared/dist/util";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { match } from "ts-pattern";
import mixpanel from "mixpanel-browser";
import { useMyId } from "shared/dist/auth-data";

export function ObBasics({ setLoading, setDisableButton }: ObScreenProps): JSX.Element {
  const [name, setNameInner] = React.useState("");
  React.useEffect(() => {
    try {
      mixpanel.track("bio_onboarding");
    } catch (e) {
      console.error("MP Error: ", e);
    }
  }, []);
  const [setUrlMutation, setUrlResults] = useSetUrlMutation();
  const [nameFormLoading, setNameFormLoading] = React.useState(false);
  const [validateField] = useValidateFieldLazyQuery();
  const my_id = useMyId()!;
  const validateAndSetUrl = React.useCallback(
    async (newScreenName: string) => {
      setDisableButton?.(true);
      const baseUrl = newScreenName
        .toLowerCase()
        .replace(/[^a-z0-9]/g, "_")
        .replace(/-+/g, "_");
      if (!my_id) return;
      const validationResult = await validateField({
        fetchPolicy: "no-cache",
        variables: { field: L_Bio_Field.UrlSlug, value: baseUrl },
      });
      await match(validationResult?.data?.l_validate)
        .with({ __typename: "L_Simple_Response_Success" }, () =>
          setUrlMutation({ variables: { slug: baseUrl, id: my_id } })
        )
        .otherwise(async () =>
          setUrlMutation({
            variables: { slug: `${baseUrl}${Math.floor(Math.random() * 999999)}`, id: my_id },
          })
        );
      setNameInner(newScreenName);
      setDisableButton?.(false);
      setLoading?.(false);
      setNameFormLoading(false);
    },
    [validateField, setUrlMutation, my_id, setDisableButton]
  );
  const anyLoading = setUrlResults.loading || nameFormLoading;

  useEffect(() => {
    setLoading && setLoading(anyLoading);
    if (!name || name.length < 3) setDisableButton?.(true);
  }, [anyLoading, setLoading, setDisableButton, name]);

  return (
    <ObScreen
      title="What should we call you?"
      subtitle="This is what your display name will be on the Candid app."
    >
      <div
        className={classNames(
          "flex flex-col gap-0 max-h-full min-h-0 h-full w-full overflow-hidden items-stretch"
        )}
      >
        <ScreenNameEditor
          hideReset
          onSave={async (screenName: string) => {
            validateAndSetUrl(screenName);
          }}
          onChange={() => setNameInner("")}
          onLoading={(b) => setNameFormLoading(b)}
          dontLoadDbValue
          suppressToast
          placeholder="DreamyDancer"
          saveComponentOverride={<FontAwesomeIcon icon={faCheck} fixedWidth />}
          hideSubmitInstructions
          className="border-b border-gray-400 pl-1 rounded-none w-full"
        />
        <div className="flex-1" />
      </div>
    </ObScreen>
  );
}
