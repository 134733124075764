import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "shared-web-react/dist/widgets/floating-ui/tooltip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SpinnerButton } from "shared-web-react/dist/widgets/spinner-button";
import { allRoutes } from "../../../util/routes";
import clsx from "clsx";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { useDeleteMyAccountMutation } from "shared/dist/__generated__/components";
import { useMyId } from "shared/dist/auth-data";
import { useNavigate } from "react-router-dom";

export function DeleteAccountButton(): JSX.Element {
  const my_id = useMyId();
  const [mutate, { loading }] = useDeleteMyAccountMutation();
  const addToast = useAddToast();
  const confirm = useConfirm();
  const navigate = useNavigate();
  return (
    <Tooltip>
      <TooltipTrigger>
        <TooltipContent>Soft Delete User</TooltipContent>
        <SpinnerButton
          loading={loading}
          onClickWrapped={() =>
            new Promise((resolve, reject) => {
              if (!my_id) return resolve(null);
              confirm({
                title: "Delete Your Account?",
                content: "This cannot be undone.",
                onOk: async () => {
                  await mutate({ variables: { user_id: my_id } });
                  addToast({
                    content: "Deleted your account",
                    color: "success",
                  });
                  navigate(allRoutes.logout.buildPath({}));
                  resolve(null);
                },
              });
            })
          }
          disabled={loading}
          className={clsx("btn btn-warning ")}
        >
          <span className={clsx("flex items-center gap-2")}>
            <FontAwesomeIcon icon={faTrash} size="2x" />
            Delete Your Account
          </span>
        </SpinnerButton>
      </TooltipTrigger>
    </Tooltip>
  );
}
