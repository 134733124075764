import {
  AddPromptAnswersMutationVariables,
  GqlOps,
  useAddPromptAnswersMutation,
  useProfilePromptHopeToGainQuery,
} from "shared/dist/__generated__/components";
import {
  SelectPills,
  SelectPillsChoices,
  SelectPillsProps,
} from "../../widgets/inputs/select-pills";
import { classNames, filterNulls, uniqWith } from "shared/dist/util";

import React from "react";
import { ReactState } from "shared/dist/util/types";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { useMustBeLoggedIn } from "../../util/routes";

export function HopeToFindPicker({
  activeChoicesState,
  className,
  isForBio,
  loadingState,
  labelOverride,
  size,
}: {
  activeChoicesState?: ReactState<Array<string>>;
  className?: string;
  isForBio?: boolean;
  labelOverride?: string | JSX.Element | React.ReactNode;
  loadingState?: ReactState<boolean>;
} & Pick<SelectPillsProps, "size">): JSX.Element {
  const my_id = useMustBeLoggedIn() ?? "";
  const { data, loading, error } = useProfilePromptHopeToGainQuery({
    variables: { my_id },
  });
  const choices =
    data?.profile_prompts_by_pk?.question_answer_choices?.en_US
      ?.split("|")
      ?.filter?.((e) => e.length)
      ?.map((value) => ({ value })) ?? [];
  const currentAnswersInDb = data?.profile_prompts_by_pk?.answers?.[0]?.value
    ?.split("|")
    .filter((e) => e.length);
  const [activeChoices, setActiveChoices] =
    activeChoicesState ??
    React.useState<Array<string>>(uniqWith(filterNulls(currentAnswersInDb)) ?? []);
  const [addAnswersMutation] = useAddPromptAnswersMutation();
  const onChange = React.useCallback(
    async (choices: SelectPillsChoices) => {
      const answers: AddPromptAnswersMutationVariables["answers"] = [
        {
          prompt_id: "profile_prompt_hope_to_gain",
          show_on_bio: true,
          value: uniqWith(choices.map((e) => e.value)).join("|"),
        },
      ];
      loadingState?.[1]?.(true);
      await addAnswersMutation({
        variables: { answers },
        refetchQueries: [GqlOps.Query.ProfilePromptHopeToGain],
      }).finally(() => {
        setActiveChoices?.(choices.map((c) => c.value));
        loadingState?.[1]?.(false);
      });
    },
    [loadingState, setActiveChoices, addAnswersMutation]
  );
  return (
    <div className={`${classNames()} ${className}`}>
      {loading ? (
        <SpinnerCentered />
      ) : (
        <>
          <SelectPills
            topLabel={
              !isForBio ? labelOverride ?? data?.profile_prompts_by_pk?.question_title?.en_US : null
            }
            size={size}
            bottomLabel={!isForBio ? `Choose up to 10 (${activeChoices.length} / 10)` : undefined}
            maxChoices={10}
            defaultValue={currentAnswersInDb}
            choices={choices}
            onChange={onChange}
            className={className}
            isForBio={isForBio}
          />
        </>
      )}
    </div>
  );
}
