import {
  GqlOps,
  Thread_Message_Full_340Fragment,
  useThreadMessages340Query,
  useUpdateThreadViewed300Mutation,
} from "shared/dist/__generated__/components";
import { sortBy, uniqWith } from "shared/dist/util";

import { DateTime } from "luxon"; /* cSpell:disable-line */
import React from "react";
import { useMyId } from "shared/dist/auth-data";

// retaining this for reference..
// type DeepOmit<T, K> = T extends Array<infer U>
//   ? Array<DeepOmit<U, K>>
//   : T extends object
//   ? {
//       [F in Exclude<keyof T, K>]: DeepOmit<T[F], K>;
//     }
//   : T;

export type StoredThreadMessage = Omit<Thread_Message_Full_340Fragment, "created_at"> & {
  created_at_dt: DateTime;
};

const sortMsgs = (arr: Array<StoredThreadMessage>) =>
  sortBy(arr, (m) => m.created_at_dt.toMillis());
export type ThreadMessagesStoreData = {
  fetchOlder: () => void;
  loading: boolean;
  data: { thread_messages: Array<StoredThreadMessage> };
  messageOfInterest: null | StoredThreadMessage;
};

export function useSetThreadRead(threadId: string): () => Promise<void> {
  const myId = useMyId();
  const [setViewedMutation] = useUpdateThreadViewed300Mutation();
  return React.useCallback(async () => {
    if (!myId) return;
    setViewedMutation({
      refetchQueries: [
        GqlOps.Query.UserThreads412,
        GqlOps.Query.ThreadsIndicator,
        GqlOps.Query.ThreadsIndicator220,
      ],
      variables: {
        thread_id: threadId,
        member_id: myId!,
        viewed_at: new Date().toISOString(),
      },
    });
  }, [setViewedMutation, myId, threadId]);
}

export function useThreadMessagesStore(threadId: string): ThreadMessagesStoreData {
  const myId = useMyId();
  // const [lastMessages, setLastMessages] =
  //   React.useState<null | Array<Thread_Message_Full_340Fragment>>(null);
  const { data, previousData, loading, fetchMore, refetch } = useThreadMessages340Query({
    skip: !myId || !threadId,
    fetchPolicy: "cache-and-network",
    variables: {
      thread_id: threadId,
      my_id: myId!,
      filter: {},
    },
  });
  const messages = data?.thread_messages ?? [];
  const { thread_messages, threadMessageIds, messageOfInterest } = React.useMemo(() => {
    // console.log(
    // "🚀 - file: thread-messages-store.ts:98 previousData:",
    // previousData?.thread_messages?.length,
    // previousData?.thread_messages?.[0]
    // );
    // console.log(
    // "🚀 - file: thread-messages-store.ts:98 data:",
    // data?.thread_messages?.length,
    // data?.thread_messages?.[0]
    // );
    const allWithDt = sortBy(
      messages
        .filter((m) => m.thread_id === threadId)
        .map((m) => ({ ...m, created_at_dt: DateTime.fromISO(m.created_at) })),
      (m) => m.created_at_dt.toMillis()
    );
    const threadMessageIds = new Set(allWithDt.map((m) => m.id));
    const previousMessageIds = new Set(previousData?.thread_messages?.map((m) => m.id) ?? []);
    // console.log(
    // "🚀 - file: thread-messages-store.ts:132 - const{thread_messages,messageOfInterest}=React.useMemo - allWithDt:",
    // allWithDt
    // );
    const last = sortBy(
      allWithDt
        .filter((m) => !previousMessageIds.has(m.id))
        .map((m) => ({ ...m, created_at_dt: DateTime.fromISO(m.created_at) })),
      (m) => m.created_at_dt.toMillis()
    );
    // console.log(
    // "🚀 - file: thread-messages-store.ts:137 - const{thread_messages,messageOfInterest}=React.useMemo - last:",
    // last
    // );
    return {
      thread_messages: allWithDt,
      threadMessageIds,
      messageOfInterest: last.length
        ? last[last.length - 1]
        : allWithDt.length
        ? allWithDt[allWithDt.length - 1]
        : null,
    };
  }, [previousData, data]);
  const fetchOlder = React.useCallback(async () => {
    // console.log("🚀 - file: thread-messages-store.ts:56 fetchOlder", { loading, myId });
    if (loading || !myId) return;
    if (previousData?.thread_messages && !previousData.thread_messages.length) return;
    // if (messages.length > 0 && !lastMessages.length) return;
    const filter = thread_messages[0]
      ? {
          _or: [
            { created_at: { _lte: thread_messages[0].created_at } },
            {
              created_at: { _gte: thread_messages[thread_messages.length - 1].created_at },
            },
          ],
        }
      : {};
    // console.log(
    // "🚀 - file: thread-messages-store.ts:75 - fetchOlder - filter:",
    // JSON.stringify(filter)
    // );
    const last = await fetchMore({
      variables: {
        thread_id: threadId,
        my_id: myId!,
        filter,
      },
    });
    // setLastMessages(last.data.thread_messages?.filter((m) => !threadMessageIds.has(m.id)));
  }, [thread_messages, previousData, fetchMore, loading, myId]);
  return {
    data: { thread_messages },
    messageOfInterest,
    fetchOlder: () => {
      // console.log("🚀 - file: thread-messages-store.ts:123 - useThreadMessagesStore - fetchOlder:");
      fetchOlder();
    },
    loading,
  };
}

export function useFakeDataThreadMessagesStore(
  threadId: string,
  fakeDataOpts: {
    memberIds: Array<string>;
  }
): ThreadMessagesStoreData {
  const limit = 25;
  const memberIds = fakeDataOpts.memberIds;
  const myId = useMyId();
  const [messages, setMessages] = React.useState<Array<StoredThreadMessage>>([]);
  const [messageOfInterest, setMessageOfInterest] = React.useState<null | StoredThreadMessage>(
    null
  );
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    // console.log("🚀 - file: thread-messages-store.ts:52 - initial load", memberIds);
    setLoading(true);
    const timer = setTimeout(() => {
      const newMessages = sortMsgs(
        [...new Array(limit)].map(() =>
          mkMessage({
            memberIds,
            threadId,
            minDt: DateTime.now().minus({ days: 7 }),
            maxDt: DateTime.now().minus({ days: 1 }),
          })
        )
      );
      setMessages(newMessages);
      setMessageOfInterest(newMessages[newMessages.length - 1]);
      setLoading(false);
    }, 2500);
    return () => timer && clearTimeout(timer);
  }, [JSON.stringify(memberIds)]);
  const fetchOlder = React.useCallback(async () => {
    // console.log("🚀 - file: thread-messages-store.ts:69 - fetchOlder - fetchOlder:");
    if (loading) return;
    if (messages.length > 200) return;
    setLoading(true);
    setTimeout(() => {
      const prevMin = DateTime.fromMillis(
        Math.min(...messages.map((m) => m.created_at_dt.toMillis()))
      );
      const newMessages = sortMsgs(
        [...new Array(limit)].map(() =>
          mkMessage({
            memberIds,
            threadId,
            minDt: prevMin.minus({ days: 7 }),
            maxDt: prevMin.minus({ minutes: 1 }),
          })
        )
      );
      setMessageOfInterest(newMessages[newMessages.length - 1]);
      setMessages(sortMsgs([...messages, ...newMessages]));
      setLoading(false);
    }, 1500);
  }, [messages, setMessages, threadId, memberIds, loading, setLoading]);
  return {
    data: { thread_messages: sortBy(messages, (m) => m.created_at_dt.toMillis()) },
    messageOfInterest,
    fetchOlder,
    loading,
  };
}

/* ----- random helpers ----- */
const lorem =
  /* cSpell:disable */
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore " +
  "et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut " +
  "aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse " +
  "cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in " +
  "culpa qui officia deserunt mollit anim id est laborum.";
/* cSpell:enable */

const mkLorem = (wordCount: number) => lorem.split(" ").slice(0, wordCount).join(" ");

function mkId() {
  /* cspell: disable-next-line */
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const mkMessage = ({
  minDt,
  maxDt,
  memberIds,
  threadId,
}: {
  memberIds: Array<string>;
  threadId: string;
  minDt: DateTime;
  maxDt: DateTime;
}): StoredThreadMessage => ({
  id: mkId(),
  thread_id: threadId,
  content: mkLorem(1 + Math.floor(Math.random() * 20)),
  sender_id: memberIds[Math.floor(Math.random() * memberIds.length)],
  message_media_uploads_aggregate: { aggregate: { count: 0 } },
  sender_summary: { users_blocked_by_this_user: [], this_user_blocked_by_users: [] },
  created_at_dt: DateTime.fromMillis(
    minDt.toMillis() + Math.floor(Math.random() * (maxDt.toMillis() - minDt.toMillis()))
  ),
});
