import { ObPageProps, ObPagePropsWithNext } from "../../screens/onboarding/widgets";
import React, { useCallback, useState } from "react";
import { faCircleCheck, faExclamationTriangle, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { useBirthdayQuery, useUpdateBirthdayMutation } from "shared/dist/__generated__/components";

import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useMyId } from "shared/dist/auth-data";
import { useRefetchMyBioSummary } from "../../screens/user-page/common";

export function validateBirthday(dt: DateTime, setError?: (e: string | null) => void) {
  const over18Years = DateTime.now().minus({ years: 18 }) > dt;
  setError?.(over18Years ? null : "Sorry, you must be over 18 to join Candid");
  return over18Years;
}

export type BirthdayInputProps = {
  loading?: boolean;
  className?: string;
  dirty?: boolean;
} & React.HTMLProps<HTMLInputElement>;

export function BirthdayInput({
  loading,
  className,
  onBlur,
  onChange,
  dirty,
  ...props
}: BirthdayInputProps): JSX.Element {
  const [error, setError] = React.useState<null | string>(null);
  return (
    <div className="BirthdayInput form-control w-full flex-1">
      <label className="w-full join">
        <input
          className={`join-item input input-bordered flex-1 text-left align-left focus:outline-none min-w-0 ${className}`}
          type="date"
          min={DateTime.now().minus({ years: 105, days: 1 }).toISODate() ?? undefined}
          onChange={(e) => {
            const dt = e.target.value ? DateTime.fromISO(e.target.value) : null;
            if (!dt) return;
            const valid = validateBirthday(dt, setError);
            if (valid) onChange?.(e);
          }}
          onBlur={(e) => {
            const dt = e.target.value ? DateTime.fromISO(e.target.value) : null;
            if (!dt) return;
            const valid = validateBirthday(dt, setError);
            if (valid) onBlur?.(e);
          }}
          {...props}
        />
        <button disabled={loading || !dirty} className={clsx("btn join-item btn-primary")}>
          <FontAwesomeIcon
            icon={loading ? faSpinner : error ? faExclamationTriangle : faCircleCheck}
            className={clsx(!dirty && "text-gray-300")}
            spin={loading}
            fixedWidth
          />
        </button>
      </label>
      <span className="label-text-alt text-error">{error ?? "\u00A0"}</span>
    </div>
  );
}

export function BirthdayPicker(): JSX.Element {
  const my_id = useMyId()!;
  const refetchBio = useRefetchMyBioSummary();
  const [saveMutation, saveMutationResult] = useUpdateBirthdayMutation();
  const { data, loading } = useBirthdayQuery({
    variables: { user_id: my_id },
  });
  const [inputDate, setInputDate] = React.useState<DateTime | null>(null);
  const [error, setError] = React.useState<null | string>(null);
  const [dirty, setDirty] = React.useState(false);
  const [showAgeState, setShowAgeState] = React.useState(false);
  const dbDate = React.useMemo(
    () => (data?.users_by_pk?.birthday ? DateTime.fromISO(data.users_by_pk.birthday) : undefined),
    [data?.users_by_pk?.birthday]
  );
  // const addToast = useAddToast();
  const save = React.useCallback(
    async (dt: DateTime | null, display: boolean) => {
      if (!dt) return;
      const valid = validateBirthday(dt, setError);
      console.log("🚀 - file: birthday-picker.tsx:96 - dt:", dt, valid);
      if (!valid) return;
      saveMutation({
        variables: {
          display,
          dt: dt.toISODate(),
          user_id: my_id,
        },
      })
        // .then(() => addToast({ unitary: true, content: "Saved!" }))
        .then(refetchBio);
    },
    [saveMutation, inputDate, setError]
  );

  const anyLoading = loading || saveMutationResult.loading;
  const displayDate = inputDate?.toISODate?.() ?? dbDate?.toISODate();
  return (
    <div>
      <div>
        <label className="label">Birthday</label>
      </div>
      <div
        className={clsx(
          "flex justify-stretch w-full items-stretch mb-2",
          "flex-col max-lg:space-y-2 ",
          "lg:flex-row lg:items-start lg:justify-start lg:space-x-2 "
        )}
      >
        <BirthdayInput
          loading={anyLoading}
          disabled={anyLoading}
          dirty={dirty}
          // placeholder={"1995-01-01"}
          // defaultValue={dbDate?.toISODate() ?? "1990-01-01"}
          value={displayDate ?? "1990-01-01"}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const dt = e.target.value ? DateTime.fromISO(e.target.value) : null;
            if (!dt) return;
            setInputDate(validateBirthday(dt) ? dt : DateTime.now().minus({ years: 18, days: 1 }));
            setDirty(true);
            // save(dt, showAgeState);
          }}
          onBlur={(e) => {
            const dt = e.target.value ? DateTime.fromISO(e.target.value) : null;
            setInputDate(dt);
            save(dt, showAgeState);
            setDirty(false);
          }}
        />
        <div className="flex-1 form-control">
          <label className="label text-left justify-start pl-0">
            <input
              type="checkbox"
              checked={showAgeState}
              onChange={(evt) => {
                setShowAgeState(evt.target.checked);
                save(inputDate, evt.target.checked);
              }}
              className="checkbox"
            />
            <span className="ml-1 label-text">Show Age on Profile?</span>
          </label>
        </div>
      </div>
    </div>
  );
}

export function ObBirthdayPicker({ setDisableButton }: ObPageProps): JSX.Element {
  const my_id = useMyId()!;
  const [updateBirthdayMutation] = useUpdateBirthdayMutation();
  const { data } = useBirthdayQuery({
    fetchPolicy: "cache-first",
    variables: { user_id: my_id },
  });
  const dbDate = data?.users_by_pk?.birthday ? DateTime.fromISO(data.users_by_pk.birthday) : null;
  const [month, setMonth] = useState<string | null>(null);
  const [day, setDay] = useState<string | null>(null);
  const [year, setYear] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showAgeState, setShowAgeState] = useState(false);
  const [loadedFromDb, setLoadedFromDb] = React.useState(false);
  React.useEffect(() => {
    if (loadedFromDb) return;
    if (!dbDate) return;
    if (!month) setMonth(dbDate.month.toString());
    if (!day) setDay(dbDate.day.toString());
    if (!year) setYear(dbDate.year.toString());
    setLoadedFromDb(true);
  }, [dbDate, day, setDay, month, setMonth, year, setYear, loadedFromDb, setLoadedFromDb]);

  React.useEffect(() => {
    if (!day?.length || !month?.length || !year?.length) return;
    const birthday = DateTime.fromFormat(
      `${("0" + month).slice(-2)}/${("0" + day).slice(-2)}/${year}`,
      "MM/dd/yyyy"
    );
    console.log(
      "🚀 - file: SAVING birthday-picker.tsx:201 - handleBlur - birthday:",
      showAgeState,
      year,
      month,
      day,
      birthday
    );
    if (!birthday.isValid) {
      setError("Invalid date. Please use MM/DD/YYYY format.");
      setDisableButton(true);
      return;
    }
    const over18Years = DateTime.now().minus({ years: 18 }) > birthday;
    if (!over18Years) {
      setError("You must be at least 18 years old to join Candid.");
      setDisableButton(true);
      return;
    }
    const valid = validateBirthday(birthday, setError);
    if (!valid) {
      setError("Invalid date. Please use MM/DD/YYYY format.");
      return;
    }
    updateBirthdayMutation({
      variables: {
        display: showAgeState,
        dt: birthday.toISODate(),
        user_id: my_id,
      },
    });
    setDisableButton(false);
  }, [updateBirthdayMutation, showAgeState, day, month, year, setDisableButton]);

  return (
    <div>
      <div className="flex flex-col mb-2">
        <div className="flex space-x-2 text-5xl items-center justify-center">
          <input
            type="text"
            inputMode="numeric"
            placeholder="MM"
            maxLength={2}
            className="w-20 bg-transparent border-none border-b border-gray-600 focus:border-gray-600 focus:outline-none focus:ring-0 text-center placeholder-gray-400 text-gray-600"
            value={month ?? ""}
            onChange={(e) => setMonth(e.target.value)}
          />
          <span className="text-3xl">/</span>
          <input
            type="text"
            inputMode="numeric"
            placeholder="DD"
            maxLength={2}
            className="w-20 bg-transparent border-none border-b border-gray-600 focus:border-gray-600 focus:outline-none focus:ring-0 text-center placeholder-gray-400 text-gray-600"
            value={day ?? ""}
            onChange={(e) => setDay(e.target.value)}
          />
          <span className="text-3xl">/</span>
          <input
            type="text"
            inputMode="numeric"
            placeholder="YYYY"
            maxLength={4}
            className="w-32 bg-transparent border-none border-b border-gray-600 focus:border-gray-600 focus:outline-none focus:ring-0 text-center placeholder-gray-400 text-gray-600"
            value={year ?? ""}
            onChange={(e) => setYear(e.target.value)}
          />
        </div>
      </div>
      <span className="label-text-alt text-error">{error ?? "\u00A0"}</span>
      <div className="flex-1 form-control">
        <label className="label text-left justify-start pl-0">
          <input
            type="checkbox"
            checked={showAgeState}
            onChange={(evt) => {
              setShowAgeState(evt.target.checked);
              // save(inputDate, evt.target.checked);
            }}
            className="checkbox"
          />
          <span className="ml-1 label-text">Show Age on Profile?</span>
        </label>
      </div>
      <p className="text-gray-500 text-lg mt-1">
        Enter your birthday in MM/dd/yyyy format. Your profile will display your age, not your birth
        date.
      </p>
    </div>
  );
}
