import React from "react";
import { classNames } from "shared/dist/util";
import { colors } from "../colors.json";
import { useIsProd } from "shared/dist/util/env";
import { useNavigate } from "react-router-dom";

export function ColorTestWidget(): JSX.Element {
  const isProd = useIsProd();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (isProd) navigate("/");
  }, []);
  if (isProd) return <></>;
  return (
    <div className={"space-y-2 p-2 overflow-y-auto max-h-full"}>
      {/* <div className="space-x-2">
      {Object.keys(theme).map((t) => (
        <span key={t} className={`p-2`} style={{ background: theme[t] }}>
          {t}
        </span>
      ))}
    </div> */}
      <div className="space-x-2">
        <button className="btn ">plain</button>
        <button className="btn btn-primary">primary</button>
        {/* <span className="p-8 bg-black">
          <button className="btn btn-primary glass">glass</button>
        </span> */}
        <button className="btn btn-primary btn-outline">primary outline</button>
        <button className="btn btn-primary btn-ghost">primary ghost</button>
        <button className="btn btn-info">info</button>
        <button className="btn btn-accent">accent</button>
        <button className="btn btn-secondary">secondary</button>
        <button className="btn btn-error">error</button>
        <button className="btn btn-warning">warning</button>
        <button className="btn btn-success">success</button>
      </div>
      <div className="grid grid-cols-6 gap-2">
        {["primary", "secondary", "success", "warning", "info", "error", "accent", "neutral"].map(
          (c, idx) => (
            <React.Fragment key={idx}>
              {["", "-content"].map((mod) => (
                <div
                  key={idx + mod}
                  className={classNames(
                    "border-black border-2 p-3 bg-" + c + mod,
                    mod === "-content" ? `text-${c}` : `text-${c}-content`
                  )}
                >
                  {c + mod}
                </div>
              ))}
            </React.Fragment>
          )
        )}
      </div>
      <div className="my-2 space-y-2">
        {Object.entries(colors).map(([color, shades]) => (
          <div className="space-x-2" key={color}>
            {Object.entries(shades).map(([shade, val]) => (
              <span
                key={color + shade}
                className={`border p-2 text-black text-sm w-32 inline-block bg-${color}-${shade}`}
              >
                {`${color}-${shade}`} {val}
              </span>
            ))}
          </div>
        ))}
      </div>
      <div className="prose">
        <h1 className="text-primary">H1 heading primary</h1>
        <h1 className="">H1 heading</h1>
        <h2>H2 heading</h2>
        <h3>H3 heading</h3>
        <h4>H4 heading</h4>
        <p>this is a paragraph</p>
      </div>
    </div>
  );
}
