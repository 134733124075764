import {
  GqlOps,
  Notification_Settings_KeysFragment,
  useMyNotificationSettingsQuery,
  useSetupDefaultNotificationsMutation,
  useUpdateMyNotificationSettingsMutation,
} from "shared/dist/__generated__/components";

import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import { H5 } from "shared-web-react/dist/widgets/text";
import React from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { SpinnerCheckbox } from "shared-web-react/dist/widgets/spinner-checkbox";
import { useMyId } from "shared/dist/auth-data";

type SingleNotificationSettingProps = {
  label: string;
  settings: Notification_Settings_KeysFragment | undefined | null;
  settingKey: keyof Notification_Settings_KeysFragment;
};

function SingleNotificationSetting({
  label,
  settings,
  settingKey,
}: SingleNotificationSettingProps): JSX.Element {
  const my_id = useMyId()!;
  const [mutate, mutationResult] = useUpdateMyNotificationSettingsMutation();
  const value = settings?.[settingKey] ?? false;
  const toggleValue = React.useCallback(
    async (newValue: boolean) => {
      console.log("🚀 ~ file: notifications.tsx:52 ~ newValue:", newValue);
      return mutate({
        variables: { my_id, updates: { [settingKey]: newValue } },
      });
    },
    [mutate, settingKey, my_id, value]
  );
  const settingsMap = mutationResult.data?.update_user_notification_settings_by_pk;
  const mergedValue = !!(settingsMap?.[settingKey] ?? value);
  return (
    <div className="flex justify-between items-center">
      <SpinnerCheckbox
        onChangeWrapped={() => toggleValue(!mergedValue)}
        className="font-semibold w-full "
        checked={mergedValue}
        checkboxAtEnd
      >
        {label}
      </SpinnerCheckbox>
    </div>
  );
}

export function NotificationSettings(): JSX.Element {
  const my_id = useMyId();
  console.log("🚀 ~ file: notifications.tsx:46 ~ NotificationSettings ~ my_id:", my_id);
  if (!my_id) {
    throw new Error("missing id in notifications settings");
  }
  const [setupDefaultMutations] = useSetupDefaultNotificationsMutation();
  React.useEffect(() => {
    my_id &&
      setupDefaultMutations({
        variables: { my_id },
        refetchQueries: [GqlOps.Query.MyNotificationSettings],
      });
  }, [my_id]);
  const { data, loading, error } = useMyNotificationSettingsQuery({
    variables: { my_id },
    fetchPolicy: "cache-and-network",
  });
  console.log("🚀 ~ file: notifications.tsx:55 ~ NotificationSettings ~ data:", data);
  if (error) {
    return <ErrorComp caption={error.clientErrors.toString()} />;
  }
  if (loading) {
    return <SpinnerCentered />;
  }

  console.log(data?.user_notification_settings_by_pk);
  const settings = data?.user_notification_settings_by_pk;
  // const { __typename, user_id, ...settings } = settingsOuter ?? {};
  return (
    <div className="flex flex-row">
      <div className="flex flex-1 flex-col justify-start gap-2">
        <H5 className="pb-2">Push Notifications</H5>
        <SingleNotificationSetting
          label="Push Notification on Status Reactions"
          settings={settings}
          settingKey={"push_on_status_reaction"}
        />
        <SingleNotificationSetting
          label="Push Notification on Message Reactions"
          settings={settings}
          settingKey={"push_on_thread_message_reaction"}
        />
        <SingleNotificationSetting
          label="Push Notification on Messages"
          settings={settings}
          settingKey={"push_on_new_thread_message"}
        />
        <SingleNotificationSetting
          label="Push Notification on Friend Requests"
          settings={settings}
          settingKey={"push_on_new_friend_request"}
        />
        <SingleNotificationSetting
          label="Push Notification on Likes"
          settings={settings}
          settingKey={"push_on_new_like_request"}
        />
        <SingleNotificationSetting
          label="Push Notification on New Friends"
          settings={settings}
          settingKey={"push_on_new_friend"}
        />
        <SingleNotificationSetting
          label="Push Notification on New Partners"
          settings={settings}
          settingKey={"push_on_new_partner"}
        />
        <SingleNotificationSetting
          label="Push Notification on Accepted Likes"
          settings={settings}
          settingKey={"push_on_new_like"}
        />
        <H5 className="pb-2">Email Notifications</H5>
        {/* <div>
      <div className="flex flex-row"> */}
        <div className="flex flex-1 flex-col justify-start gap-2">
          <SingleNotificationSetting
            label="Email me when I reviece a new message"
            settings={settings}
            settingKey={"email_on_new_message_thread"}
          />
          <SingleNotificationSetting
            label="Email on Friend Requests"
            settings={settings}
            settingKey={"email_on_new_friend_request"}
          />
          <SingleNotificationSetting
            label="Email me when I have a new match"
            settings={settings}
            settingKey={"email_on_new_like_request"}
          />
          {/* <SingleNotificationSetting
            label="Send me weekly summary emails"
            value={
              data?.user_notification_settings_by_pk
                ?.email_weekly_summary ?? false
            }
            settingKey={"email_weekly_summary"}
          /> */}
        </div>
      </div>
    </div>
  );
}
