import { Onboarding_Statuses_Enum } from "shared/dist/__generated__/components";
import React from "react";
import { useApolloClient } from "shared/dist/apollo";
import { useMyId } from "shared/dist/auth-data";
import { useParams } from "react-router-dom";
import { useUserUrlSlug410Query } from "shared/dist/__generated__/components";

export function useRefetchBioV2() {
  const { slug: mySlug } = useIsMyPage();
  const cache = useApolloClient().cache;
  const refetch = React.useCallback(() => {
    const bioResponseId = cache.identify({
      __typename: "L_Bio_V2_Success",
      id: mySlug,
    });
    cache.evict({ broadcast: true, id: bioResponseId });
  }, [cache, mySlug]);
  return refetch;
}

export function useRefetchMyBio() {
  const { slug: mySlug } = useIsMyPage();
  const cache = useApolloClient().cache;
  const refetch = React.useCallback(() => {
    const bioResponseId = cache.identify({
      __typename: "L_Bio_Public_Response",
      id: mySlug,
    });
    cache.evict({ broadcast: true, id: bioResponseId });
  }, [cache, mySlug]);
  return refetch;
}

export function useIsMyPage() {
  const { slug, loading } = useMySlug() ?? {};
  const { slug: paramsSlug } = useParams();
  if (loading) {
    return { slug, loading: true, isMyPage: undefined, error: undefined };
  }
  if (!slug) {
    return {
      slug: undefined,
      loading: false,
      isMyPage: undefined,
      error: "Unable to load slug",
    };
  }
  return {
    slug,
    loading: false,
    isMyPage: slug === paramsSlug,
    error: undefined,
  };
}

/**
 *
 * @returns @deprecated use useMyInfo instead
 */
export function useMySlug(): null | {
  slug: string;
  loading: boolean;
  status: Onboarding_Statuses_Enum;
  refetch: () => Promise<unknown>;
} {
  const user_id = useMyId();
  const { data, loading, refetch } = useUserUrlSlug410Query({
    fetchPolicy: "cache-and-network",
    skip: !user_id,
    variables: { user_id: user_id ?? "" },
  });
  const user = data?.users_by_pk;
  const slug = user?.url_slug?.slug ?? null;
  // console.log("🚀 ~ file: bio-settings.tsx:45 ~ slug:", slug);
  const status = user?.onboarding_status ?? null;
  if (!slug || !status) {
    return null;
  }
  return {
    slug,
    loading,
    status,
    refetch,
  };
}

export function useMyInfo():
  | {
      slug?: null;
      loading: true;
      status?: null;
      refetch: () => Promise<unknown>;
    }
  | {
      error: "missing slug" | "missing status";
      slug?: null;
      loading: boolean;
      status?: null;
      refetch: () => Promise<unknown>;
    }
  | {
      slug: string;
      loading: boolean;
      status: Onboarding_Statuses_Enum;
      refetch: () => Promise<unknown>;
    } {
  const user_id = useMyId();
  const { data, loading, refetch } = useUserUrlSlug410Query({
    fetchPolicy: "cache-and-network",
    skip: !user_id,
    variables: { user_id: user_id ?? "" },
  });
  const user = data?.users_by_pk;
  const slug = user?.url_slug?.slug ?? null;
  // console.log("🚀 ~ file: bio-settings.tsx:45 ~ slug:", slug);
  const status = user?.onboarding_status ?? null;
  if (!slug) return { loading, error: "missing slug", refetch };
  if (!status) return { loading, error: "missing status", refetch };
  return {
    slug,
    loading,
    status,
    refetch,
  };
}
