import { UserPageTab, UserPageTabLabel } from "../../../widgets/tabs";
import { faGrid, faHeart, faLink } from "@fortawesome/pro-solid-svg-icons";

import { Container } from "../../../widgets/container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Outlet } from "react-router-dom";
import React from "react";
import { allRoutes } from "../../../util/routes";
import { classNames } from "shared/dist/util";

export function DiscoveryRoot(): JSX.Element {
  // const ref = React.useRef<HTMLDivElement>(null);
  // const [tabsHeight, setTabsHeight] = React.useState(0);
  // React.useEffect(() => {
  //   setTabsHeight(ref?.current?.clientHeight ?? 0);
  // }, [ref, setTabsHeight]);
  return (
    <div className={classNames("h-full flex flex-col justify-stretch items-stretch")}>
      <div
        className={classNames(
          "lg:mx-2",
          "backdrop-blur-sm",
          "flex-0 fit-content pt-2 relative z-20-main-ui-overlay"
        )}
        style={{ background: `rgba(255,255,255,0.75)` }}
      >
        <div className="tabs-xs md:tabs-sm lg:tabs-md tabs-bordered tabs top-0 flex w-full mt-2 flex-nowrap justify-center ">
          <UserPageTab hideIndicator route={allRoutes.DISCOVERY.buildPath({})}>
            <FontAwesomeIcon icon={faGrid} />
            <UserPageTabLabel>Discovery</UserPageTabLabel>
          </UserPageTab>
          <UserPageTab route={allRoutes.DISCOVERY.MY_INCOMING_LIKES.buildPath({})}>
            <FontAwesomeIcon icon={faHeart} />
            <UserPageTabLabel>My Likes</UserPageTabLabel>
          </UserPageTab>
          <UserPageTab route={allRoutes.DISCOVERY.MATCHES.buildPath({})}>
            <FontAwesomeIcon icon={faLink} />
            <UserPageTabLabel>Matches</UserPageTabLabel>
          </UserPageTab>
        </div>
      </div>
      <div style={{}} className={classNames("flex-1")}>
        <Outlet />
      </div>
    </div>
  );
}
