import { IndicatorNavLink } from "./indicator-nav-link";
import React from "react";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { useMatch } from "react-router-dom";

export type UserPageTabProps<PageAdt> = React.PropsWithChildren<{
  currentPage?: undefined;
  setCurrentPage?: undefined;
  thisPage?: undefined;
  route: string;
  hideIndicator?: boolean;
  style?: React.CSSProperties;
}>;

export function UserPageTab<PageAdt>({
  children,
  thisPage,
  currentPage,
  setCurrentPage,
  route,
  style,
  hideIndicator,
}: UserPageTabProps<PageAdt>): JSX.Element {
  const spanClass: React.HTMLAttributes<HTMLAnchorElement>["className"] =
    "whitespace-nowrap text-ellipsis min-w-0";
  const baseClass: React.HTMLAttributes<HTMLAnchorElement>["className"] =
    "flex-shrink flex-grow-0 min-w-0 inline-block text-ellipsis";
  const isActive = useMatch(route);
  // if (!setCurrentPage) {
  return (
    <IndicatorNavLink
      end
      style={style ? () => style : undefined}
      hideIndicator={hideIndicator}
      baseClassName={clsx(baseClass, "tab", isActive && "tab-active")}
      to={route}
    >
      <span className={spanClass}>{children}</span>
    </IndicatorNavLink>
  );
  // }
  // return (
  //   <a
  //     onClick={() => setCurrentPage(thisPage)}
  //     className={classNames(baseClass, currentPage === thisPage && "tab-active")}
  //   >
  //     <span className={spanClass}>{children}</span>
  //   </a>
  // );
}

export function UserPageTabLabel({
  children,
  className,
}: {
  children: React.ReactNode | string;
  className?: React.HTMLAttributes<HTMLSpanElement>["className"];
}): JSX.Element {
  return (
    <span className={`whitespace-nowrap text-ellipsis ml-1 ${className ?? ""}`}>{children}</span>
  );
}
