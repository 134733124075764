import {
  Bio_Basics_On_SuccessFragment,
  L_Bio_V2_User_Details,
} from "shared/dist/__generated__/components";
import { Maybe, classNames } from "shared/dist/util";
import { faGear, faGlobe } from "@fortawesome/pro-solid-svg-icons";

import { Container } from "../../widgets/container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import React from "react";
import { allRoutes } from "../../util/routes";
import { faCalendarAlt } from "@fortawesome/pro-solid-svg-icons";
import { faCompass } from "@fortawesome/pro-regular-svg-icons";
import { userPageContainerWidth } from "./common";

function BarItem({
  children,
  tooltip,
}: React.PropsWithChildren<{ tooltip?: string }>): JSX.Element {
  return (
    <>
      <div
        data-tip={tooltip ?? ""}
        className={`p-1 text-primary whitespace-nowrap flex-nowrap max-lg:text-sm max-md:text-xs${
          tooltip ? "tooltip" : ""
        }`}
      >
        {children}
      </div>
      <div className="divider divider-horizontal max-lg:mx-2 max-md:mx-1 last:hidden" />
    </>
  );
}

// function InfoBarRelationships({
//   relationships,
// }: {
//   relationships: NonNullable<BioFromSlugQuery["l_bio_public"]>["relationships"];
// }): JSX.Element {
//   const randomized = React.useMemo(
//     () => [...(relationships ?? [])]?.sort(() => 0.5 - Math.random()),
//     [relationships]
//   );
//   if (randomized.length === 0) {
//     return <></>;
//   }
//   return (
//     <BarItem>
//       <FontAwesomeIcon icon={faHeart} />
//       {randomized.map((rel, idx) => (
//         <span key={idx} className="mx-1">
//           <Link to={allRoutes.PROFILE.buildPath({ slug: rel.slug })}>{"@" + rel.slug}</Link>
//           {idx < randomized.length - 1 && ", "}
//         </span>
//       ))}
//     </BarItem>
//   );
// }

type UserDetails = Bio_Basics_On_SuccessFragment["details"];
type InfoBarProps = {
  editable: boolean;
  hideOuterBorder?: boolean;
  // narrow the type of details so this will work with both v1 and v2 implementations
  details: Maybe<
    Pick<L_Bio_V2_User_Details, "location" | "age" | "orientation" | "pronouns" | "gender">
  >;
  relationships: undefined | null;
  // | NonNullable<BioFromSlugQuery["l_bio_public"]>["relationships"];
};

export function InfoBar({
  details,
  relationships,
  hideOuterBorder,
  editable,
}: InfoBarProps): JSX.Element {
  const isEmpty =
    !details?.location &&
    !details?.age &&
    !details?.orientation &&
    !details?.pronouns &&
    !details?.gender &&
    Object.values(relationships ?? []).length === 0;
  // console.log("🚀 ~ file: info-bar.tsx:84 ~ isEmpty", {
  //   isEmpty,
  //   editable,
  //   details,
  //   relationships,
  // });

  return (
    <div className={classNames(isEmpty && !editable && "hidden")}>
      <Container size={userPageContainerWidth}>
        <div
          className={classNames(
            hideOuterBorder || "border-y-primary border-2",
            " max-w-[100vw] !text-candid-gray-700 overflow-x-auto"
          )}
        >
          <div className="px-2 flex justify-center flex-row min-w-fit overflow-x-hidden">
            {isEmpty && editable && (
              <BarItem tooltip="settings">
                <Link to={allRoutes.SETTINGS.DETAILS.buildPath({})}>
                  <FontAwesomeIcon icon={faGear} className="mr-1" />
                  <span className="italic">
                    this could use some details, tell us about yourself...
                  </span>
                </Link>
              </BarItem>
            )}
            {/* <InfoBarRelationships relationships={relationships} /> */}
            {details?.location && (
              <BarItem tooltip="Relationships">
                <FontAwesomeIcon icon={faGlobe} className="mr-1" /> {details.location}
              </BarItem>
            )}
            {details?.age && (
              <BarItem>
                <FontAwesomeIcon icon={faCalendarAlt} className="mr-1" />
                Age: {details.age}
              </BarItem>
            )}
            {details?.orientation && (
              <BarItem tooltip="orientation">
                <FontAwesomeIcon icon={faCompass} className="mr-1" />
                {details.orientation}
              </BarItem>
            )}
            {details?.pronouns && (
              <BarItem tooltip="pronouns">
                {/* <FontAwesomeIcon icon={faPeopleGroup} className="mr-1" /> */}({details.pronouns}
                )
              </BarItem>
            )}
            {details?.gender && (
              <BarItem tooltip="gender">
                {/* <FontAwesomeIcon icon={faPeopleGroup} className="mr-1" /> */}
                {details.gender}
              </BarItem>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
