import {
  GqlOps,
  L_Geo_Point_Type,
  ProfilePromptsQuery,
  useAddPromptAnswersMutation,
  useProfilePromptsQuery,
  useUserLocations500Query,
} from "shared/dist/__generated__/components";
import {
  LocationPicker,
  LocationSettings,
  useSaveLocation,
} from "../../../widgets/bio/location-picker";
import {
  SelectWithDetails,
  SelectWithDetailsOption,
} from "shared-web-react/dist/widgets/select-details";

import { Container } from "../../../widgets/container";
import { ContentBox } from "../../../widgets/content-box";
import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import { GenderPickerForUsers } from "../../../widgets/pickers/gender-picker";
import { H4 } from "shared-web-react/dist/widgets/text";
import { HopeToFindPicker } from "../../../widgets/pickers/hope-to-find-picker";
import { OrientationPicker } from "../../../widgets/bio/orientation-picker";
import { PronounsPicker } from "../../../widgets/bio/pronouns-picker";
import React from "react";
import { SpinnerFullScreen } from "shared-web-react/dist/widgets/spinner";
import { ValidatedInput } from "../../../widgets/inputs/validated-input";
import { classNames } from "shared/dist/util";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useForm } from "react-hook-form";
import { useMustBeLoggedIn } from "../../../util/routes";
import { z } from "zod";

export function ProfileSettings({
  // onNext,
  dontLoadSavedAnswers,
}: {
  onNext: () => void;
  dontLoadSavedAnswers?: boolean;
}): JSX.Element {
  const my_id = useMustBeLoggedIn() ?? "";
  // const [addAnswersMutation, { loading: mutationLoading, error: addAnswersError }] =
  //   useAddPromptAnswersMutation();
  // const [updateBirthdayMutation, { loading: updateBirthdayLoading }] = useUpdateBirthdayMutation();
  const {
    error: promptsError,
    loading: promptsLoading,
    data: promptsData,
  } = useProfilePromptsQuery({ variables: { my_id } });
  const {
    register,
    // handleSubmit,
    // reset,
    // trigger,
    setValue,
    formState: { errors },
  } = useForm<Record<string, string>>({
    mode: "all",
    defaultValues: { birthday: "1990-01-01" },
  });
  React.useEffect(() => {
    if (dontLoadSavedAnswers) {
      return;
    }
    promptsData?.profile_prompts?.forEach((p) => {
      const val = p?.answers?.[0]?.value;
      if (!val) { return; } // prettier-ignore
      setValue(p.id, val);
    });
  }, [promptsData?.profile_prompts]);
  useMustBeLoggedIn();

  // if (addAnswersError) {
  //   return <ErrorComp caption={addAnswersError.clientErrors.toString()} />;
  // }
  if (promptsError) {
    return <ErrorComp caption={promptsError.clientErrors.toString()} />;
  }
  if (promptsLoading || !promptsData) {
    return <SpinnerFullScreen />;
  }
  return (
    <Container size="xs" className={classNames("flex flex-col h-full gap-2 pb-4 items-stretch")}>
      <ContentBox className="px-3 pb-1">
        <HopeToFindPicker
          labelOverride={
            <H4>
              What <span className="normal-case">I'm</span> Looking for:
            </H4>
          }
        />
      </ContentBox>
      <ContentBox className="px-3 pb-1">
        <LocationSettings />
      </ContentBox>
      <ContentBox className="px-3 pb-1">
        <GenderPickerForUsers />
        <OrientationPicker />
        <PronounsPicker />
        {promptsData?.profile_prompts?.map((prompt) => (
          <SinglePrompt
            onSave={async () => {}}
            {...{ errors, register, prompt }}
            key={prompt.seq_no}
          />
        ))}
      </ContentBox>
    </Container>
  );
}

type Prompt = ProfilePromptsQuery["profile_prompts"][0];
type SinglePromptProps = {
  prompt: Prompt;
  //   setLoading: (b: boolean) => void;
  onSave: (s: string) => Promise<void>;
  //   loading: boolean;
};

function SinglePromptSelectOne({
  choices,
  prompt,
  onSave,
}: {
  choices: Array<string>;
} & SinglePromptProps): JSX.Element {
  const [loading, setLoading] = React.useState(false);
  const selected = prompt.answers?.[0]?.value
    ? ([prompt.answers?.[0]?.value, prompt.answers?.[0]?.value, null] as const)
    : null;
  const options = React.useMemo(() => choices.map((c) => [c, c, null] as const), [choices]);
  const addToast = useAddToast();
  return (
    <SelectWithDetails
      loading={loading}
      disableOnLoading
      options={options}
      selected={selected}
      setSelected={(value: SelectWithDetailsOption | null) => {
        console.log("🚀 ~ file: profile.tsx:247 ~ value:", value);
        if (!value) {
          return;
        }
        try {
          setLoading(true);
          onSave(value[1]).then(() => addToast({ content: "Saved!" }));
        } finally {
          setLoading(false);
        }
      }}
    />
  );
}

function SinglePromptSelectMany({}: { choices: Array<string> } & SinglePromptProps): JSX.Element {
  return <span>not implemented</span>;
}
function SinglePromptText({ onSave, prompt }: SinglePromptProps): JSX.Element {
  return (
    <ValidatedInput
      {...{
        hasBadge: false,
        defaultValue: prompt.answers?.[0]?.value ?? "",
        defaultValueTreatAsValid: true,
        submitHandler: onSave,
        onValid: onSave,
        //   className={`input input-bordered ${
        //     errors[prompt.id] ? "input-error" : ""
        //   }`}
        // validationFunction: async ({}) => null,
        fieldSchema: z
          .string()
          .min(3, { message: "Please use at least 3 characters." })
          .max(255, { message: "Please use less than 255 characters" })
          .regex(/^[a-z0-9][-A-Za-z0-9_]*[a-z0-9]$/i, {
            message: "Please use only letters, numbers, underscores, or dashes.",
          }),
      }}
    />
  );
}

function SinglePrompt({ prompt }: SinglePromptProps): JSX.Element {
  const choices = prompt.question_answer_choices?.en_US?.split("|");

  const my_id = useMustBeLoggedIn() ?? "";
  const [addAnswersMutation, { loading, error }] = useAddPromptAnswersMutation();
  const onSave = React.useCallback(
    async (value: string) => {
      addAnswersMutation({
        refetchQueries: [GqlOps.Query.ProfilePrompts],
        variables: {
          answers: [
            {
              prompt_id: prompt.id,
              show_on_bio: true,
              value,
            },
          ],
        },
      });
    },
    [addAnswersMutation, my_id, prompt]
  );
  return (
    <div className="form-control">
      <label className="label">
        <span className="label-text">
          {prompt.question_title?.en_US}
          {prompt.is_required && <span className="text-error italic opacity-70 ml-2">*</span>}
        </span>
      </label>
      {choices ? (
        prompt.answer_choices_allow_multiple ? (
          <SinglePromptSelectMany {...{ loading, error, choices, prompt, onSave }} />
        ) : (
          <SinglePromptSelectOne {...{ loading, error, choices, prompt, onSave }} />
        )
      ) : (
        <SinglePromptText {...{ loading, error, prompt, onSave }} />
      )}
      {prompt.question_subtitle?.en_US && (
        <label className="label">
          <span className={`label-text-alt`}>{prompt.question_subtitle?.en_US}</span>
          {/* {errors[prompt.id] && (
            <span className="label-text-alt text-error">
              {errors[prompt.id]?.message?.toString()}
            </span>
          )} */}
        </label>
      )}
    </div>
  );
}
