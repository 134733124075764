import { GqlOps, useSetStatusMutation } from "shared/dist/__generated__/components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { SpinnerButton } from "shared-web-react/dist/widgets/spinner-button";
import clsx from "clsx";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

export function StatusUpdate({
  setCurrentStatus,
}: {
  setCurrentStatus: (s: string) => void;
}): JSX.Element {
  const [setStatusMutation, { loading, error }] = useSetStatusMutation();
  const [status, setStatus] = React.useState("");
  const updateStatus = React.useCallback(
    async (e: Pick<Event, "stopPropagation" | "preventDefault">) => {
      e.preventDefault();
      e.stopPropagation();
      console.log("🚀 ~ file: bio-basics.tsx:55 ~ updateStatus ~ status", status);
      if (status.trim().length === 0) {
        return;
      }
      await setStatusMutation({
        refetchQueries: [
          GqlOps.Query.NewsFeed440,
          GqlOps.Query.BioV2FromSlugFeed403,
          GqlOps.Query.BioV2FromSlugBasics,
          GqlOps.Query.BioFromSlug,
          GqlOps.Query.BioSummaryAbbreviated,
        ],
        variables: { content: status.trim() },
      });
      setCurrentStatus(status);
      setStatus("");
    },
    [setStatusMutation, setStatus, status]
  );
  return (
    <form onSubmit={updateStatus} className="form-control w-full">
      <label className="join">
        <input
          type="text"
          placeholder="Update my status 🥳🎊🎉"
          maxLength={1000}
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className="join-item input !text-primary m-0 input-bordered input-sm focus:outline-none min-w-0 w-full"
        />
        <SpinnerButton
          loading={loading}
          className={clsx(
            "join-item btn btn-sm bg-primary-content !text-primary",
            " hover:bg-primary-content active:bg-primary-content btn-ghost "
          )}
          onClick={updateStatus}
        >
          <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
        </SpinnerButton>
      </label>
    </form>
  );
}
