import { DiscoveryCommonProps } from "./dating-settings-utilities";
import { H5 } from "shared-web-react/dist/widgets/text";
import { RangeSlider } from "shared-web-react/dist/widgets/range-slider";
import React from "react";
import { classNames } from "shared/dist/util";
import { useDebounce } from "use-debounce";
import { useMyId } from "shared/dist/auth-data";
import { useUpdateDiscoveryPreferences300Mutation } from "shared/dist/__generated__/components";

export function AgeRangeSettings({ disabled, onUpdate, prefs }: DiscoveryCommonProps): JSX.Element {
  const my_id = useMyId();
  // console.log("🚀 ~ file: age-settings.tsx:27 ~ prefs:", prefs);
  const [ageRange, setAgeRange] = React.useState<null | [number, number]>(null);
  React.useEffect(() => {
    if (!prefs || ageRange) { return; } // prettier-ignore
    setAgeRange([prefs.min_age, prefs.max_age]);
  }, [prefs, ageRange, setAgeRange]);
  const [mutate] = useUpdateDiscoveryPreferences300Mutation();
  const [debouncedAgeRange] = useDebounce(ageRange, 500);
  React.useEffect(() => {
    if (
      disabled ||
      !my_id ||
      !debouncedAgeRange ||
      (debouncedAgeRange[0] === prefs?.min_age && debouncedAgeRange[1] === prefs?.max_age)
    ) {
      return;
    }
    const update = {
      min_age: debouncedAgeRange[0],
      max_age: debouncedAgeRange[1],
    };
    onUpdate(update);
  }, [mutate, debouncedAgeRange]);
  return (
    <React.Fragment>
      <H5>Age Range</H5>
      Show me people mostly in this age range
      <div className={classNames("py-2")}>
        <RangeSlider
          min={18}
          max={99}
          className={classNames("h-8", disabled && "opacity-50")}
          value={ageRange ?? [18, 99]}
          onChange={(v) => v && setAgeRange([v[0], v[1]])}
          pearling
          minDistance={2}
        />
      </div>
    </React.Fragment>
  );
}
