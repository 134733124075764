import { H2, H3, H4, H5 } from "shared-web-react/dist/widgets/text";

import { Container } from "../../widgets/container";
import React from "react";
import { TermsNavButtons } from "./widgets";

function Highlight({ children }: { children: string }): JSX.Element {
  return <span className="text-candid-purple-700">{children}</span>;
}

function Section({ children }: React.PropsWithChildren<{}>): JSX.Element {
  return (
    <div className="w-full flex items-center flex-col  lg:flex-row pl-4 pr-4 prose">
      <div className="mt-6 flex-1 space-y-2">{children}</div>
    </div>
  );
}

export function PrivacyText(): JSX.Element {
  const mid = "";
  const gradients = [
    `radial-gradient(at top left, white,               ${mid} transparent)`,
    `radial-gradient(at top right,   RGB(211,190,228), ${mid} transparent)`,
    `radial-gradient(at bottom left, RGB(211,190,228), ${mid} transparent)`,
    `radial-gradient(at bottom right, white,           ${mid} transparent)`,
  ].join(", ");
  return (
    <div
      id="l_features"
      className="w-screen p-4 "
      style={{
        background: gradients,
      }}
    >
      <TermsNavButtons />

      <Container size="md">
        <div className="space-y-4">
          {/* Our Commitment To You */}
          <Section>
            <div className="p-4 pl-0 mt-4">
              <H2 className="mt-6 mb-0 flex-1">Candid Privacy Policy</H2>
              Last updated: June 28,2023
              <div className="flex-1 max-lg:hidden" />
            </div>
            <H4>Our Commitment To You</H4>
            <p>
              At Candid, your privacy is a top priority. Your privacy is at the core of the way we
              design and build the services and products you know and love, so that you can fully
              trust them and focus on building meaningful connections.
              <br />
              <br />
              We appreciate that you put your trust in us when you provide us with your information
              and we do not take this lightly.
            </p>
          </Section>

          {/* Our Commitment To You */}
          <Section>
            <H4>We do not compromise your privacy. </H4>
            <p>
              We design all of our products and services with your privacy in mind. We have experts
              from various fields, including legal, security, engineering, product design and others
              to make sure that no decision is taken without respect for your privacy.
            </p>
          </Section>

          <Section>
            <H4>We strive to be transparent in the way we process your data.</H4>
            <p>
              Your privacy is important and we aim to provide a clear and concise description of
              what data we collect and how we use it. In this policy, we've used as much plain
              language as possible so you aren't lost in any legal jargon.
            </p>
          </Section>

          <Section>
            <H4>We work hard to keep your information secure</H4>
            <p>
              We constantly update our security practices and invest in our security efforts to
              enhance the safety of your information.
            </p>
          </Section>

          <Section>
            <H3>Privacy Policy </H3>
            <p>Welcome to Candid's Privacy Policy. Thank you for taking the time to read it.</p>
            <p>
              We appreciate that you trust us with your information and we intend to always keep
              that trust. This starts with making sure you understand the information we collect,
              why we collect it, how it is used and your choices regarding your information. This
              Policy describes our privacy practices in plain language, keeping legal and technical
              jargon to a minimum.
            </p>
          </Section>

          <Section>
            <H4>1. Children's Privacy</H4>
            <p>
              Our services are restricted to users who are 18 years of age or older. We do not
              permit users under the age of 18 on our platform and we do not knowingly collect
              personal information from anyone under the age of 18. If you suspect that a user is
              under the age of 18, please use the reporting mechanism available through the service.
            </p>
          </Section>

          <Section>
            <H4>2. Where This Privacy Policy Applies</H4>
            <p>
              This Privacy Policy applies to websites, apps, events and other services operated by
              Candid. For simplicity, we refer to all of these as our “services” in this Privacy
              Policy. To make it extra clear, we've added links to this Privacy Policy on all
              applicable services.
            </p>
            <p>
              Some services may require their own unique privacy policy. If a particular service has
              its own privacy policy, then that policy -- not this Privacy Policy -- applies.
            </p>
          </Section>

          <Section>
            <H4>3. Information We Collect</H4>
            <p>
              It goes without saying, we can't help you develop meaningful connections without some
              information about you, such as basic profile details and the types of people you'd
              like to meet. We also collect information generated as you use our services, for
              example access logs, as well as information from third parties, like when you access
              our services through a social media account. If you want additional info, we go into
              more detail below.
            </p>

            <H5>Information you give us </H5>
            <p>You choose to give us certain information when using our services. This includes:</p>
            <ul>
              <li className="list-disc">
                <p>
                  When you create an account, you provide us with at least your login credentials
                  and some information about yourself, potentially including but not limited to:
                </p>
                <ul>
                  <li className="list-disc mx-5">Username;</li>
                  <li className="list-disc mx-5">Email address;</li>
                  <li className="list-disc mx-5">Mobile number;</li>
                  <li className="list-disc mx-5">Date of birth;</li>
                  <li className="list-disc mx-5">Photographs;</li>
                  <li className="list-disc mx-5">Location;</li>
                  <li className="list-disc mx-5">And more...</li>
                </ul>
              </li>
              <li className="list-disc">
                <p>
                  When you complete your profile, you can share with us additional information, such
                  as details on your personality, lifestyle, interests and other details about you,
                  as well as content such as photos and videos. To add certain content, like
                  pictures or videos, you may allow us to access your camera or photo album. Some of
                  the information you choose to provide us may be considered “special” or
                  “sensitive” in certain jurisdictions, for example your racial or ethnic origins,
                  sexual orientation and religious beliefs. By choosing to provide this information,
                  you consent to our processing of that information.
                </p>
              </li>
              <li className="list-disc">
                <p>
                  When you subscribe to a paid service or make a purchase directly from us (rather
                  than through a platform such as iOS or Android), you provide us or our payment
                  service provider with information, such as your debit or credit card number or
                  other financial information.
                </p>
              </li>

              <li className="list-disc">
                <p>
                  When you subscribe to a paid service or make a purchase directly from us (rather
                  than through a platform such as iOS or Android), you provide us or our payment
                  service provider with information, such as your debit or credit card number or
                  other financial information.
                </p>
              </li>

              <li className="list-disc">
                <p>
                  When you participate in surveys or focus groups, you give us your insights into
                  our products and services, responses to our questions and testimonials.
                </p>
              </li>
              <li className="list-disc">
                <p>
                  When you choose to participate in our promotions, events or contests, we collect
                  the information that you use to register or enter.
                </p>
              </li>
              <li className="list-disc">
                <p>
                  If you contact our customer care team, we collect the information you give us
                  during the interaction. Sometimes, we monitor or record these interactions for
                  training purposes and to ensure a high quality of service.
                </p>
              </li>
              <li className="list-disc">
                <p>
                  If you ask us to communicate with or otherwise process information of other people
                  (for example, if you ask us to send an email on your behalf to one of your
                  friends), we collect the information about others that you give us in order to
                  complete your request.
                </p>
              </li>
              <li className="list-disc">
                <p>
                  Of course, we also process your chats with other users as well as the content you
                  publish, as part of the operation of the services.
                </p>
              </li>
            </ul>

            <H5>Information we receive from others </H5>
            <p>
              In addition to the information you provide us directly, we receive information about
              you from others, including:
            </p>
            <ul>
              <li className="list-disc">
                <p>
                  <span className="font-semibold ">Other Users</span> Other users may provide
                  information about you as they use our services. For instance, we may collect
                  information about you from other users if they contact us about you.
                </p>
              </li>
              <li className="list-disc">
                <p>
                  <span className="font-semibold ">Other Partners</span> We may receive info about
                  you from our partners, for instance where Candid ads are published on a partner's
                  websites and platforms (in which case they may pass along details on a campaign's
                  success).
                </p>
              </li>
            </ul>

            <H5>Information collected when you use our services</H5>
            <p>
              When you use our services, we collect information about which features you've used,
              how you've used them and the devices you use to access our services. See below for
              more details:
            </p>
            <ul>
              <li className="list-disc">
                <p>
                  <span className="font-semibold ">Usage Information </span> We collect information
                  about your activity on our services, for instance how you use them (e.g., date and
                  time you logged in, features you've been using, searches, clicks and pages which
                  have been shown to you, referring webpage address, advertising that you click on)
                  and how you interact with other users (e.g., users you connect and interact with,
                  time and date of your exchanges, number of messages you send and receive).
                </p>
              </li>
              <li className="list-disc">
                <p>
                  <span className="font-semibold ">Device information</span> We collect information
                  from and about the device(s) you use to access our services, including:
                  <ul>
                    <li className="list-disc mx-5">
                      hardware and software information such as IP address, device ID and type,
                      device-specific and apps settings and characteristics, app crashes,
                      advertising IDs (such as Google's AAID and Apple's IDFA, both of which are
                      randomly generated numbers that you can reset by going into your device's
                      settings), browser type, version and language, operating system, time zones,
                      identifiers associated with cookies or other technologies that may uniquely
                      identify your device or browser (e.g., IMEI/UDID and MAC address);
                    </li>
                    <li className="list-disc mx-5">
                      information on your wireless and mobile network connection, like your service
                      provider and signal strength;
                    </li>
                    <li className="list-disc mx-5">
                      information on device sensors such as accelerometers, gyroscopes and
                      compasses.
                    </li>
                  </ul>
                </p>
              </li>
              <li className="list-disc">
                <p>
                  <span className="font-semibold ">Other information with your consent </span>
                  If you give us permission, we can collect your precise geolocation (latitude and
                  longitude) through various means, depending on the service and device you're
                  using, including GPS, Bluetooth or Wi-Fi connections. The collection of your
                  geolocation may occur in the background even when you aren't using the services if
                  the permission you gave us expressly permits such collection. If you decline
                  permission for us to collect your geolocation, we will not collect it. Similarly,
                  if you consent, we may collect your photos and videos (for instance, if you want
                  to publish a photo, video or streaming on the services).
                </p>
              </li>
            </ul>
          </Section>

          <Section>
            <H4>4. Cookies and Other Similar Data Collection Technologies</H4>
            <p>
              Like most providers of online services, we use cookies to provide, secure and improve
              our services, including by remembering your preferences, recognizing you when you
              visit our website and personalizing and tailoring ads to your interests. To accomplish
              these purposes, we also may link information from cookies with other personal
              information we hold about you.
            </p>
            <p>
              There are several cookie management options available to you. Please note that changes
              you make to your cookie preferences may make browsing our website a less satisfying
              experience. In some cases, you may even find yourself unable to use all or part of our
              site.
            </p>

            <p>
              Some web browsers (including Safari, Internet Explorer, Firefox and Chrome) have a “Do
              Not Track” ("
              <span className="font-semibold ">DNT</span> ”) feature that tells a website that a
              user does not want to have his or her online activity tracked. If a website that
              responds to a DNT signal receives a DNT signal, the browser can block that website
              from collecting certain information about the browser's user. Not all browsers offer a
              DNT option and DNT signals are not yet uniform. For this reason, many businesses,
              including Candid, do not currently respond to DNT signals
            </p>
          </Section>

          <Section>
            <H4>5. How We Use Information</H4>
            <p>
              The main reason we use your information is to deliver and improve our services.
              Additionally, we use your info to help keep you safe and to provide you with
              advertising that may be of interest to you. Read on for a more detailed explanation of
              the various reasons we use your information, together with practical examples.
            </p>
            <H5>To administer your account and provide our services to you</H5>
            <ul className="mx-5">
              <li className="list-disc">Create and manage your account</li>
              <li className="list-disc">
                Provide you with customer support and respond to your requests
              </li>
              <li className="list-disc">Complete your transactions</li>
              <li className="list-disc">
                Communicate with you about our services, including order management and billing
              </li>
            </ul>
            <H5>To help you connect with other users</H5>
            <ul className="mx-5">
              <li className="list-disc">
                Analyze your profile, activity on the service, and preferences to recommend
                meaningful connections to you and recommend you to others.
              </li>
              <li className="list-disc">Show users' profiles to one another</li>
            </ul>

            <H5>To ensure a consistent experience across your devices</H5>
            <ul className="mx-5">
              <li className="list-disc">
                Link the various devices you use so that you can enjoy a consistent experience of
                our services on all of them. We do this by linking devices and browser data, such as
                when you log into your account on different devices or by using partial or full IP
                address, browser version and similar data about your devices to help identify and
                link them.
              </li>{" "}
            </ul>

            <H5>To provide new Candid services to you</H5>
            <ul className="mx-5">
              <li className="list-disc">
                Register you and display your profile on new Candid features and apps
              </li>
              <li className="list-disc">Administer your account on these new features and apps</li>
            </ul>

            <H5>To serve you relevant offers and ads</H5>
            <ul className="mx-5">
              <li className="list-disc">
                Administer sweepstakes, contests, discounts or other offers
              </li>
              <li className="list-disc">
                Develop, display and track content and advertising tailored to your interests on our
                services and other sites
              </li>
              <li>
                Communicate with you by email, phone, social media or mobile device about products
                or services that we think may interest you
              </li>
            </ul>

            <H5>To improve our services and develop new ones</H5>
            <ul className="mx-5">
              <li className="list-disc">Administer focus groups and surveys</li>
              <li className="list-disc">
                Conduct research and analysis of users' behavior to improve our services and content
                (for instance, we may decide to change the look and feel or even substantially
                modify a given feature based on users' behavior)
              </li>
              <li>
                Develop new features and services (for example, we may decide to build a new
                interests-based feature further to requests received from users).
              </li>
            </ul>

            <H5>To prevent, detect and fight fraud or other illegal or unauthorized activities</H5>
            <ul className="mx-5">
              <li className="list-disc">
                Address ongoing or alleged misbehavior on and off-platform
              </li>
              <li className="list-disc">
                Perform data analysis to better understand and design countermeasures against these
                activities
              </li>
              <li>Retain data related to fraudulent activities to prevent against recurrences</li>
            </ul>

            <H5>To ensure legal compliance</H5>
            <ul className="mx-5">
              <li className="list-disc">Comply with legal requirements</li>
              <li className="list-disc">Assist law enforcement</li>
              <li>Enforce or exercise our rights, for example our Terms</li>
            </ul>
            <p>
              To process your information as described above, we rely on the following legal bases:
            </p>
            <ul className="mx-5">
              <li className="list-disc">
                <span className="font-semibold ">Provide our service to you: </span>
                Most of the time, the reason we process your information is to perform the contract
                that you have with us. For instance, as you go about using our service to build
                meaningful connections, we use your information to maintain your account and your
                profile, to make it viewable to other users and recommend other users to you.
              </li>
              <li className="list-disc">
                <span className="font-semibold ">Legitimate interests:</span> We may use your
                information where we have legitimate interests to do so. For instance, we analyze
                users' behavior on our services to continuously improve our offerings, we suggest
                offers we think might interest you, and we process information for administrative,
                fraud detection and other legal purposes.
              </li>
              <li>
                <span className="font-semibold ">Consent:</span> From time to time, we may ask for
                your consent to use your information for certain specific reasons. You may withdraw
                your consent at any time by contacting us at the address provided at the end of this
                Privacy Policy.
              </li>
            </ul>
          </Section>

          <Section>
            <H4>6. How We Share Information</H4>
            <p>
              Since our goal is to help you make meaningful connections, the main sharing of users'
              information is, of course, with other users. We also share some users' information
              with service providers and partners who assist us in operating the services.
            </p>
            <H5>With other users</H5>
            <p>
              You share information with other users when you voluntarily disclose information on
              the service (including your public profile). Please be careful with your information
              and make sure that the content you share is stuff that you're comfortable being
              publicly viewable since neither you nor we can control what others do with your
              information once you share it.
            </p>

            <p>
              If you choose to limit the audience for all or part of your profile or for certain
              content or information about you, then it will be visible according to your settings.
            </p>
            <H5>With third parties</H5>
            <p>
              We use third parties to help us operate and improve our services. These third parties
              assist are used for operating our services including tasks such as but not limited to:
              data hosting and maintenance, analytics, identity verification, customer care, payment
              processing and security operations.
            </p>
            <p>
              We do not sell any data to third parties and receive no compensation from said third
              parties.{" "}
              <b>
                No personal information will be shared with third parties/affiliates for
                marketing/promotional purposes.
              </b>
            </p>
            <p>
              We may also share information with partners who distribute and assist us in
              advertising our services. For instance, we may share limited information on you in
              hashed, non-human readable form to advertising partners.
            </p>
            <p>
              We follow a strict vetting process prior to engaging any service provider or working
              with any partner. All of our service providers and partners must agree to strict
              confidentiality obligations.
            </p>
            <H5>For corporate transactions</H5>
            <p>
              We may transfer your information if we are involved, whether in whole or in part, in a
              merger, sale, acquisition, divestiture, restructuring, reorganization, dissolution,
              bankruptcy or other change of ownership or control.
            </p>
            <H5>When required by law </H5>
            <p>
              We may disclose your information if reasonably necessary: (i) to comply with a legal
              process, such as a court order, subpoena or search warrant, government / law
              enforcement investigation or other legal requirements; (ii) to assist in the
              prevention or detection of crime (subject in each case to applicable law); or (iii) to
              protect the safety of any person.
            </p>
            <H5>To enforce legal rights </H5>
            <p>
              We may also share information: (i) if disclosure would mitigate our liability in an
              actual or threatened lawsuit; (ii) as necessary to protect our legal rights and legal
              rights of our users, business partners or other interested parties; (iii) to enforce
              our agreements with you; and (iv) to investigate, prevent, or take other actions
              regarding illegal activities, suspected fraud or other wrongdoing.
            </p>
            <H5>With your consent or at your request </H5>
            <p>
              We may ask for your consent to share your information with third parties. In any such
              case, we will make it clear why we want to share the information.
            </p>
            <p>
              We may use and share non-personal information (meaning information that, by itself,
              does not identify who you are such as device information, general demographics,
              general behavioral data, geolocation in de-identified form), as well as personal
              information in hashed, non-human readable form, under any of the above circumstances.
              We may combine this information with additional non-personal information or personal
              information in hashed, non-human readable form collected from other sources.
            </p>
          </Section>

          <Section>
            <H4>7. Your Rights</H4>
            <p>
              TWe want you to be in control of your information, so we have provided you with the
              following tools:
            </p>
            <ul className="mx-5">
              <li className="list-disc">
                <span className="font-semibold ">Access / Update tools in the service.</span>
                Tools and account settings that help you to access, rectify or delete information
                that you provided to us and that's associated with your account directly within the
                service. If you have any question on those tools and settings, please contact our
                customer team for help at support@trustcandid.com
              </li>
              <li className="list-disc">
                <span className="font-semibold ">Device permissions.</span> Mobile platforms have
                permission systems for specific types of device data and notifications, such as
                phone book and location services as well as push notifications. You can change your
                settings on your device to either consent to or oppose the collection of the
                corresponding information or the display of the corresponding notifications. Of
                course, if you do that, certain services may lose full functionality.
              </li>
              <li>
                <span className="font-semibold ">Deletion.</span> You can delete your account by
                using the corresponding functionality directly on the service.
              </li>
            </ul>
            <p>
              We want you to be aware of your privacy rights. Here are a few key points to remember:
            </p>
            <ul className="mx-5">
              <li className="list-disc">
                <span className="font-semibold ">Reviewing your information.</span>
                Applicable privacy laws may give you the right to review the personal information we
                keep about you (depending on the jurisdiction, this may be called right of access,
                right of portability or variations of those terms). You can request a copy of your
                personal information by contacting us at{" "}
                <Highlight>privacy@trustcandid.com</Highlight>.
              </li>
              <li className="list-disc">
                <span className="font-semibold ">Updating your information.</span> If you believe
                that the information we hold about you is inaccurate or that we are no longer
                entitled to use it and want to request its rectification, deletion or object to its
                processing, please contact us at <Highlight>privacy@trustcandid.com</Highlight>.
              </li>
            </ul>
            <p>
              For your protection and the protection of all of our users, we may ask you to provide
              proof of identity before we can answer the above requests.
            </p>
            <p>
              Keep in mind, we may reject requests for certain reasons, including if the request is
              unlawful or if it may infringe on trade secrets or intellectual property or the
              privacy of another user. If you wish to receive information relating to another user,
              such as a copy of any messages you received from him or her through our service, the
              other user will have to contact us at <Highlight>privacy@trustcandid.com</Highlight>{" "}
              to provide their written consent before the information is released.
            </p>
            <p>
              Also, we may not be able to accommodate certain requests to object to the processing
              of personal information, notably where such requests would not allow us to provide our
              service to you anymore. For instance, we cannot provide our service if we do not have
              your date of birth.
            </p>

            <ul className="mx-5">
              <li className="list-disc">
                <span className="font-semibold ">Un-installation.</span> You can stop all
                information collection by an app by uninstalling it using the standard
                un-installation process for your device. If you uninstall the app from your mobile
                device, the unique identifier associated with your device will continue to be
                stored. If you re-install the application on the same mobile device, we will be able
                to re-associate this identifier to your previous transactions and activities.
              </li>
              <li className="list-disc">
                <span className="font-semibold ">Accountability.</span>In certain countries,
                including in the European Union, you have a right to lodge a complaint with the
                appropriate data protection authority if you have concerns about how we process your
                personal information. The data protection authority you can lodge a complaint with
                notably may be that of your habitual residence, where you work or where we are
                established.
              </li>
            </ul>
          </Section>

          <Section>
            <H4>8. Residents of California</H4>
            <p>
              If you are a California resident, you can request a notice disclosing the categories
              of personal information about you that we have shared with third parties for their
              direct marketing purposes during the preceding calendar year. To request this notice,
              please submit your request at <Highlight>privacy@trustcandid.com</Highlight>. Please
              allow 30 days for a response. For your protection and the protection of all of our
              users, we may ask you to provide proof of identity before we can answer such a
              request.
            </p>
          </Section>

          <Section>
            <H4>9. How We Protect Your Information</H4>
            <p>
              We work hard to protect you from unauthorized access to or alteration, disclosure or
              destruction of your personal information. As with all technology companies, although
              we take steps to secure your information, we do not promise, and you should not
              expect, that your personal information will always remain secure.
            </p>
            <p>
              We regularly monitor our systems for possible vulnerabilities and attacks and
              regularly review our information collection, storage and processing practices to
              update our physical, technical and organizational security measures.
            </p>

            <p>
              We may suspend your use of all or part of the services without notice if we suspect or
              detect any breach of security.
            </p>
          </Section>

          <Section>
            <H4>10. How Long We Retain Your Information</H4>
            <p>
              We retain your information for business purposes, for as long as your account is
              active, and/or as long as is reasonably necessary to provide you with our products and
              services. We will also retain your information as reasonably necessary to comply with
              our legal obligations, resolve disputes and enforce our agreements. We may also retain
              cached or archived copies of your information for a reasonable period of time.
            </p>
          </Section>

          <Section>
            <H4>11. Children's Privacy</H4>
            <p>
              Our services are restricted to users who are 18 years of age or older. We do not
              permit users under the age of 18 on our platform and we do not knowingly collect
              personal information from anyone under the age of 18. If you suspect that a user is
              under the age of 18, please use the reporting mechanism available through the service.
            </p>
          </Section>

          <Section>
            <H4>12. Privacy Policy Changes</H4>
            <p>
              Candid may, in its sole discretion, amend this Privacy Policy from time to time. To
              ensure you are able to remain informed about the information we collect and how we use
              it, material changes to our Privacy Policy will be reflected here.
            </p>
          </Section>

          <Section>
            <H4>13. How to Contact Us</H4>
            <p>
              If you have questions about this Privacy Policy, you can contact us at{" "}
              <Highlight>privacy@trustcandid.com</Highlight>.
            </p>
          </Section>
        </div>
      </Container>
    </div>
  );
}
