import { ContentBox, ContentBoxInfoLine } from "../../widgets/content-box";
import {
  Feature_Flags_Enum,
  L_Relationship_Type_Enum,
  L_Vouch_Level,
  useBioV2FromSlugBasicsQuery,
  useBioV2FromSlugRelationshipsQuery,
} from "shared/dist/__generated__/components";
import { classNames, filterNulls } from "shared/dist/util";

import { EditBioContentBoxLine } from "../../widgets/bio/edit-bio-content-box-line";
import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import { InfoBar } from "./info-bar";
import { Pill } from "../../widgets/pill";
import { ProfileListHorizontal } from "../../widgets/profile-list";
import React from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { UserPageContainer } from "./common";
import { VouchInfoBox } from "./vouching/vouch-info-box";
import { allRoutes } from "../../util/routes";
import { match } from "ts-pattern";
import { useBioAndSlugFromContext } from "./bio-context";
import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";
import { useTypedParams } from "react-router-typesafe-routes/dom";

export type UserAboutProps = {};

export function UserAbout(): JSX.Element {
  const v2 = useIsFeatureEnabled(Feature_Flags_Enum.ProfileAndDiscoveryV02);
  return v2 ? <UserAboutV2 /> : <UserAboutV1 />;
}
export function UserAboutV2({}: UserAboutProps): JSX.Element {
  const slug = useTypedParams(allRoutes.PROFILE).slug!;
  const { data: relsData, loading: relsLoading } = useBioV2FromSlugRelationshipsQuery({
    skip: !slug,
    variables: { slug },
    fetchPolicy: "cache-first",
  });
  const { data: basicsData, loading: basicsLoading } = useBioV2FromSlugBasicsQuery({
    skip: !slug,
    variables: { slug },
    fetchPolicy: "cache-first",
  });
  if (basicsData?.l_bio_v2?.__typename !== "L_Bio_V2_Success") {
    return <></>;
  }
  const bio = basicsData.l_bio_v2;
  const ref = React.useRef<HTMLDivElement>(null);
  const anyLoading = basicsLoading;
  if (!bio) {
    if (anyLoading) {
      return <SpinnerCentered />;
    }
    return <ErrorComp caption={`404: We're sorry, but can't find @${slug}'s profile...`} />;
  }
  const [singleAnswers, multipleAnswers] = React.useMemo(() => {
    const filtered = bio.answers.filter((a) => a.answer !== null);
    return [
      filtered.filter((a) => !a.answer_choices_allow_multiple),
      filtered.filter((a) => a.answer_choices_allow_multiple),
    ];
  }, [bio]);
  const partners = match(relsData?.l_bio_v2)
    .with({ __typename: "L_Bio_V2_Success" }, (bio) => bio.relationships)
    .otherwise(() => [])
    .filter((f) => f.type === L_Relationship_Type_Enum.Partner)
    .map((p) =>
      p?.slug && p?.screen_name
        ? {
            slug: p.slug,
            screenName: p.screen_name,
          }
        : null
    );
  const vouchData = bio?.user.user_vouch_level;
  return (
    <div className="UserAboutv2 relative" ref={ref}>
      {ref?.current && (
        <div
          style={{ height: `calc(4rem + 100dvh - ${ref.current.getBoundingClientRect().top}px` }}
          className="absolute z-0 top-0  right-0 left-0 bg-base-200"
        />
      )}
      <div className="absolute z-0 top-0  bottom-0 right-0 left-0 bg-base-200" />
      <UserPageContainer className="space-y-3 pb-16">
        <VouchInfoBox screenName={bio.user.screen_name} level={vouchData} />
        {partners.length > 0 && (
          <ContentBox className="px-3 pb-1">
            <ProfileListHorizontal columnLayout profiles={filterNulls(partners)} />
          </ContentBox>
        )}
        <ContentBox className="px-3 pb-1">
          <InfoBar hideOuterBorder details={bio?.details} relationships={null} editable={false} />
          <dl>
            {singleAnswers
              .filter((a) => !!a.answer?.trim()?.length)
              .map((answer, idx) => (
                <ContentBoxInfoLine key={idx}>
                  {answer.prompt.en_US}
                  <span className="text-secondary font-semibold">{answer.answer?.trim()}</span>
                </ContentBoxInfoLine>
              ))}
            <EditBioContentBoxLine editable={bio.editable ?? false} />
          </dl>
        </ContentBox>
        {multipleAnswers.map((answer, idx) => {
          const answers = answer?.answer?.split("|").filter((s) => !!s?.trim()?.length);
          if (!answers || !answers.length) return null;
          return (
            <ContentBox className="px-3 pb-2" key={idx}>
              <p className="text-md pt-1 ">{answer.prompt.en_US}</p>
              <div className=" my-0 divider" />
              <div className="flex-1 basis-full min-h-0 flex flex-row gap-x-2 flex-wrap overflow-auto">
                {answers.map((choice, choiceIdx) => (
                  <Pill
                    key={choiceIdx}
                    value={choice}
                    label={choice}
                    active
                    hideIcon
                    className="flex-0 !mx-0"
                  />
                ))}
              </div>
            </ContentBox>
          );
        })}
        <ContentBox className="px-3 pb-1">
          <p className="text-md pt-1 font-semibold">About Me:</p>
          {!!bio?.details?.bio?.trim?.()?.length && (
            <>
              <div className=" my-0 divider" />
              <div className="prose prose-sm">
                {bio?.details?.bio?.split("\n").map((str, idx) => (
                  <p
                    className={classNames(
                      "whitespace-break-spaces text-ellipsis overflow-x-hidden "
                    )}
                    key={idx}
                  >
                    {str}
                  </p>
                ))}
              </div>
            </>
          )}
          <EditBioContentBoxLine subPage="bio" editable={bio.editable ?? false} />
        </ContentBox>
      </UserPageContainer>
    </div>
  );
}
export function UserAboutV1({}: UserAboutProps): JSX.Element {
  const [slug, { data, loading }] = useBioAndSlugFromContext();
  const bio = data?.l_bio_public;
  if (!bio) {
    if (loading) {
      return <SpinnerCentered />;
    }
    return <ErrorComp caption={`404: We're sorry, but can't find @${slug}'s profile...`} />;
  }
  const [singleAnswers, multipleAnswers] = React.useMemo(() => {
    const filtered = bio.answers.filter((a) => a.answer !== null);
    return [
      filtered.filter((a) => !a.answer_choices_allow_multiple),
      filtered.filter((a) => a.answer_choices_allow_multiple),
    ];
  }, [bio]);
  const partners = bio?.relationships
    ?.filter((f) => f.type === L_Relationship_Type_Enum.Partner)
    .map((p) =>
      p?.slug && p?.screen_name
        ? {
            slug: p.slug,
            screenName: p.screen_name,
          }
        : null
    );
  const vouchData = bio?.user.user_vouch_level;
  return (
    <div className="UserAboutV1 relative">
      <div className="absolute top-0 bottom-0 right-0 left-0 bg-base-200  min-h-screen" />
      <UserPageContainer className="space-y-3 pb-16">
        <VouchInfoBox screenName={bio.user.screen_name} level={vouchData} />
        {partners.length > 0 && (
          <ContentBox className="px-3 pb-1">
            <ProfileListHorizontal columnLayout profiles={filterNulls(partners)} />
          </ContentBox>
        )}
        <ContentBox className="px-3 pb-1">
          <InfoBar hideOuterBorder details={bio?.details} relationships={null} editable={false} />
          <dl>
            {singleAnswers.map((answer, idx) => (
              <ContentBoxInfoLine key={idx}>
                {answer.prompt.en_US}
                <span className="text-secondary font-semibold">{answer.answer}</span>
              </ContentBoxInfoLine>
            ))}
            <EditBioContentBoxLine editable={bio.editable ?? false} />
          </dl>
        </ContentBox>
        {multipleAnswers.map((answer, idx) => {
          const answers = answer?.answer?.split("|").filter((s) => s?.trim()?.length > 0);
          if (!answers) {
            return null;
          }
          return (
            <ContentBox className="px-3 pb-2" key={idx}>
              <p className="text-md pt-1 ">{answer.prompt.en_US}</p>
              <div className=" my-0 divider" />
              <div className="flex-1 basis-full min-h-0 flex flex-row gap-x-2 flex-wrap overflow-auto">
                {answers.map((choice, choiceIdx) => (
                  <Pill
                    key={choiceIdx}
                    value={choice}
                    label={choice}
                    active
                    hideIcon
                    className="flex-0 !mx-0"
                  />
                ))}
              </div>
            </ContentBox>
          );
        })}
        <ContentBox className="px-3 pb-1">
          <p className="text-md pt-1 font-semibold">About Me:</p>
          <div className=" my-0 divider" />
          <div className="prose prose-sm">
            {bio?.details?.bio?.split("\n").map((str, idx) => (
              <p
                className={classNames("whitespace-break-spaces text-ellipsis overflow-x-hidden ")}
                key={idx}
              >
                {str}
              </p>
            ))}
          </div>
          <EditBioContentBoxLine subPage="bio" editable={bio.editable ?? false} />
        </ContentBox>
      </UserPageContainer>
    </div>
  );
}
