import { FacebookPixelComponent, GoogleComponent } from "../../widgets/tracking-pixels";

import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { LandingBuilding } from "./building";
import { LandingFeatures } from "./features";
import { LandingFooter } from "./footer";
import { LandingHero } from "./hero";
import { NeonSpinner } from "../../widgets/neon-spinner";
import React from "react";
import { allRoutes } from "../../util/routes";
import { useIsProd } from "shared/dist/util/env";
import { useNavigate } from "react-router-dom";

export function LandingMain(): JSX.Element {
  const navigate = useNavigate();
  const isProd = useIsProd();
  React.useEffect(() => {
    async function checkDeviceAndRedirect() {
      const info = await Device.getInfo();
      const isIphone = info?.model?.includes("iPhone");
      if (isProd && info.platform === "web" && isIphone) {
        window.open("https://apps.apple.com/us/app/candid-enm/id6466639507", "_blank");
      } else if (info.platform === "ios") {
        navigate(allRoutes.login.buildPath({}));
      }
    }
    checkDeviceAndRedirect();
  }, [navigate]);

  if (Capacitor?.isNativePlatform?.()) {
    return <NeonSpinner />;
  }
  return (
    <div className="w-screen bg-candid-gray-500">
      <LandingHero />
      {/* <LandingStandouts /> */}
      <LandingFeatures />
      <LandingBuilding />
      <LandingFooter />
      <GoogleComponent />
      <FacebookPixelComponent />
    </div>
  );
}
