import { UserPageNavWrapper, UserPageNavWrapperProps } from "../screens/user-page/main-nav";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { AUTH_REDIRECT_COOKIE_KEY } from "shared-web-react/dist/constants";
import Cookies from "js-cookie";
import { Onboarding_Statuses_Enum } from "shared/dist/__generated__/components";
import React from "react";
import { RedirectToLanding } from "../App";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { allRoutes } from "./routes";
import { useMyId } from "shared/dist/auth-data";
import { useMySlug } from "../screens/bio/bio-settings";

export function RequireLogin({
  children,
  wrapAll,
  wrapFst,
  wrapSnd,
  newContentBoxUi,
  hideMenuLabelsInDesktop,
}: {
  children: React.ReactNode | [React.ReactNode, React.ReactNode];
  wrapAll?: boolean;
  wrapFst?: boolean;
  wrapSnd?: boolean;
} & Pick<UserPageNavWrapperProps, "hideMenuLabelsInDesktop" | "newContentBoxUi">): JSX.Element {
  const id = useMyId();
  // console.log("🚀 ~ file: profile-page.tsx:32 ~ id:", id);
  const { status } = useMySlug() ?? {};
  const location = useLocation();
  const navigate = useNavigate();
  if (
    status === Onboarding_Statuses_Enum.V2_00New ||
    status === Onboarding_Statuses_Enum.V2_05InvitedConfirmedObPending
  ) {
    const inviteEntry = allRoutes.ONBOARDING.buildPath({});
    if (location.pathname.toLowerCase() !== inviteEntry.toLowerCase()) {
      navigate(inviteEntry);
      return <></>;
    }
  }
  if (children instanceof Array) {
    if (id) {
      return wrapAll || wrapFst ? (
        <UserPageNavWrapper {...{ newContentBoxUi, hideMenuLabelsInDesktop }}>
          {children[0]}
        </UserPageNavWrapper>
      ) : (
        <>{children[0]}</>
      );
    }
    return children[1] ? (
      wrapAll || wrapSnd ? (
        <UserPageNavWrapper {...{ newContentBoxUi, hideMenuLabelsInDesktop }}>
          {children[1]}
        </UserPageNavWrapper>
      ) : (
        <>{children[1]}</>
      )
    ) : (
      <RedirectToLanding />
    );
  }
  if (id) {
    return wrapAll || wrapFst ? (
      <UserPageNavWrapper {...{ newContentBoxUi, hideMenuLabelsInDesktop }}>
        {children}
      </UserPageNavWrapper>
    ) : (
      <>{children}</>
    );
  }
  return <RedirectToLanding />;
}

export function RequireAnonymous({
  children,
}: {
  children: React.ReactNode | [React.ReactNode, React.ReactNode];
}): JSX.Element {
  const id = useMyId();
  if (children instanceof Array) {
    if (!id) {
      return <>{children[0]}</>;
    }
    return <>{children[1]}</> || <RedirectTo to={allRoutes.me.buildPath({})} />;
  }
  if (!id) {
    return <>{children}</>;
  }
  return <RedirectTo to={allRoutes.me.buildPath({})} />;
}

export function useNavigateToLogin() {
  const navigate = useNavigate();
  return () => {
    Cookies.set(AUTH_REDIRECT_COOKIE_KEY, window.location.pathname);
    setTimeout(() => {
      navigate(allRoutes.login.buildPath({}));
    }, 100);
  };
}

export function RedirectTo({ setAuthRedirect, to }: { to: string; setAuthRedirect?: boolean }) {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (setAuthRedirect) {
      Cookies.set(AUTH_REDIRECT_COOKIE_KEY, window.location.pathname);
    }
    setTimeout(() => {
      navigate(to);
    }, 100);
  }, []);
  return <SpinnerCentered />;
}

export function RedirectToUserPage(): JSX.Element {
  let { slug } = useParams();
  const navigate = useNavigate();
  React.useEffect(() => {
    setTimeout(() => {
      if (slug) {
        navigate(allRoutes.PROFILE.buildPath({ slug }));
      } else {
        navigate(allRoutes.ROOT.buildPath({}));
      }
    }, 100);
  }, []);
  return <SpinnerCentered />;
}
