import { H2, H3, H4 } from "shared-web-react/dist/widgets/text";

import { Container } from "../../widgets/container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import React from "react";
import { TermsNavButtons } from "./widgets";
import { allRoutes } from "../../util/routes";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";

function Highlight({ children }: { children: string }): JSX.Element {
  return <span className="text-candid-purple-700">{children}</span>;
}

export function Section({ children }: React.PropsWithChildren<{}>): JSX.Element {
  return (
    <div className="w-full flex items-center flex-col  lg:flex-row pl-4 pr-4 prose">
      <div className="mt-6 flex-1 space-y-2">{children}</div>
    </div>
  );
}

export function TermsText(): JSX.Element {
  const mid = "";
  const gradients = [
    `radial-gradient(at top left, white,               ${mid} transparent)`,
    `radial-gradient(at top right,   RGB(211,190,228), ${mid} transparent)`,
    `radial-gradient(at bottom left, RGB(211,190,228), ${mid} transparent)`,
    `radial-gradient(at bottom right, white,           ${mid} transparent)`,
  ].join(", ");
  return (
    <div
      id="l_features"
      className="w-screen p-4 "
      style={{
        background: gradients,
      }}
    >
      <TermsNavButtons />

      <Container size="md">
        <div className="space-y-4">
          {/* Our Commitment To You */}
          <Section>
            <div className="p-4  pb pl-0 mt-4">
              <H2 className="mt-6 mb-0 flex-1">Candid Terms & Conditions</H2>
              Last updated: June 28,2023
              <div className="flex-1 max-lg:hidden" />
            </div>
            <H4>1. Introduction; Agreement To This Terms Of Service</H4>
            <p>
              Please read Principled Software, Inc. d/b/a Candid's (" Candid ", " We ", " Our ", “
              Us “) Terms of Service carefully, which include and incorporate Candid's Privacy
              Policy (collectively, the " Terms "). Candid provides a service that allows Candid
              Account holders (as defined below) to register unique personal profiles and
              communicate with other members based on location.These Terms govern and apply to your
              access and use of Candid's social networking services available via Candid's mobile
              apps (Candid) (collectively, the "Apps") and Candid's websites
              (https://trustcandid.com and https://candid.bio) (collectively, the "Sites"). To make
              these Terms easier to read, the Sites, our services and the Apps are collectively
              called the "Services." <br />
              <br />
              <br />
              BY ACCESSING, DOWNLOADING, USING, PURCHASING, PAYING TO USE AND/OR SUBSCRIBING TO THE
              SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY,
              THESE TERMS, AS THEY MAY BE AMENDED FROM TIME TO TIME. IF YOU DO NOT AGREE WITH THESE
              TERMS IN THEIR ENTIRETY, YOU MAY NOT USE THE SERVICES.
              <br />
              <br />
              Please also note that if you subscribe to the Services, your subscription will
              automatically renew for certain periods of time if you do not cancel prior to the end
              of the term. See Section 16 and our Virtual Goods and Currency Terms of Service for
              additional terms where applicable for more information on the automatic renewal terms
              applicable to subscriptions.
            </p>
          </Section>

          <Section>
            <H4>2. Eligibility</H4>
            <p>
              You must be based in the United States and must be at least 18 years of age or the age
              of majority if not 18 to create an account on Candid and use the Service. By creating
              an account and using the Service, you represent and warrant that:
            </p>
            <ul>
              <li className="list-disc mx-5">
                you are 18 (or the age of the majority if not 18) and can form a binding contract
                with Candid,
              </li>
              <li className="list-disc mx-5">
                you are not a person who is barred from using the Service under the laws of the
                United States or any other applicable jurisdiction (for example, you do not appear
                on the U.S. Treasury Department’s list of Specially Designated Nationals or face any
                other similar prohibition),
              </li>
              <li className="list-disc mx-5">
                you will comply with this Agreement and all applicable local, state, national and
                international laws, rules and regulations, and
              </li>
              <li className="list-disc mx-5">
                you have never been convicted of or pled no contest to a felony, a sex crime, or any
                crime involving violence, and that you are not required to register as a sex
                offender with any state, federal or local sex offender registry.
              </li>
            </ul>
          </Section>

          <Section>
            <H4>3. Use Of The Services</H4>
            <p>
              You agree that you will only use the Services, including the posting of any content
              through the Services, in a manner consistent with these Terms and compliant with any
              and all applicable local, provincial, federal and international laws and regulations.
              <br />
              <br />
              a) Registration. You will need to register and create only one (1) unique account for
              your use of the Services. To register a Candid Account, you must enter a valid U.S.
              mobile phone number and we will ask that you validate your phone number via a one-time
              SMS code. We will ask you to provide certain information about yourself as prompted by
              the registration form. You will not include any telephone numbers, street addresses,
              URLs, multimedia, artworks downloaded from external sources, email addresses or any
              other contact information in your profile or in any other publicly viewable User
              Content (as defined in Section 4) or other communications made in connection with your
              use of the Services. You understand and agree that anyone may be able to view any
              information you choose to make publicly available.
              <br />
              <br />
              b) Candid Account Security . You understand that you are fully responsible for
              maintaining the confidentiality of your Candid Account and you are responsible for all
              activities that occur under your Candid Account, including the purchase of any of our
              Paid Services (as described in Section 17). You are solely responsible for selecting
              an appropriate username and for any and all disclosures you make of your username to
              others, including any selection or disclosure that makes you personally identifiable.
              You are responsible for maintaining the confidentiality of your Candid Account login
              username and password and are fully responsible for all activities that occur under
              your Candid Account. In the event that you forgot your login information and wish to
              retrieve it, you will be directed to a password recovery section where you will be
              asked for the email address associated with your Candid Account. You agree (a) to
              immediately notify Candid if you suspect any unauthorized use of your Candid Account
              or any other breach of security, and (b) to use particular caution when accessing your
              Candid Account from a public or shared computer and/or mobile device so that others
              are not able to view or record your personal information. For instance, never give out
              your password, and remember to lock your mobile device when it is not in use. Please
              email us at <Highlight>support@trustcandid.com</Highlight> with the subject line:
              Candid Account Security Breach if you believe your Candid Account has been accessed by
              an unauthorized person. You acknowledge that Candid is not, and will not, be liable or
              responsible for any losses or damages arising from the theft or misappropriation of
              your Candid Account. You further acknowledge and agree that Candid is not responsible
              for, and has no control over, any security breaches experienced on any third party
              Social Networking Site (as defined below).
              <br />
              <br />
              c) Exclusive Use. You will only use the Services for your sole, personal use and not
              in connection with any commercial activities. You will not authorize others to use the
              Services, gain access your Candid Account or otherwise attempt to transfer your right
              to use the Services to any other person or entity. Candid reserves the right to (a)
              disable, block, suspend or deactivate any User's use of or access to the Services,
              including the location information or profiles of other Users, or (b) terminate any
              User's Candid Account, for any reason whatsoever and without notice.
              <br />
              <br />
              d) Data Usage Charges. The use of the Services on your mobile device may require use
              of your data service. Depending on the plan that you have with your cellular carrier,
              you could incur data charges. You should check with your cellular carrier and
              determine how you will be charged for data usage. Candid is not responsible for your
              data usage and will not be responsible for data charges that you may incur while using
              the Services. You are responsible for any Internet connection, data or other fees
              assessed by your cellular carrier to access the Services via your wireless device,
              including any data plan charges, toll, out-of-area, roaming, or other wireless device
              connection charges.
              <br />
              <br />
              e) Interactions with Other Users. You assume all risk when using the Services,
              including but not limited to all risks associated with any online or offline
              interactions with others, including dating. You acknowledge that Candid does not
              routinely screen its Users, inquire into the background of its Users, attempt to
              verify information provided by its Users (except as provided above) or conduct
              criminal screenings of its Users so please proceed with caution. Candid does not make
              any representations, warranties or guarantees as to the conduct of its Users,
              information provided by Users, or their compatibility with you. You alone are
              responsible for your involvement with other Users. You agree that Candid will not be
              responsible for any losses or damages incurred as the result of any such interactions
              with other Users. Candid reserves the right, but has no obligation, to monitor
              disagreements between you and other Users. Please take all necessary precautions when
              meeting other Users, especially if you decide to meet in person. We have created
              Community Guidelines that you may find helpful and we suggest that you review these
              before you start using the Services. You agree to treat all other Users with dignity
              and respect and comply with these Terms especially the User Conduct rules set forth in
              Section 3(G).
              <br />
              <br />
              f) No Commercial Solicitation or Advertising. You will not engage in any advertising
              or solicitation to buy or sell any products or services through the use of the
              Services and you will not transmit any chain letters, junk or spam email or
              communications to other Users. Additionally, you will not use any information obtained
              from the Services in order to contact, advertise to, solicit or sell products or
              services to any User without their prior explicit consent.
              <br />
              <br />
              g) User Conduct. Candid is not responsible or liable in any manner for the conduct of
              its Users, whether or not such conduct is in connection with the use of the Services.
              You acknowledge that you use the Services entirely at your own risk. In order to keep
              use of the Services fun and safe, you agree not to do any of the following in
              connection with the Services or the Users thereof:
              <br />
              <br />
              i) use the Services in any unlawful manner or in a manner that is harmful to or
              violates the rights of others;
              <br />
              <br />
              ii) engage in any unlawful, lewd, harassing, obscene, intimidating, threatening,
              predatory or stalking behavior;
              <br />
              <br />
              iii) use the Services in connection with hazardous environments requiring fail-safe
              performance or any application in which the failure or inaccuracy of that application
              or the Services could lead directly to death, personal injury, or severe physical or
              property damage. i.e. driving while using the Services;
              <br />
              <br />
              iv) use the Services in any manner that could disrupt, damage, disable, overburden,
              impair or affect the performance of the Services or Candid's computer
              systems/technology or interfere with or attempt to interfere with any other User's use
              of the Services or User's computer systems/technology;
              <br />
              <br />
              v) impersonate any person or entity, or misrepresent your age, identity, affiliation,
              connection or association with, any person or entity; vi) make any commercial use of
              the Services or promote or solicit involvement in or support of a political platform,
              religion, cult, or sect;
              <br />
              <br />
              vii) defraud, swindle or deceive other Users;
              <br />
              <br />
              viii) disseminate another User's personal information without his or her permission,
              or collect or solicit another User's personal information for commercial or unlawful
              purposes;
              <br />
              <br />
              ix) solicit or engage in gambling or any similar activity or any illegal or unlawful
              activity;
              <br />
              <br />
              x) use any scripts, bots or other automated technology to scrape or access the
              Services;
              <br />
              <br />
              xi) use the Services for any phishing, trolling or similar activities;
              <br />
              <br />
              xii) use the Services to redirect Users to other sites or encourage Users to visit
              other sites;
              <br />
              <br />
              xiii) harvest or collect email addresses or other contact information of other Users
              from the Services by electronic or other means or use the Services to send, either
              directly or indirectly, any unsolicited bulk email or communications, unsolicited
              commercial email or communications or other spamming or spamming activities;
              <br />
              <br />
              xiv) distribute unsolicited commercial messages or spam, and or use any robot, spider,
              site search/retrieval application, or other manual or automatic device or process to
              retrieve, index, " data mine ", or in any way reproduce or circumvent the navigational
              structure, security features or presentation of the Services or its contents; (xv)
              attempt to access any Services that you are not authorized to access; or (xvi)permit
              or allow other people or third parties to access and use the Services via your Candid
              Account.
              <br />
              <br />
              h) Reporting Violations. If you wish to report any violation of these Terms by others,
              including Users, contact us at <Highlight>support@trustcandid.com</Highlight>. While
              we will make reasonable efforts to review Candid Accounts that are flagged in a timely
              manner, we are under no obligation to remove the flagged Candid Account and in no way
              represent that we will remove or otherwise address flagged Candid Accounts.
              <br />
              <br />
              i) Enforcement. You understand and agree that if Candid believes in its sole
              discretion that you have violated these Terms, misused the Services or behaved in a
              way that could be regarded as inappropriate, unlawful, illegal or unsafe, Candid may,
              among other things, investigate, take legal action against you and/or terminate your
              Candid Account and cancel your subscription and/or membership without notice to you.
            </p>
          </Section>

          <Section>
            <H4>4. User Content </H4>
            <p>
              a) Definition; Responsibility for User Content. You are solely responsible for the
              content and information that you provide, publish, transmit, display or otherwise
              communicate to Candid through the Services or to other Users (collectively referred to
              as " post "), including without limitation, messages, data, text, photos, video,
              music, graphics, links or other materials posted through text, messages, photos and
              profile information (your submissions and posts as well as those of other Users,
              collectively, are " User Content "). Candid does not control, take responsibility for
              or assume liability for any User Content posted by you, other Users or any third
              party, or for any loss or damage thereto, nor is Candid liable for any mistakes,
              defamation, slander, libel, omissions, falsehoods, obscenity, pornography or profanity
              that you encounter.
              <br />
              <br />
              b) Accuracy of Information. You will not post any inaccurate, misleading, incomplete
              or false information or User Content to the Services or to any other User through the
              Services. You agree that all images posted to your Candid Account are of you. You may
              be required to supply certain information and post a photo of yourself to use the
              Services.
              <br />
              <br />
              c) No Duty to Review User Content. Although you understand and acknowledge that Candid
              has no duty to prescreen, review, control, monitor or edit the User Content posted by
              Users and is not liable for User Content that is provided by others, you agree that
              Candid may, at its sole discretion, review, edit, refuse to accept or delete User
              Content at any time and for any reason or no reason without notice. You are solely
              responsible for creating backup copies and replacing any User Content that you post or
              store on the Services at your sole cost and expense. This includes Candid's right to
              modify or crop any photos you post to comply with Candid's policies, practices and
              procedures.
            </p>
          </Section>

          <Section>
            <H3>5. License of User Content to Candid</H3>
            <p>
              Candid claims no ownership or control over your User Content, except as otherwise
              specifically provided herein, on the Services or in a separate agreement. By
              submitting or posting User Content, you automatically grant, and you represent and
              warrant that you have the right to grant, to Candid, its affiliates, licensees and
              successors, an irrevocable, perpetual, non-exclusive, fully paid, worldwide right and
              license to use, copy, publicly perform, publicly display, reproduce, adapt, modify and
              distribute such User Content furnished by you and to prepare derivative works of, or
              incorporate into other works, such information and User Content, and to grant and
              authorize sublicenses of the foregoing in any medium. You represent and warrant that
              the User Content and the public posting and use of your User Content by Candid will
              not infringe or violate any third-party rights, including without limitation, any
              intellectual property rights or rights of privacy or publicity, or cause any harm to
              any third party or violate these Terms. You further represent and warrant that you
              have the written consent of each and every identifiable natural person in your User
              Content (including but not limited to any content you submit to Candid to feature) to
              use such person's name or likeness in the manner contemplated by the Services and
              these Terms, and each such person has released you from any liability that may arise
              in relation to such use. By posting User Content, you hereby release Candid,
              directors, officers, shareholders, employees, contractors and agents from any claims
              that such use, as authorized above, violates any of your rights and you understand
              that you will not be entitled to any additional compensation for any use by Candid of
              your User Content. You also grant Candid the right to choose Candid Accounts to
              feature on the Services for enhanced visibility at no extra cost, in the sole
              discretion of Candid.
            </p>
          </Section>

          <Section>
            <H4>6. Use of Proprietary Information of Others</H4>
            <p>
              You will not post, copy, transfer, create any derivative works from, distribute,
              reproduce or show in any manner any copyrighted or trade-marked or other proprietary
              information or materials, including any User Content posted by other Users, without
              the prior consent of the owner of such proprietary rights. You acknowledge that
              information or materials available through the Services may have copyright protection
              whether or not it is identified as being copyrighted.
            </p>
          </Section>

          <Section>
            <H4>7. Communications</H4>
            <p>
              We may provide notices to you in any of the following ways. First, we may email you at
              the email address that you provided when you created your Candid Account. Second, we
              may present the notice on the Services. When we post notices on the Services, we post
              them in the area of the Services suitable to the notice. It is your responsibility to
              periodically review the Services for notices.
              <br />
              <br />
              Subject to the{" "}
              <Link
                className="text-candid-purple-700 capitalize"
                to={allRoutes.privacy.buildPath({})}
              >
                Privacy Policy
              </Link>{" "}
              , if you send to Candid or post on the Services in any public area any information,
              ideas, inventions, concepts, techniques or know-how (" User Submissions "), for any
              purpose, including the developing, manufacturing and/or marketing or products or
              Services incorporating such information, you acknowledge that Candid can use the User
              Submissions without acknowledgement or compensation to you. You hereby grant to Candid
              a royalty-free, perpetual, irrevocable, worldwide, non-exclusive right and license to
              reproduce, modify, adapt, publish, translate, create derivative works from,
              distribute, perform, copy, bundle, market, adapt, transmit, arrange, sublicense,
              export, merge, transfer, loan, rent, lease, assign, share, outsource, host, make
              available to any person, display or otherwise use any User Submission communicated to
              Candid by you through the Services (including, without limitation, any ideas,
              concepts, inventions, know-how, techniques or any intellectual property contained
              therein) and to incorporate any User Submission in other works in any form, media, or
              technology now known or later developed, including for advertising and promotion
              purposes, for any purpose, whatsoever, without any duty to account to you. In
              addition, you irrevocably waive all of your moral rights as author in respect of such
              User Submission, however arising. Candid will not be required to treat any User
              Submission as confidential, and may use any User Submission in its business (including
              without limitation, for products or advertising) without incurring any liability for
              royalties or making any other consideration available to you of any kind, and Candid
              will not incur any liability as a result of any similarities that may appear in future
              Candid operations. For greater clarity, Candid also reserves the right, in its sole
              discretion, to refuse to post any User Submission that it receives from you. Candid
              will not republish any personally identifiable images of users without their express
              written consent.
            </p>
          </Section>

          <Section>
            <H4>8. Objectionable or Prohibited Content</H4>
            <p>
              ICandid has no tolerance for objectionable or prohibited content. You are not allowed
              to post, transmit or deliver to any other User, either directly or indirectly, any
              User Content that violates any third-party rights or any applicable American or
              international law, rule or regulation or is prohibited under these Terms or any other
              Candid policy governing your use of the Services ("Objectionable or Prohibited Content
              "). Objectionable or Prohibited Content includes without limitation User Content that:
              <br />
              <br />
              i) is obscene, pornographic, profane, defamatory, abusive, offensive, indecent,
              sexually oriented, lewd, threatening, harassing, inflammatory, inaccurate,
              misrepresentative, fraudulent or illegal;
              <br />
              <br />
              ii) promotes racism, sexism, homophobia, bigotry, hatred or physical harm of any kind
              against any group or individual;
              <br />
              <br />
              iii) is intended to, or does, harass, or intimidate any other User or third party;
              <br />
              <br />
              iv) may infringe or violate any patent, trade-mark, trade secret, copyright or other
              intellectual or proprietary right of any party, including User Content that contains
              others' copyrighted content (e.g., photos, images, music, movies, videos, etc.)
              without obtaining proper permission first;
              <br />
              <br />
              v) contains video, audio, photographs, or images of another person without his or her
              express written consent (or in the case of a minor, the minor's legal guardian) or
              otherwise violates anyone's right of privacy or publicity;
              <br />
              <br />
              vi) promotes or enables illegal or unlawful activities, such as instructions on how to
              make or buy illegal weapons or drugs;
              <br />
              <br />
              vii) violates someone's data privacy or data protection rights;
              <br />
              <br />
              viii) contains viruses, time bombs, malware, trojan horses, cancelbots, worms or other
              harmful, or disruptive codes, components or devices;
              <br />
              <br />
              ix) contains any advertising, fundraising or promotional content; or
              <br />
              <br />
              x) is, in the sole judgment of Candid, objectionable or restricts or inhibits any
              person from using or enjoying the Services or exposes Candid or its Users to harm or
              liability of any type.
            </p>
          </Section>

          <Section>
            <H4>9. Privacy</H4>
            <p>
              Candid takes the protection of your personal information seriously. Please refer to
              our{" "}
              <Link
                className="text-candid-purple-700 capitalize"
                to={allRoutes.privacy.buildPath({})}
              >
                Privacy Policy
              </Link>{" "}
              for information about how Candid collects, uses, stores and discloses personal
              information from its Users. You understand and agree that if you post any User
              Content, information or material of a personal or private nature in your profile or in
              any private or public areas of the Services or post or provide to Candid any
              information or content which is intended to be shared with other Users, such content,
              information and materials will be shared with others accordingly, and you hereby
              consent to such sharing. You understand that by using the Services you consent to the
              collection, use and disclosure of your personal information as described in our{" "}
              <Link
                className="text-candid-purple-700 capitalize"
                to={allRoutes.privacy.buildPath({})}
              >
                Privacy Policy
              </Link>
              .
            </p>
          </Section>

          <Section>
            <H4>10. Intellectual Property Rights And Limited License</H4>
            <p>
              Other than your User Content, the Services and all materials therein, including,
              without limitation, software, images, text, graphics, designs, illustrations, Candid
              logos, patents, trade-marks, service marks, copyrights, photographs, audio, videos,
              music, information, data, other files and the arrangement thereof and User Content
              belonging to other Users (the " Proprietary Materials "), and all intellectual
              property rights related thereto, are the exclusive property of Candid and its
              licensors (including other Users who post User Content to the Services). Except as
              explicitly provided herein, nothing in these Terms shall be deemed to create a license
              in or under any such intellectual property rights of Candid. You are granted a
              limited, non-sublicensable license to access and use the Services and Proprietary
              Materials, subject to these Terms. You agree that you will not:
              <br />
              <br />
              i) copy, modify, publish, adapt, sublicense, translate, sell, distribute, transmit,
              perform, display, reverse engineer, decipher, decompile or otherwise disassemble any
              portion of the Proprietary Materials or the Services or cause others to do so;
              <br />
              <br />
              ii) " frame " or " mirror " any part of the Services or the Proprietary Materials,
              without our prior written authorization;
              <br />
              <br />
              iii) use meta tags or code or other devices containing any reference to Candid or the
              Services in order to direct any person to any other site for any purpose;
              <br />
              <br />
              iv) resell or make any commercial use of the Services or Proprietary Materials;
              <br />
              <br />
              v) use any data mining, robots, spiders or similar data gathering or extraction
              methods or otherwise collect any pictures, descriptions, data or other content from
              the Services or Proprietary Materials;
              <br />
              <br />
              vi) forge headers or otherwise manipulate identifiers in order to disguise the origin
              of any information transmitted through the Services;
              <br />
              <br />
              vii) use any automated methods or processes to create Candid Accounts or access the
              Services or Proprietary Materials; or
              <br />
              <br />
              viii) use the Proprietary Materials or the Services other than for their intended
              purpose. Any use of the Services or Proprietary Materials other than as expressly
              authorized herein, without the prior written consent of Candid, is strictly prohibited
              and will violate and terminate the license granted herein. Such unauthorized use may
              also violate applicable HongKong or international laws, including without limitation
              copyright and trade-mark laws and applicable communications regulations and statutes.
              Unless explicitly stated herein, nothing in these Terms shall be construed as
              conferring any license to intellectual property rights, whether by estoppel,
              implication or otherwise. Candid reserves all rights not expressly granted herein in
              the Services and the Proprietary Materials. This license is revocable at any time.
            </p>
          </Section>
          <Section>
            <H4>11. Trade-marks</H4>
            <p>
              "<span className="font-bold">Candid</span>", Candid's logos and any other trade names
              or slogans contained in the Services are trade-marks or service marks of Candid, its
              partners or its licensors and may not be copied, imitated or used, in whole or in
              part, without the prior written permission of Candid or the applicable trade mark
              holder. In addition, the look and feel of the Services, including all page headers,
              custom graphics, button icons and scripts, is the service mark, trade mark and/or
              trade dress of Candid and may not be copied, imitated or used, in whole or in part,
              without our prior written permission. All other trade-marks, registered trade-marks,
              product names and company names or logos mentioned in the Services are the property of
              their respective owners. Reference to any products, services, processes or other
              information, by trade name, trade-mark, manufacturer, supplier or otherwise does not
              constitute or imply endorsement, sponsorship or recommendation thereof by us.
            </p>
          </Section>

          <Section>
            <H4>12. Third Party Content</H4>
            <p>
              Candid may provide third party content on the Services and may provide links to web
              pages, applications, mobile apps, sites and content of third parties (collectively the
              " Third Party Content ") as a service to those interested in this information. Candid
              does not control, endorse or adopt any Third Party Content and expressly disclaims any
              and all representations, warranties or conditions of any kind regarding the Third
              Party Content, including without limitation regarding its accuracy or completeness.
              You acknowledge and agree that Candid is not responsible or liable in any manner for
              any Third Party Content and Candid undertakes no responsibility to update or review
              any Third Party Content. Users use such Third Party Content contained therein at their
              own risk.
            </p>
          </Section>

          <Section>
            <H4>13. Links to Third Party Sites or Resources</H4>
            <p>
              The Services may include links to other apps or sites that are not operated by us,
              including through advertisements. We are providing these links to you only as a
              convenience and are not responsible for the content or links displayed on such apps or
              sites. If you decide to download a third-party app or access a third-party site you
              assume all risk related to such access and use. Additionally, you acknowledge and
              agree that any personal information collected, used, processed and disclosed through
              your use of such apps or sites shall be governed by the privacy policy and practices
              of such app/site owners, not by Candid under its{" "}
              <Link
                className="text-candid-purple-700 capitalize"
                to={allRoutes.privacy.buildPath({})}
              >
                Privacy Policy
              </Link>{" "}
              or otherwise.
            </p>
          </Section>

          <Section>
            <H4>14. Advertising</H4>
            <p>
              Candid and its licensees may publicly display advertisements and other information
              adjacent to your User Content. You are not entitled to any compensation for such
              advertisements. The manner, mode and extent of such advertising are subject to change
              without specific notice to you.
            </p>
          </Section>

          <Section>
            <H4>15. End User Licenses</H4>
            <p>
              a) License Grant. Subject to these Terms, Candid hereby grants you a non-exclusive,
              non-transferable, revocable license to use a compiled code copy of the Services for
              one (1) Candid Account for your personal, noncommercial use. Candid does not represent
              or warrant that the Services will be compatible with your web browser and/or mobile
              device at any time.
              <br />
              <br />
              b) Restrictions. You may not: (i) modify, disassemble, decompile or reverse engineer
              the Services, except to the extent that such restriction is expressly prohibited by
              law; (ii) rent, lease, loan, resell, sublicense, distribute or otherwise transfer the
              Services to any third party or use the Services to provide time sharing or similar
              services to any third party; (iii) make any copies of the Services; (iv) remove,
              circumvent, disable, damage or otherwise interfere with security-related features of
              the Services, features that prevent or restrict use or copying of any content
              accessible through the Services, or features that enforce limitations on use of the
              Services; or (v) delete the copyright and any other proprietary rights notices on the
              Services.
              <br />
              <br />
              c) Software Updates. You acknowledge that Candid may from time to time issue upgraded
              versions of the Services, and may automatically electronically upgrade the version of
              the Services that you are using. You consent to such automatic upgrading, and agree
              that these Terms will apply to all such upgrades. You agree that Candid will not be
              liable to you for any such modifications.
              <br />
              <br />
              d) Rights Reserved. The foregoing license granted under these Terms is not a sale of
              the Services or any copy thereof and Candid or its third party partners or suppliers
              retain all rights, title, and interest in the Services (and any copy thereof). Any
              attempt by you to transfer any of the rights, duties or obligations hereunder, except
              as expressly provided for in these Terms, is void. Candid reserves all rights not
              expressly granted under these Terms.
              <br />
              <br />
              e) App Stores. You acknowledge and agree that the availability of the Apps is
              dependent on the third parties from which you received the Apps, e.g., Google Play or
              the Apple app store (collectively " App Stores "). You acknowledge that these Terms
              are between you and Candid and not with the App Stores and that each App Store may
              have its own terms and conditions to which you must agree before downloading the Apps
              from it. You agree to comply with, and your license to use the Apps is conditioned
              upon your compliance with, all applicable terms and conditions of the particular App
              Store.
            </p>
          </Section>

          <Section>
            <H4>16. PURCHASES AND AUTOMATICALLY RENEWING SUBSCRIPTIONS</H4>
            <p className="font-bold">
              You will have the opportunity to purchase products and services from Candid. If you
              purchase a subscription, it will automatically renew—and you will be charged—until you
              cancel.
            </p>
            <p>
              Candid may offer products and services for purchase through iTunes, Google Play or
              other external services authorized by Candid (each, an “External Service,” and any
              purchases made thereon, an “External Service Purchase”). Candid may also offer
              products and services for purchase via credit card or other payment processors on the
              Website or inside the App (“Internal Purchases”). If you purchase a subscription, it
              will automatically renew until you cancel, in accordance with the terms disclosed to
              you at the time of purchase, as further described below. If you cancel your
              subscription, you will continue to have access to your subscription benefits until the
              end of your subscription period, at which point it will expire.
              <br />
              <br />
              Because our Services may be utilized without a subscription, canceling your
              subscription does not remove your profile from our Services. If you wish to fully
              terminate your membership, you must terminate your membership as set forth in Section
              23. subscription period, at which point it will expire.
              <br />
              <br />
              Candid frequently offer promotional rates - which can vary based on region, length of
              subscription, bundle size and more. We also regularly test new features and payment
              options.
            </p>
          </Section>

          <Section>
            <H4>16a. EXTERNAL SERVICE PURCHASES AND SUBSCRIPTIONS</H4>
            <p className="font-bold">
              External Service Purchases, including subscriptions, may be processed through the
              External Service, in which case those purchases must be managed through your External
              Service Account. Subscriptions automatically renew until you cancel.
            </p>
            <p>
              When making a purchase on the Service, you may have the option to pay through an
              External Service, such as with your Apple ID or Google Play account (“your External
              Service Account”), and your External Service Account will be charged for the purchase
              in accordance with the terms disclosed to you at the time of purchase and the general
              terms applicable to your External Service Account. Some External Services may charge
              you sales tax, depending on where you live, which may change from time to time.
              <br />
              <br />
              If your External Service Purchase includes an automatically renewing subscription,
              your External Service Account will continue to be periodically charged for the
              subscription until you cancel. After your initial subscription commitment period, and
              again after any subsequent subscription period, the subscription will automatically
              continue for the price and time period you agreed to when subscribing.
              <br />
              <br />
              To cancel a subscription: If you do not want your subscription to renew automatically,
              or if you want to change or terminate your subscription, you must log in to your
              External Service Account and follow instructions to manage or cancel your
              subscription, even if you have otherwise deleted your account with us or if you have
              deleted the App from your device. For example, if you subscribed using your Apple ID,
              cancellation is handled by Apple, not Candid. To cancel a purchase made with your
              Apple ID, go to Settings {">"} iTunes & App Stores{" "}
              <FontAwesomeIcon icon={faArrowRight} /> [click on your Apple ID]{" "}
              <FontAwesomeIcon icon={faArrowRight} /> View Apple ID{" "}
              <FontAwesomeIcon icon={faArrowRight} /> Subscriptions, then find your Candid
              subscription and follow the instructions to cancel. You can also request assistance at
              <a className="text-blue-600" href="https://getsupport.apple.com">
                {" "}
                https://getsupport.apple.com
              </a>
              . Similarly, if you subscribed on Google Play, cancellation is handled by Google. To
              cancel a purchase made through Google Play, launch the Google Play app on your mobile
              device and go to Menu <FontAwesomeIcon icon={faArrowRight} /> My Apps{" "}
              <FontAwesomeIcon icon={faArrowRight} /> Subscriptions, then find your Candid
              subscription and follow the instructions to cancel. You can also request assistance at{" "}
              <a className="text-blue-600" href="https://getsupport.apple.com">
                https://play.google.com
              </a>
              . If you cancel a subscription, you may continue to use the cancelled service until
              the end of your then-current subscription term. The subscription will not be renewed
              when your then-current term expires.
              <br />
              <br />
              If you initiate a chargeback or otherwise reverse a payment made with your External
              Service Account, Candid may terminate your account immediately in its sole discretion.
              Candid will retain all funds charged to your External Service Account until you cancel
              your subscription through your External Service Account. Certain users may be entitled
              to request a refund. See Section 8d below for more information.
            </p>
          </Section>

          <Section>
            <H4>16b. INTERNAL PURCHASES AND SUBSCRIPTIONS</H4>
            <p className="font-bold">
              Internal Purchases, including subscriptions, are processed using the Payment Method
              you provide on the Website or App. Subscriptions automatically renew until you cancel.
            </p>
            <p>
              If you make an Internal Purchase, you agree to pay the prices displayed to you for the
              Services you've selected as well as any sales or similar taxes that may be imposed on
              your payments (and as may change from time to time), and you authorize Candid to
              charge the payment method you provide (your “Payment Method”). Candid may correct any
              billing errors or mistakes even if we have already requested or received payment. If
              you initiate a chargeback or otherwise reverse a payment made with your Payment
              Method, Candid may terminate your account immediately in its sole discretion, on the
              basis that you have determined that you do not want a Candid subscription. In the
              event that your chargeback or other payment reversal is overturned, please contact
              Customer Service at <Highlight>support@trustcandid.com</Highlight>.
              <br />
              <br />
              If your Internal Purchase includes an automatically renewing subscription, your
              Payment Method will continue to be periodically charged for the subscription until you
              cancel. After your initial subscription commitment period, and again after any
              subsequent subscription period, your subscription will automatically continue for the
              price and time period you agreed to when subscribing, until you cancel.
              <br />
              <br />
              To cancel a subscription, log in to the Website or App and go to the Settings tool. If
              you cancel a subscription, you may continue to use the cancelled service until the end
              of your then-current subscription term. The subscription will not be renewed when your
              then-current term expires.
              <br />
              <br />
              To cancel a subscription, you can (1) log in to the Website or App and go to the
              Settings page, and then click on “Subscriptions”, and follow the instructions to
              downgrade; or (2) open the Android App and go to the Settings page, then click on
              Purchases and follow the instructions to downgrade. If you cancel a subscription, you
              may continue to use the cancelled service until the end of your then-current
              subscription term. The subscription will not be renewed when your then-current term
              expires.
              <br />
              <br />
              You may edit your Payment Method information by using the Settings tool. If a payment
              is not successfully processed, due to expiration, insufficient funds, or otherwise,
              you remain responsible for any uncollected amounts and authorize us to continue
              billing the Payment Method, as it may be updated. This may result in a change to your
              payment billing dates.
              <br />
              <br />
              In addition, you authorize us to obtain updated or replacement expiration dates and
              card numbers for your credit or debit card as provided by your credit or debit card
              issuer. The terms of your payment will be based on your Payment Method and may be
              determined by agreements between you and the financial institution, credit card
              issuer, or other provider of your chosen Payment Method. Certain users may be entitled
              to request a refund. See Section 16d below for more information.
            </p>
          </Section>

          <Section>
            <H4>16c. VIRTUAL ITEMS</H4>
            <p className="font-bold">
              Virtual items are non-refundable and subject to certain conditions.
            </p>
            <p>
              From time to time, you may have the opportunity to purchase a limited, personal,
              non-transferable, non-sublicensable, revocable license to use or access special
              limited-use features such as “Boost” (“Virtual Item(s)”) from Candid. You may only
              purchase Virtual Items from us or our authorized partners through our Services.
              Virtual Items represent a limited license right governed by this Agreement, and,
              except as otherwise prohibited by applicable law, no title or ownership in or to
              Virtual Items is being transferred or assigned to you. This Agreement should not be
              construed as a sale of any rights in Virtual Items.
              <br />
              <br />
              Any Virtual Item balance shown in your account does not constitute a real-world
              balance or reflect any stored value, but instead constitutes a measurement of the
              extent of your license. Virtual Items do not incur fees for non-use; however, the
              license granted to you in Virtual Items will terminate in accordance with the terms of
              this Agreement, on the earlier of when Candid ceases providing our Services, or your
              account is otherwise closed or terminated.
              <br />
              <br />
              Candid, in its sole discretion, reserves the right to charge fees for the right to
              access or use Virtual Items and/or may distribute Virtual Items with or without
              charge. Candid may manage, regulate, control, modify, or eliminate Virtual Items at
              any time, including taking actions that may impact the perceived value or purchase
              price, if applicable, of any Virtual Items. Candid shall have no liability to you or
              any third party in the event that Candid exercises any such rights. The transfer of
              Virtual Items is prohibited, and you shall not sell, redeem, or otherwise transfer
              Virtual Items to any person or entity. Virtual Items may only be redeemed through our
              Services.
              <br />
              <br />
              ALL PURCHASES AND REDEMPTIONS OF VIRTUAL ITEMS MADE THROUGH OUR SERVICES ARE FINAL AND
              NON-REFUNDABLE. YOU ACKNOWLEDGE THAT CANDID IS NOT REQUIRED TO PROVIDE A REFUND FOR
              ANY REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED
              VIRTUAL ITEMS WHEN AN ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY OR
              INVOLUNTARY.
            </p>
          </Section>

          <Section>
            <H4>16d. REFUNDS</H4>
            <p className="font-bold">
              Generally, all purchases are nonrefundable. Special terms apply in Arizona,
              California, Colorado, Connecticut, Illinois, Iowa, Minnesota, New York, North
              Carolina, Ohio, Rhode Island, and Wisconsin.
            </p>
            <p className="underline">
              For subscribers residing in Arizona, California, Colorado, Connecticut, Illinois,
              Iowa, Minnesota, New York, North Carolina, Ohio, Rhode Island, and Wisconsin:{" "}
            </p>
            <p>
              Your Right to Cancel—You may cancel your subscription, without penalty or obligation,
              at any time prior to midnight of the third business day following the date you
              subscribed. In the event that you die before the end of your subscription period, your
              estate shall be entitled to a refund of that portion of any payment you had made for
              your subscription which is allocable to the period after your death. In the event that
              you become disabled (such that you are unable to use our Services) before the end of
              your subscription period, you shall be entitled to a refund of that portion of any
              payment you had made for your subscription which is allocable to the period after your
              disability by providing the Company notice in the same manner as you request a refund
              as described below.
              <br />
              <br />
              Purchases of Virtual Items are FINAL AND NON-REFUNDABLE.
              <br />
              <br />
              If any of the above apply to you and you subscribed using your Apple ID, your refund
              requests are handled by Apple, not Candid. To request a refund, please contact your
              External Service directly; for example using your Apple device, go to Settings{" "}
              <FontAwesomeIcon icon={faArrowRight} /> iTunes & App Stores{" "}
              <FontAwesomeIcon icon={faArrowRight} /> [click on your Apple ID]{" "}
              <FontAwesomeIcon icon={faArrowRight} /> View Apple ID{" "}
              <FontAwesomeIcon icon={faArrowRight} /> Purchase History. Find the transaction and
              select “Report a Problem.” You can also request a refund at{" "}
              <a className="text-blue-600" href="https://getsupport.apple.com">
                {" "}
                https://getsupport.apple.com
              </a>
              . For any other purchase, please contact Candid Customer Service with your order
              number (see your confirmation email) by emailing a signed and dated notice which
              states that you, the buyer, are canceling this Agreement, or words of similar effect.
              Please also include the email address or telephone number associated with your account
              along with your order number. This notice shall be sent to{" "}
              <Highlight>support@trustcandid.com</Highlight>.
            </p>
          </Section>

          <Section>
            <H4>17. Free Trials And Promotions</H4>
            <p>
              From time to time, we may offer free trials or other promotions (a " Promotion "). As
              an example, we may offer Promotions that provide a free Subscription to the Services
              for a certain period of time. You must cancel your subscription (in accordance with
              Section 16) before the end of the Promotion period in order to avoid being
              automatically charged for Subscription Fees. As another example, we may provide you
              with our virtual currency without charge. Any such virtual currency is subject to the
              terms of our Virtual Goods and Currency Terms of Service . Additional Terms applicable
              to specific Promotions may be provided on the Candid Software.
            </p>
          </Section>

          <Section>
            <H4>18. Modification Of These Terms</H4>
            <p>
              We reserve the right, in our sole discretion, to change, modify, add, or remove
              portions of any of the terms and conditions contained in these Terms including any
              policy or guideline applicable to the Services at any time. Unless otherwise
              specified, any changes or modifications will be effective immediately upon posting of
              the revisions on the Services, and your continued use of the Services after such
              posting will constitute your acceptance of such changes or modifications. You should
              from time to time review the Terms and any policies and documents incorporated in them
              to understand the terms and conditions that apply to your use of the Services. The
              Terms will always show the " last updated " date at the top. If you do not agree to
              any amended Terms, you must stop using the Services. If you have any questions about
              these Terms, please email us at the contact address below. These changes will be
              effective immediately for new and current Users of the Services.
            </p>
          </Section>

          <Section>
            <H4>19. DISCLAIMERS</H4>
            <p>
              Candid PROVIDES THE SERVICES AND THE PROPRIETARY MATERIALS AND THE SERVICES ON AN " AS
              IS " AND " AS AVAILABLE " BASIS. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW,
              Candid EXPRESSLY DISCLAIMS ANY REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND,
              WHETHER EXPRESS, IMPLIED, ARISING FROM STATUTE OR OTHERWISE IN LAW OR FROM A COURSE OF
              DEALING OR USAGE OF TRADE WITH RESPECT TO THE SERVICES AND THE PROPRIETARY MATERIALS,
              INCLUDING ANY REPRESENTATIONS, WARRANTIES OR CONDITIONS OF MERCHANTABLE QUALITY,
              TITLE, ACCURACY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT.
              <br />
              <br />
              Candid DOES NOT REPRESENT AND WARRANT THAT (A) YOUR USE OF THE SERVICES OR PROPRIETARY
              MATERIALS WILL BE SECURE, UNINTERRUPTED, COMPLETE, ALWAYS AVAILABLE, ERROR-FREE OR
              WILL MEET YOUR REQUIREMENTS, (B) ANY DEFECTS IN THE SERVICES OR PROPRIETARY MATERIALS
              WILL BE CORRECTED OR (C) THE SERVICES AND PROPRIETARY MATERIALS ARE FREE OF VIRUSES OR
              OTHER HARMFUL COMPONENTS. Candid DISCLAIMS LIABILITY FOR, AND NO WARRANTY IS MADE WITH
              RESPECT TO, THE CONNECTIVITY AND AVAILABILITY OF THE SERVICES OR THE DELIVERY OF ANY
              COMMUNICATIONS.
              <br />
              <br />
              Candid HAS NO ANY OBLIGATION TO VERIFY THE IDENTITY OF OR SCREEN THE PERSONS
              SUBSCRIBING TO OR USING THE SERVICES, NOR DOES IT HAVE ANY OBLIGATION TO MONITOR THE
              USE OF THE SERVICES BY OTHER USERS OF THE COMMUNITY. THEREFORE, Candid DISCLAIMS ANY
              AND ALL LIABILITY FOR YOUR INTERACTIONS WITH AND THE CONDUCT OF OTHER USERS AND FOR
              IDENTITY THEFT OR ANY OTHER MISUSE OF YOUR IDENTITY OR INFORMATION.
              <br />
              <br />
              Candid DOES NOT: (i) GUARANTEE THE ACCURACY, COMPLETENESS OR USEFULNESS OF ANY
              INFORMATION ON THE SERVICES OR ANY RESULTS FROM YOUR USE OF THE SERVICES; OR (ii)
              ADOPT, ENDORSE OR ACCEPT RESPONSIBILITY OR LIABILITY FOR THE CONDUCT OF ANY USERS OR
              FOR THE ACCURACY OR RELIABILITY OF ANY OPINION, ADVICE, OR STATEMENT MADE BY ANY PARTY
              OTHER THAN Candid. UNDER NO CIRCUMSTANCES WILL Candid BE RESPONSIBLE FOR ANY LOSSES,
              DAMAGES OR HARM OF ANY KIND RESULTING FROM ANY USER'S CONDUCT OR YOUR USE OF THE
              SERVICES OR FROM ANYONE'S RELIANCE ON INFORMATION OR OTHER CONTENT POSTED ON THE
              SERVICES, OR TRANSMITTED TO OR BY ANY USERS.
            </p>
          </Section>

          <Section>
            <H4>20. LIMITATION OF LIABILITY; INDEMNITY</H4>
            You hereby waive and agree not to assert any claims or allegations of any nature
            whatsoever against Candid, its affiliates or subsidiaries, their sponsors, contractors,
            advertisers, vendors or other partners, any of their successors or assigns, or any of
            their respective officers, directors, agents or employees (collectively, the "{" "}
            <span className="font-bold">Released Parties</span> ") arising out of or in any way
            relating to your use of the Services or Proprietary Materials, including, without
            limitation, any claims or allegations relating to the alleged infringement of
            proprietary rights, alleged inaccuracy of User Content, or allegations that any Released
            Party has or should indemnify, defend or hold harmless you or any third party from any
            claim or allegation arising from your use of the Services. Your use of the Services and
            Proprietary Materials is entirely at your own risk.
            <br></br>
            Without limiting any of the foregoing, neither Candid nor any other Released Party shall
            be liable to you for any direct, special, indirect, exemplary, consequential, punitive
            damages, or any other damages of any kind, including but not limited to loss of use,
            loss of profits, loss of data, loss of good will, data, or other intangible losses or
            other economic loss, whether in an action in contract, tort (including but not limited
            to negligence) or otherwise, arising out of or in any way connected with your use of the
            Services or any Proprietary Materials, including without limitation any damages caused
            by or resulting from: (i) your use or inability to use the Services or any Proprietary
            Materials; (ii) any third party claims that the use by you of any of the Services or
            Proprietary Materials violates any third party intellectual property right or privacy
            right; (iii) any failure of performance of the Services or any Proprietary Materials,
            whether related to mistakes, errors, omissions, interruptions, defects, delays in
            operation or transmission, deletion of files or email, computer viruses or any failure
            of performance (whether or not resulting from acts of god, communications failure,
            theft, destruction or unauthorized access to Candid or any other Released Party's
            records, programs or services); (iv) any damages arising from communications or
            transactions with other Users of the Services, whether through blogs, or otherwise, or
            persons that you meet through the Services; or (v) any other matters relating to the
            Services, Proprietary Materials, any User Submissions (as defined above), based in
            contract, tort, negligence, strict liability, fundamental breach, failure of essential
            purpose or otherwise, whether or not Candid had any knowledge, actual or constructive,
            that you might incur such damages.
            <br></br>
            IN NO EVENT WILL THE TOTAL LIABILITY OF Candid OR THE RELEASED PARTIES ARISING OUT OF OR
            IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES OR
            PROPRIETARY MATERIALS EXCEED THE GREATER OF THE AMOUNT YOU HAVE PAID TO Candid FOR USE
            OF THE SERVICES AND PROPRIETARY MATERIALS IN THE PRECEDING 12 MONTH PERIOD OR $50 USD.
            THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE
            BARGAIN BETWEEN Candid AND YOU.
            <br></br>
            You shall fully defend, indemnify and hold harmless Candid and the other Released
            Parties from any losses, damages, liabilities, costs, expenses (including legal fees),
            claims and proceedings arising out of your use (or the use by any person to whom you
            have given access to your Candid Account) of the Services, provision of User Content and
            the Proprietary Materials, including any claims made by any person that any of your User
            Content infringes the rights, including the intellectual property rights of any third
            party.
          </Section>

          <Section>
            <H4>21. Applicable Law; Jurisdiction</H4>
            <p>
              The Services are controlled by Candid and operated by it from its offices in Florida.
              All disputes, claims or other matters arising from or relating to your use of the
              Services, the Proprietary Materials and these Terms will be governed by the laws of
              Florida applicable therein, excluding the application of any conflicts of laws rules.
              The United Nations Convention on Contracts for the International Sale of Goods will
              not apply to these Terms.
            </p>
          </Section>

          <Section>
            <H4>22. Dispute Resolution</H4>
            <p>
              Please read the following arbitration provision in this carefully. Unless you opt out
              in the manner described in subsections 12 and 13 below, this arbitration provision
              requires you to arbitrate disputes with Candid and limits the manner in which you seek
              relief from us.
            </p>
            <ol>
              <li>
                You and Candid agree that any dispute that has arisen or may arise between us
                relating in any way to Your use of or access to the Candid Services, any validity,
                interpretation, breach, enforcement, or termination of this Agreement, or otherwise
                relating to Candid in any way (collectively, “Covered Dispute Matters” or
                “Disputes”) will be resolved in accordance with the provisions set forth in this
                Section 22.
              </li>
              <li>
                <span className="underline">Informal Resolution</span>. You and Candid agree that
                good faith, informal efforts to resolve Disputes can result in a prompt, low-cost,
                and mutually-beneficial outcome. You and Candid therefore agree that before either
                party commences arbitration against the other (or initiates an action in small
                claims court if a party so elects), we will personally meet and confer
                telephonically or via videoconference, in a good faith effort to resolve informally
                any Dispute covered by this arbitration provision (“Informal Dispute Resolution
                Conference” or “Conference”). If You are represented by counsel, Your counsel may
                participate in the conference, but You will also participate in the conference. The
                party initiating a Dispute must give notice to the other party in writing of its
                intent to initiate a Conference (“Notice”), which shall occur within forty-five (45)
                days after the other party receives such Notice, unless an extension is mutually
                agreed upon by the parties. Notice to Candid that You intend to initiate a
                Conference should be sent by email to <Highlight>legal@trustcandid.com</Highlight>.
                The Notice must include: (1) Your name, phone number, mailing address, and the email
                address associated with Your User Account (if You have one); (2) the name, telephone
                number, mailing address and email address of Your counsel, if any; and (3) a
                description of Your Dispute. The Conference shall be individualized such that a
                separate Conference must be held each time either party initiates a Dispute, even if
                the same law firm or group of law firms represents multiple users in similar cases,
                unless all parties agree; multiple individuals initiating a Dispute cannot
                participate in the same Conference unless all parties agree. In the time between a
                party receiving the Notice and the Conference, nothing in this arbitration provision
                shall prohibit the parties from engaging in informal communications to resolve the
                initiating party’s Dispute. Engaging in the Conference is a condition precedent and
                requirement that must be fulfilled before commencing arbitration. The statute of
                limitations and any filing fee deadlines shall be tolled while the parties engage in
                the Conference process required by this section.
              </li>

              <li>
                <span className="underline">Applicable Law.</span>
                You and We agree that United States federal law including Federal Arbitration Act, 9
                U.S.C. § 1 et seq. (“FAA”), and (to the extent not inconsistent with or pre-empted
                by federal law) the laws of the State of Florida, USA, without regard to conflict of
                laws principles, will govern all Covered Dispute Matters, except as may be expressly
                provided in the Special Terms.
              </li>

              <li>
                <span className="underline">Our Arbitration.</span>
                You and We agree that this provision and each of its parts evidence a transaction
                involving interstate commerce, and the FAA applies in all cases and governs the
                interpretation and enforcement of the arbitration rules and arbitration proceedings.
                Any Covered Dispute Matter must be asserted individually in binding arbitration
                administered by the American Arbitration Association (“AAA”) in accordance with its
                Consumer Arbitration Rules (including utilizing desk, phone or video conference
                proceedings where appropriate and permitted to mitigate costs of travel) available
                at
                <a className="text-blue-600" href="https://getsupport.apple.com">
                  {" "}
                  https://www.adr.org/sites/default/files/Consumer%20Rules.pdf
                </a>
                . A party who wishes to initiate arbitration must provide the other party with a
                request for arbitration (the “Request”). The Request must include (1) the name,
                telephone number, mailing address, email address of the party seeking arbitration
                and the account username (if applicable) as well as the email address associated
                with any applicable User Account; (2) a statement of the legal claims being asserted
                and the factual bases of those claims; (3) a description of the remedy sought and an
                accurate, good-faith calculation of the amount in controversy in United States
                Dollars; (4) a statement certifying completion of the Information Dispute Resolution
                process as above; and (5) evidence that the requesting party has paid any necessary
                filing fees in connection with such arbitration. If the party requesting arbitration
                is represented by counsel, the Request shall also include counsel’s name, telephone
                number, mailing address, and email address. Such counsel must also sign the Request.
                By signing the Request, counsel certifies to the best of counsel’s knowledge,
                information, and belief, formed after an inquiry reasonable under the circumstances
                that: (1) the Request is not being presented for any improper purpose, such as to
                harass, cause unnecessary delay, or needlessly increase the cost of dispute
                resolution; (2) the claims, defenses and other legal contentions are warranted by
                existing law or by a nonfrivolous argument for extending, modifying, or reversing
                existing law or for establishing new law; and (3) the factual and damages
                contentions have evidentiary support or, if specifically so identified, will likely
                have evidentiary support after a reasonable opportunity for further investigation or
                discovery. The arbitrator will be either a retired judge or an attorney licensed to
                practice law in the state of Florida and will be selected by the parties from the
                AAA’s roster of consumer dispute arbitrators. If the parties are unable to agree
                upon an arbitrator within thirty-five (35) days of delivery of the Request, then the
                AAA will appoint the arbitrator in accordance with the AAA Rules, provided that if
                the Batch Arbitration process under subsection 9 is triggered, the AAA will appoint
                the arbitrator for each batch.
                <br />
                <br />
                Unless you and Candid otherwise agree, or the Batch Arbitration process discussed in
                subsection 9 below is triggered, the arbitration will be conducted in the county
                where you reside. You and We agree that the arbitrator, and not any federal,
                international, state, or local court or agency, shall have exclusive authority to
                resolve any dispute relating to the interpretation, applicability, enforceability or
                formation of this Agreement, including any claim that all or any part of this
                Agreement is void or voidable or a particular claim is subject to arbitration,
                except for the following: (1) all disputes arising out of or relating to subsection
                14 of this Section including any claim that all or part of that same subsection is
                unenforceable, illegal, void or voidable, or that that same subsection has been
                breached, shall be decided by a court of competent jurisdiction and not by an
                arbitrator; (2) except as expressly contemplated in subsection 9, all disputes about
                the payment of arbitration fees shall be decided only by a court of competent
                jurisdiction and not by an arbitrator; (3) all disputes about whether either party
                has satisfied any condition precedent to arbitration shall be decided only by a
                court of competent jurisdiction and not by an arbitrator; and (4) all disputes about
                which version of the arbitration provision applies shall be decided only by a court
                of competent jurisdiction and not by an arbitrator.
              </li>
              <li>
                <span className="underline">The Arbitrator’s Award to You or Us.</span> You and We
                agree that for matters where the relief sought is over $5,000, the arbitrator’s
                decision will include the essential findings and conclusions upon which the
                arbitrator based the award. The arbitrator will decide the substance of all claims
                in accordance with applicable law, including recognized principles of equity, and
                will honor all claims of privilege recognized by law. The arbitrator shall not be
                bound by rulings in prior arbitrations involving different Users, but is bound by
                rulings in prior arbitrations involving the same Candid User to the extent required
                by applicable law. You and We agree that the arbitrator’s award shall be final and
                binding, and judgment on the award rendered by the arbitrator may be entered in any
                court having jurisdiction thereof.
              </li>

              <li>
                <span className="underline">Injunctive and Declaratory Relief.</span>
                Subject to this Arbitration provision, the arbitrator may award declaratory or
                injunctive relief only in favor of the individual party seeking relief and only to
                the extent necessary to provide relief warranted by the party's individual claim.
                Nothing in this paragraph is intended to, nor shall it, affect the terms and
                conditions under the subsection 9 entitled “Batch Arbitration.” Notwithstanding
                anything to the contrary in this Arbitration Agreement, if a court decides by means
                of a final decision, not subject to any further appeal or recourse, that the
                limitations of this subsection, “Waiver of Class and Other Non-Individualized
                Relief,” are invalid or unenforceable as to a particular claim or request for relief
                (such as a request for public injunctive relief ), You and Candid agree that that
                particular claim or request for relief (and only that particular claim or request
                for relief) shall be severed from the arbitration and may be litigated in the state
                or federal courts located in the State of Florida. All other Disputes shall be
                arbitrated or litigated in small claims court. This subsection does not prevent You
                or Candid from participating in a class-wide settlement of claims.
              </li>

              <li>
                <span className="underline">
                  Exceptions To Our Agreement To Arbitrate Disputes.
                </span>
                There are only two exceptions to this provision to arbitrate:
                <ol>
                  <li className="mx-5">
                    First, if either party reasonably believes that the other party has in any
                    manner violated or threatened to infringe the intellectual property rights of
                    the other party, the party whose rights have been violated may seek injunctive
                    or other appropriate interim relief in any court of competent jurisdiction.
                  </li>
                  <li className="mx-5">
                    Second, each party will retain the right to seek relief in a small claims court
                    for disputes or claims within the scope of the jurisdiction of such courts.
                  </li>
                </ol>
              </li>

              <li>
                <span className="underline"> Costs of Arbitration.</span>You and We agree that
                payment of all filing, administration, and arbitrator fees will be governed by the
                AAA's rules, unless otherwise stated in this provision to arbitrate. The parties
                shall bear their own attorneys' fees and costs in arbitration unless the arbitrator
                finds that either the substance of the dispute or the relief sought in the request
                for arbitration was frivolous or was brought for an improper purpose (as measured by
                the standards set forth in Federal Rule of Civil Procedure 11(b)).
              </li>

              <li>
                <span className="underline">Batch Arbitration.</span>
                To increase the efficiency of administration and resolution of arbitrations, You and
                Candid agree that in the event that there are one hundred (100) or more individual
                requests for arbitration of a substantially similar nature filed against Candid by
                or with the assistance of the same law firm, group of law firms, or organizations,
                within a thirty (30) day period (or as soon as possible thereafter), the AAA shall
                (1) administer the arbitration demands in batches of 100 requests per batch (plus,
                to the extent there are less than 100 requests left over after the batching
                described above, a final batch consisting of the remaining requests); (2) appoint
                one arbitrator for each batch; and (3) provide for the resolution of each batch as a
                single consolidated arbitration with one set of filing and administrative fees due
                per side per batch, one procedural calendar, one hearing (if any) in a place to be
                determined by the arbitrator, and one final award (“Batch Arbitration”). All parties
                agree that requests are of a “substantially similar nature” if they arise out of or
                relate to the same event or factual scenario and raise the same or similar legal
                issues and seek the same or similar relief. To the extent the parties disagree on
                the application of the Batch Arbitration process, the disagreeing party shall advise
                the AAA, and the AAA shall appoint a sole standing arbitrator to determine the
                applicability of the Batch Arbitration process (“Administrative Arbitrator”). In an
                effort to expedite resolution of any such dispute by the Administrative Arbitrator,
                the parties agree the Administrative Arbitrator may set forth such procedures as are
                necessary to resolve any disputes promptly. The Administrative Arbitrator's fees
                shall be paid by Candid. You and Candid agree to cooperate in good faith with the
                AAA to implement the Batch Arbitration process including the payment of single
                filing and administrative fees for batches of requests, as well as any steps to
                minimize the time and costs of arbitration, which may include: (1) the appointment
                of a discovery special master to assist the arbitrator in the resolution of
                discovery disputes; and (2) the adoption of an expedited calendar of the arbitration
                proceedings. This Batch Arbitration provision shall in no way be interpreted as
                authorizing a class, collective and/or mass arbitration or action of any kind, or
                arbitration involving joint or consolidated claims under any circumstances, except
                as expressly set forth in this subsection.
              </li>

              <li>
                <span className="underline"> Future Amendments to the Agreement to Arbitrate.</span>
                Notwithstanding any provision in this Agreement to the contrary, You and We agree
                that if We make any amendment to this agreement to arbitrate in the future, that
                amendment shall not apply to any claim that was filed in a legal proceeding against
                Candid prior to the effective date of the amendment. However, the amendment shall
                apply to all other disputes or claims governed by the agreement to arbitrate that
                have arisen or may arise between You and Candid. If You do not agree to these
                amended terms, You may close Your User Account within thirty (30) days of the
                posting or notification and You will not be bound by the amended terms. Changes to
                this arbitration agreement do not provide You with a new opportunity to opt out of
                the arbitration agreement if You have previously agreed to a version of this
                Agreement and did not validly opt out of arbitration.
              </li>

              <li>
                <span className="underline">Venue for Arbitration.</span> Any arbitration instituted
                pursuant to these Terms shall be held in Miami-Dade County or such other place as
                the Parties may mutually agree, including via video conference.
              </li>

              <li>
                <span className="underline"> Judicial Forum for Legal Disputes.</span> Unless You
                and We agree otherwise and except as described in Section 22.2 (Small Claims Court),
                in the event that the agreement to arbitrate above is found not to apply to You or
                to a particular claim or dispute, either as a result of Your decision to opt out of
                the agreement to arbitrate, as a result of a decision by the arbitrator or a court
                order, or because You are an international user to which this agreement to arbitrate
                does not apply, You agree (except as otherwise provided by law) that any claim or
                dispute that has arisen or may arise between You and Candid must be resolved
                exclusively by a state or federal court located in Miami-Dade County, Florida. You
                and Candid agree to submit to the exclusive personal jurisdiction of the courts
                located within Miami-Dade County, Florida for the purpose of litigating all such
                claims or disputes.
              </li>

              <li>
                <span className="underline">Judicial Forum for Legal Disputes.</span>
                Unless You and We agree otherwise and except as described in Section 22.2 (Small
                Claims Court), in the event that the agreement to arbitrate above is found not to
                apply to You or to a particular claim or dispute, either as a result of Your
                decision to opt out of the agreement to arbitrate, as a result of a decision by the
                arbitrator or a court order, or because You are an international user to which this
                agreement to arbitrate does not apply, You agree (except as otherwise provided by
                law) that any claim or dispute that has arisen or may arise between You and Candid
                must be resolved exclusively by a state or federal court located in Miami-Dade
                County, Florida. You and Candid agree to submit to the exclusive personal
                jurisdiction of the courts located within Miami-Dade County, Florida for the purpose
                of litigating all such claims or disputes.
              </li>

              <li>
                <span className="underline">Arbitration Severability Clause.</span>F YOU ARE A NEW
                CANDID USER, YOU CAN CHOOSE TO REJECT THE AGREEMENT TO ARBITRATE PROVISION
                (“OPT-OUT”) BY EMAILING US AN OPT-OUT NOTICE TO LEGAL@TRUSTCANDID.COM (“OPT-OUT
                NOTICE”). THE OPT-OUT NOTICE MUST BE RECEIVED WITHIN THIRTY-ONE (31) DAYS AFTER THE
                DATE YOU ACCEPT THE TERMS OF THIS AGREEMENT FOR THE FIRST TIME. IF YOU ARE NOT A NEW
                CANDID USER, YOU HAVE UNTIL THIRTY-ONE (31) DAYS AFTER THE POSTING OF THE NEW TERMS
                TO SUBMIT AN ARBITRATION OPT-OUT NOTICE.
              </li>

              <li>
                <span className="underline">Arbitration Opt-Out Procedure. </span>
                In order to opt-out, within thirty-one (31) days after you accept the terms of this
                agreement for the first time, You must email Your legal name, mailing address
                (including street address, city, state, and zip code), email address(es) associated
                with Your Account(s) to which the opt-out applies, and an unaltered digital image of
                Your valid driver’s license to: legal@trustcandid.com. This procedure is the only
                way You can opt out of the agreement to arbitrate. If You opt out of the agreement
                to arbitrate, all other parts of this Agreement and this Disputes Section (including
                Sections 14 (Advertising), 15 (End User Licenses), 19 (Disclaimers), and 20
                (Limitation of Liability; Indemnity)) will continue to apply to You. Opting out of
                this agreement to arbitrate has no effect on any previous, other, or future
                arbitration agreements that You may have with Us.
              </li>

              <li>
                <span className="underline"> WAIVER OF CERTAIN RIGHTS.</span>BY AGREEING TO THIS
                AGREEMENT, YOU AND CANDID HEREBY IRREVOCABLY WAIVE ANY CONSTITUTIONAL AND STATUTORY
                RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY (OTHER THAN
                SMALL CLAIMS COURT AS PROVIDED ABOVE. YOU ALSO AGREE THAT YOU CANNOT SERVE AS A
                REPRESENTATIVE, AS A PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER REPRESENTATIVE
                CAPACITY, OR TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS, IN ANY LAWSUIT,
                ARBITRATION OR OTHER PROCEEDING FILED AGAINST US AND/OR RELATED THIRD PARTIES, EVEN
                IF ARBITRATION IS NOT REQUIRED UNDER THIS AGREEMENT EXCEPT AS SPECIFIED IN
                SUBSECTION 9 OF THIS SECTION ABOVE. You and Candid are instead electing that all
                Disputes shall be resolved by arbitration under this arbitration provision, except
                as specified in subsection 9 above. There is no judge or jury in arbitration, and
                court review of an arbitration award is subject to very limited review. This
                subsection does not prevent You or Candid from participating in a class-wide
                settlement of claims.
              </li>

              <li>
                <span className="underline">STATUTE OF LIMITATIONS FOR YOUR CLAIMS.</span>
                You and Candid further agree that any Dispute that either has as detailed in this
                Arbitration Agreement must be initiated via arbitration within the applicable
                statute of limitation for that claim or controversy, or it will be forever time
                barred. Likewise, you agree that all applicable statutes of limitation will apply to
                such arbitration in the same manner as those statutes of limitation would apply in
                the applicable court of competent jurisdiction.
              </li>
            </ol>
          </Section>

          <Section>
            <H4>23. Termination</H4>
            <p className="font-bold">
              If you no longer wish to use our Services, or if we terminate your account for any
              reason, here’s what you need to know.
            </p>
            <p>
              You can deactivate or delete your account at any time by logging into the App, going
              to the “Settings” page (the gear icon), and selecting the “Privacy” tab, and following
              the instructions to terminate your membership. However, you will need to cancel /
              manage any External Service Purchases through your External Service Account (e.g.,
              iTunes, Google Play) to avoid additional billing. For more details on terminating any
              purchases associated with your account, refer to Section 16.
              <br />
              <br />
              Candid reserves the right to investigate and, if appropriate, suspend or terminate
              your account without a refund if Candid believes that you have violated these Terms,
              misused our Services, or behaved in a way that Candid regards as inappropriate or
              unlawful, on or off our Services. We reserve the right to make use of any personal,
              technological, legal, or other means available to enforce the Terms, at any time
              without liability and without the obligation to give you prior notice, including, but
              not limited to, preventing you from accessing the Services.
              <br />
              <br />
              If your account is terminated by you or by Candid for any reason, these Terms continue
              and remain enforceable between you and Candid, and you will not be entitled to any
              refund for purchases made. Your information will be maintained and deleted in
              accordance with our{" "}
              <Link
                className="text-candid-purple-700 capitalize"
                to={allRoutes.privacy.buildPath({})}
              >
                Privacy Policy
              </Link>
              .
            </p>
          </Section>

          <Section>
            <H4>24. Miscellaneous</H4>
            <p>
              These Terms, and any rights and licenses granted hereunder, may not be transferred or
              assigned by you, but may be assigned by Candid without restriction. Any attempted
              transfer or assignment in violation hereof shall be null and void. If any provision,
              or any portion thereof, of these Terms is held illegal, void, invalid or
              unenforceable, such provision will be changed and interpreted to accomplish the
              objectives of the provision to the greatest extent possible under any applicable law
              and the remaining provisions will continue in full force and effect. The failure of
              Candid to exercise or enforce any right or provision in these Terms shall not operate
              as a waiver of such right or provision.
            </p>
          </Section>

          <Section>
            <H4>25. Language Of These Terms</H4>
            <p>
              The language of these Terms is English. The English language version of these Terms
              will govern your relationship with Candid.
            </p>
          </Section>

          <Section>
            <H4>26. Questions and Comments</H4>
            <p>
              If you have any questions regarding these Terms or your use of the Services, please
              contact us at: <Highlight> support@trustcandid.com</Highlight>
            </p>
          </Section>
        </div>
      </Container>
    </div>
  );
}
