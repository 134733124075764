import { H2 } from "shared-web-react/dist/widgets/text";
import { LandingFooter } from "../landing/footer";
import { Section } from "../terms/terms";
import { TermsNavButtons } from "../terms/widgets";
import { Capacitor } from "@capacitor/core";

export function Support(): JSX.Element {
  const mid = "";
  const gradients = [
    `radial-gradient(at top left, white,               ${mid} transparent)`,
    `radial-gradient(at top right,   RGB(211,190,228), ${mid} transparent)`,
    `radial-gradient(at bottom left, RGB(211,190,228), ${mid} transparent)`,
    `radial-gradient(at bottom right, white,           ${mid} transparent)`,
  ].join(", ");

  return (
    <div
      className="w-screen p-4 h-screen flex flex-col justify-between items-center"
      style={{
        background: gradients,
      }}
    >
      {!Capacitor.isNativePlatform() && <TermsNavButtons />}

      <Section>
        <div className="p-4 pb pl-0 mt-4">
          <H2>Need Help?</H2>
        </div>
        <p className="text-xl">
          If you have feedback on the app, you can{" "}
          <a href="https://trustcandid.typeform.com/feedbackform">submit that here</a>. App feedback
          goes directly to our engineering team <br />
          <br />
          <br />
          For any other support questions, email us at support@candid.bio. We do our best to respond
          to all support requests within 24 hours.
        </p>
      </Section>
      <LandingFooter />
    </div>
  );
}
