import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { ShowIndicator, ShowIndicatorProps } from "shared/dist/indicators-context";
import { classNames } from "shared/dist/util";

export type IndicatorNavLinkProps = {} & Omit<ShowIndicatorProps, "prefix"> & NavLinkProps;
export function IndicatorNavLink({
  children,
  to,
  baseClassName,
  activeClassName,
  inactiveClassName,
  indicatorItemClassName,
  hideIndicator,
  ...props
}: IndicatorNavLinkProps): JSX.Element {
  return (
    <ShowIndicator
      hideIndicator={hideIndicator}
      prefix={`${to}`}
      {...{
        baseClassName: classNames(baseClassName, "indicator-nav-link"),
        activeClassName,
        inactiveClassName,
        indicatorItemClassName,
      }}
    >
      <NavLink to={to} {...props}>
        {children}
      </NavLink>
    </ShowIndicator>
  );
}
