import { BioEditor } from "../../../widgets/bio/bio-editor";
import { BirthdayPicker } from "../../../widgets/pickers/birthday-picker";
import { Container } from "../../../widgets/container";
import { ContentBox } from "../../../widgets/content-box";
import { EmailEditor } from "../../../widgets/pickers/email-editor";
import { EmailVerifier } from "../../../widgets/pickers/email-verifier";
import { PartnerSettings } from "./dating/partner-settings";
import { ScreenNameEditor } from "../../../widgets/pickers/screen-name-editor";
import { UrlSlugForm } from "../../bio/url-slug-form";
import { classNames } from "shared/dist/util";

// screen name > url > email > phone > DOB ID Verification
export function PersonalSettings(): JSX.Element {
  return (
    <Container size="xs" className={classNames("flex flex-col h-full gap-2 pb-4 items-stretch")}>
      <ContentBox className="px-3 pb-1">
        <PartnerSettings />
      </ContentBox>
      <ContentBox className="px-3 pb-1">
        <ScreenNameEditor labelOverride="Your Candid Display Name:" />
        <UrlSlugForm labelOverride="Your unique Candid Handle:" />
        <EmailEditor labelOverride="Your email address:" />
        <EmailVerifier
          labelOverride="Verify your email address:"
          onSave={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
        <BirthdayPicker />
        <BioEditor labelOverride="What else should people know about you?" onSave={() => {}} />
      </ContentBox>
    </Container>
  );
}
