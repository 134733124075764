import {
  GqlOps,
  useNewMessage300Mutation,
  useNewMessageWithMedia300Mutation,
  useThreadDetailsQuery,
} from "shared/dist/__generated__/components";
import React, { FormEvent } from "react";
import { classNames, filterNullsDefaultWithEmpty } from "shared/dist/util";
import { faImage, faSend } from "@fortawesome/pro-solid-svg-icons";

import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImageUploader } from "../../widgets/images";
import { SpinnerButton } from "shared-web-react/dist/widgets/spinner-button";
import clsx from "clsx";
import { useMyId } from "shared/dist/auth-data";
import { useStringLimit } from "shared-web-react/dist/util/strings";

export type ThreadInputProps = {
  threadId: string;
  setLoadingMessage: (msg: null | string) => void;
};

export function ThreadInput({ threadId, setLoadingMessage }: ThreadInputProps): JSX.Element {
  const [newMessageMutation, newMessageResults] = useNewMessage300Mutation();
  const [newMediaMessageMutation, newMediaMessageResults] = useNewMessageWithMedia300Mutation();
  const [message, setMessage] = React.useState("");
  const [mediaUploadLoading, setMediaUploadLoading] = React.useState(false);
  const [selectionStart, setSelectionStart] = React.useState(0);
  const [nextSelectionStart, setNextSelectionStart] = React.useState<null | number>(null);
  const ref = React.createRef<HTMLInputElement>();
  const myId = useMyId();
  // For user Lookups
  const { data, loading, refetch } = useThreadDetailsQuery({
    variables: { thread_id: threadId, my_id: myId! },
    fetchPolicy: "cache-first",
  });
  const members: Array<[string, string]> = filterNullsDefaultWithEmpty(
    data?.threads_by_pk?.members.map((m) =>
      m.user_public?.screen_name && m.user_public?.slug
        ? [m.user_public.screen_name, m.user_public.slug]
        : null
    )
  );
  const [candidateMentions, setCandidateMentions] = React.useState<null | typeof members>(null);
  const setMessageWrapper = useStringLimit(2000, setMessage);
  const anyLoading =
    newMessageResults.loading || newMediaMessageResults.loading || mediaUploadLoading;
  const sendMediaMessage = React.useCallback(
    async (mediaUploadIds: Array<string>) => {
      if (anyLoading) {
        return;
      }
      setLoadingMessage(message);
      const results = await newMediaMessageMutation({
        variables: {
          content: message,
          thread_id: threadId,
          media_uploads: mediaUploadIds.map((m) => ({ media_upload_id: m })),
        },
        refetchQueries: [
          GqlOps.Query.ThreadMessages,
          GqlOps.Query.ThreadMessages340,
          GqlOps.Query.UserThreads,
          GqlOps.Query.UserThreads412,
        ],
      });
      setMessage("");
      setLoadingMessage(null);
    },
    [message, setLoadingMessage, setMessage, newMediaMessageMutation]
  );
  const sendMessage = React.useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (message.length < 1) {
        return;
      }
      if (anyLoading) {
        return;
      }
      setLoadingMessage(message);
      const results = await newMessageMutation({
        variables: { content: message, thread_id: threadId },
        refetchQueries: [
          GqlOps.Query.ThreadMessages,
          GqlOps.Query.ThreadMessages340,
          GqlOps.Query.UserThreads,
          GqlOps.Query.UserThreads412,
        ],
      });
      setMessage("");
      setLoadingMessage(null);
      ref?.current?.focus?.();
    },
    [message, setLoadingMessage, setMessage, newMessageMutation, anyLoading]
  );
  React.useEffect(() => {
    // Check if the current message, up to the selection point, contains a mention and if so, add selected text with the mention's slug
    const start = ref.current?.selectionStart ?? 0;
    const end = ref.current?.selectionEnd ?? 0;
    const messageUpToCursor = message.slice(0, start);
    const lastMessageTokenBeforeCursor = messageUpToCursor.split(/\s/).splice(-1, 1)?.[0];
    const mentionRegex = /@([-a-zA-Z0-9_]*)/;
    const prefix = lastMessageTokenBeforeCursor?.match(mentionRegex)?.[1];
    if (
      start !== end ||
      messageUpToCursor.endsWith(" ") ||
      !lastMessageTokenBeforeCursor ||
      !prefix
    ) {
      setCandidateMentions(null);
      return;
    }
    setCandidateMentions(
      members
        .filter(
          ([name, slug]) =>
            name.toLowerCase().startsWith(prefix) || slug.toLowerCase().startsWith(prefix)
        )
        .slice(0, 3)
    );
    // regex to see messageUpToCursor ends with an @mention
  }, [message, selectionStart]);
  const addMention = React.useCallback(
    (slug: string) => {
      const start = ref.current?.selectionStart ?? 0;
      const end = ref.current?.selectionEnd ?? 0;
      console.log("🚀 ~ file: thread-view.tsx:123 ~ slug:", slug);
      const messageUpToCursor = message.slice(0, start);
      const lastMessageTokenBeforeCursor = messageUpToCursor.split(/\s/).splice(-1, 1)?.[0];
      const mentionRegex = /@([-a-zA-Z0-9_]*)/;
      const prefix = lastMessageTokenBeforeCursor?.match(mentionRegex)?.[1];
      console.log("🚀 ~ file: thread-view.tsx:129 ~ prefix:", prefix);
      if (
        start !== end ||
        messageUpToCursor.endsWith(" ") ||
        !lastMessageTokenBeforeCursor ||
        !prefix
      ) {
        return;
      }
      const newMessage =
        message.slice(0, start - prefix.length) + slug + " " + message.slice(start);
      const newSelectionLoc = start - prefix.length + slug.length + 1;
      console.log("🚀 ~ file: thread-view.tsx:153 ~ newSelectionLoc:", newSelectionLoc);
      setMessage(newMessage);
      console.log("🚀 ~ file: thread-view.tsx:149 ~ newMessage:", newMessage);
      setCandidateMentions(null);
      ref.current?.focus();
      setNextSelectionStart(newSelectionLoc);
    },
    [ref, message, setMessage]
  );
  React.useEffect(() => {
    if (ref.current && nextSelectionStart !== null) {
      ref.current.selectionStart = nextSelectionStart;
      ref.current.selectionEnd = nextSelectionStart;
      setNextSelectionStart(null);
    }
  }, [ref, setNextSelectionStart, nextSelectionStart, message]);
  return (
    <div
      className={clsx("flex flex-row items-cemter gap-2 items-center justify-stretch p-2 w-full")}
    >
      <ImageUploader
        iconOverride={faImage}
        btnContent={""}
        btnClassName={classNames("btn btn-sm btn-primary rounded-full btn-circle flex-0")}
        onError={() => {
          console.error("Erorr in uploadinng");
        }}
        onComplete={(mediaUploadId) => {
          sendMediaMessage([mediaUploadId]);
          setMediaUploadLoading(false);
        }}
        onStart={() => setMediaUploadLoading(true)}
        // onDismiss={() => setLoading(false)}
        // onUploadIdReceived={setMediaUploadId}
        mediaOptions={{
          exclude_from_bio: true,
          exclude_from_feed: true,
        }}
        showPreviewConfirmation
      />
      <form
        className={classNames(
          "w-full space-x-2 flex-1 flex items-start justify-start",
          candidateMentions && "dropdown dropdown-top dropdown-open"
        )}
        action="#"
        onSubmit={sendMessage}
      >
        <input
          ref={ref}
          className={classNames(
            "input input-sm input-bordered focus:outline-none rounded-full input-full flex-1"
          )}
          onSelect={(event) => {
            setSelectionStart(ref?.current?.selectionStart ?? 0);
          }}
          onChange={(event) => {
            setMessageWrapper(event.target.value);
          }}
          value={message}
          type="text"
        />
        {candidateMentions && candidateMentions.length > 0 && (
          <ul className="dropdown-content bg-primary-content menu rounded-box my-2 cursor-pointer px-4 py-2">
            {candidateMentions.map(([name, slug], index) => (
              <React.Fragment key={slug}>
                <li
                  onClick={() => {
                    console.log("🚀 ~ file: thread-view.tsx:195 ~ slug:", slug);
                    addMention(slug);
                  }}
                  className="flex flex-row flex-nowrap items-center"
                >
                  <Avatar
                    slug={slug}
                    className="inline-block mr-1 my-2 mask mask-circle"
                    tailwindSize="6"
                  />
                  {name} / @{slug}
                </li>
              </React.Fragment>
            ))}
          </ul>
        )}
      </form>
      <SpinnerButton
        disabled={newMessageResults.loading || message.length < 1}
        loading={newMessageResults.loading}
        onClick={sendMessage}
        className={classNames("btn btn-sm btn-primary rounded-full btn-circle  flex-0")}
      >
        <FontAwesomeIcon icon={faSend} fixedWidth />
      </SpinnerButton>
    </div>
  );
}
