import { useAuthIsLoading, useMyId } from "shared/dist/auth-data";
import { TermsText } from "./terms";
import { LandingFooter } from "../landing/footer";
import { TermsNavButtons } from "./widgets";

export function Terms(): JSX.Element {
  return (
    <div className="w-screen bg-red-500">
      {/* <LandingHero /> */}
      {/* <LandingLoginButton />
      <LandingBuilding />
    <LandingMailchimp /> */}
      <TermsText />
      <LandingFooter />
    </div>
  );
}
