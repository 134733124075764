import { AddReactionBlack, ReactionPills, useAllReactions } from "./reactions";
import {
  L_Media_Metadata,
  useBioV2FromSlugSingleMediaItem451Query,
} from "shared/dist/__generated__/components";
import { P, match } from "ts-pattern";
import { ProfileList, profileListItemFromSummary } from "./profile-list";
import { allRoutes, useMkImgUrl } from "../util/routes";

import { AvatarWithScreenName } from "shared-web-react/dist/widgets/avatar";
import { Container } from "./container";
import { DateTime } from "luxon";
import { EllipsisActions } from "./ellipsis-actions";
import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import { Link } from "react-router-dom";
import React from "react";
import { RelativeDate } from "shared-web-react/dist/widgets/dates";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { ZoomableImage } from "shared-web-react/dist/widgets/zoomable-image";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { useBioV2ScreenName } from "../screens/user-page/bio-context";
import { useMyInfo } from "../screens/bio/bio-settings";
import { useSetPageTitle } from "shared-web-react/dist/widgets/nav-stack-view";
import { useTypedParams } from "react-router-typesafe-routes/dom";

export function ReactionsView(): JSX.Element {
  const { slug, media_upload_id } = useTypedParams(allRoutes.PROFILE.MEDIA_ITEM);
  const { data: screenNameData, loading: bioLoading } = useBioV2ScreenName(slug);
  const { data: imgData, loading: imgLoading } = useBioV2FromSlugSingleMediaItem451Query({
    variables: { media_upload_id: media_upload_id!, slug: slug! },
    skip: !media_upload_id || !slug,
    fetchPolicy: "cache-first",
  });
  const {
    thumb_hash,
    width: defaultWidth,
    height: defaultHeight,
  } = match(imgData?.l_bio_v2)
    .returnType<Partial<L_Media_Metadata>>()
    .with({ __typename: "L_Bio_V2_Success" }, (d) => d.single_media_item?.metadata ?? {})
    .otherwise(() => ({}));
  const mkImgUrl = useMkImgUrl();
  const imgUrl = media_upload_id ? mkImgUrl(media_upload_id, "1280") : null;
  const imgCreatedAt = match(imgData?.l_bio_v2)
    .with({ single_media_item: { created_at: P.select() } }, (d) => DateTime.fromISO(d))
    .otherwise(() => undefined);
  if (!media_upload_id) {
    console.error("Missing media_upload_id");
    return <ErrorComp caption={"Missing media_upload_id"} />;
  }
  useSetPageTitle(screenNameData?.screen_name ?? "");

  const { reactions: allReactions } = useAllReactions(media_upload_id);
  const { slug: mySlug } = useMyInfo();
  const profiles = React.useMemo(
    () =>
      allReactions
        ? Object.keys(allReactions)
            ?.map((r) => {
              return {
                ...profileListItemFromSummary({
                  ...allReactions[r]?.owner_summary,
                }),
                caption: allReactions[r]?.reaction,
              };
            })
            .map((s) => ({ ...s, screenName: s.screenName ?? "" })) ?? null
        : [],
    [allReactions]
  );

  if (!screenNameData) {
    if (bioLoading) return <SpinnerCentered />;
    return <ErrorComp caption={`404: We're sorry, but can't find @${slug}'s profile...`} />;
  }

  if (!slug) {
    console.error("🚀 - file: reactions-view.tsx:58 - ReactionsView - missing slug:");
    console.error("Missing slug");
    return <ErrorComp caption={"Missing Bio"} />;
  }

  return (
    <div className={`z-30-main-ui-overlay-2`}>
      <Container size="sm">
        {imgUrl && (
          <>
            <div className={`p-2 relative`}>
              <div className="">
                <div className="flex-col mt-4 relative">
                  <div
                    key={screenNameData.screen_name}
                    className="flex justify-between mx-5 pb-2 max-w-full whitespace-nowrap text-ellipsis overflow-x-hidden"
                  >
                    <Link
                      to={allRoutes.PROFILE.buildPath({ slug })}
                      className="flex flex-row items-center gap-2"
                    >
                      <AvatarWithScreenName
                        tailwindSize="8"
                        slug={slug}
                        screenName={screenNameData.screen_name}
                      />
                    </Link>
                    {mySlug !== slug && (
                      <EllipsisActions
                        slug={slug}
                        reportData={{ reportType: "image", reportItemId: media_upload_id }}
                        className="black"
                      />
                    )}
                  </div>
                </div>
                <div className={clsx("-mx-2")}>
                  <ZoomableImage
                    src={imgUrl}
                    defaultHeight={defaultHeight}
                    defaultWidth={defaultWidth}
                    thumbHash={thumb_hash ?? undefined} // ignored for now
                  />
                </div>
              </div>
              {imgCreatedAt && (
                <p className="opacity-75 text-sm px-5">
                  <RelativeDate dt={imgCreatedAt} />
                </p>
              )}
            </div>
            <hr className="mb-4 border-t-1 border-[secondary]" />
          </>
        )}

        <div
          className={classNames(
            "GalleryImage-ReactionsBar ",
            "mx-5 bottom-0 left-0 right-0 flex justify-start gap-2 items-center max-w-full px-2 py-1"
          )}
        >
          <AddReactionBlack
            objectId={media_upload_id}
            buttonClassName="!text-primary drop-shadow-lg"
          />
          <ReactionPills objectId={media_upload_id} />
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          {!profiles ? (
            <SpinnerCentered />
          ) : profiles?.length > 0 ? (
            <ProfileList
              columnCountOverride={1}
              className="py-4 px-6 z-50 h-full"
              mkRoute={({ screenName, slug }, idx) => ({
                state: { title: screenName, slug },
                to: allRoutes.PROFILE.buildPath({ slug: slug ?? "" }),
              })}
              profiles={profiles}
            />
          ) : (
            <div className="text-primary py-2 text-lg font-semibold text-center">
              Be the first to react!
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}

// const useGesture = createUseGesture([pinchAction]);

// // cSpell:disable-next-line
// function PinchableImage({ imgUrl }: { imgUrl: string }): JSX.Element {
//   React.useEffect(() => {
//     const handler = (e: Event) => e.preventDefault();
//     document.addEventListener("gesturestart", handler);
//     document.addEventListener("gesturechange", handler);
//     document.addEventListener("gestureend", handler);
//     // document.addEventListener("touchend", handler);
//     // document.addEventListener("touchcancel", handler);
//     return () => {
//       document.removeEventListener("gesturestart", handler);
//       document.removeEventListener("gesturechange", handler);
//       document.removeEventListener("gestureend", handler);
//       // document.removeEventListener("touchend", handler);
//       // document.removeEventListener("touchcancel", handler);
//     };
//   }, []);

//   const [style, api] = useSpring(() => ({
//     x: 0,
//     y: 0,
//     scale: 1,
//     immediate: true,
//   }));
//   const imgDivRef = React.useRef<HTMLDivElement>(null);
//   // const { refs: floatingRefs, floatingStyles } = useFloating();

//   // React.useEffect(() => {
//   //   console.log("pinchable ---- render ---- ");
//   // }, []);

//   const placementRef = React.useRef<HTMLDivElement>(null);
//   const imgSizeRef = React.useRef<HTMLImageElement>(null);
//   const [inGesture, setInGesture] = React.useState(false);
//   useGesture(
//     {
//       // onHover: ({ active, event }) => console.log('hover', event, active),
//       // onMove: ({ event }) => console.log('move', event),
//       onPinch: ({
//         origin: [ox, oy],
//         first,
//         last,
//         // movement: [ms],
//         offset: [offsetScale],
//         memo,
//         cancel,
//         event,
//       }) => {
//         const reducedScale = (offsetScale - 1) * 0.5 + 1;
//         console.log(
//           "🚀 - file: reactions-view.tsx:197 - Pinch:",
//           JSON.stringify({
//             x,
//             y,
//             width,
//             height,
//             ox,
//             oy,
//           })
//         );
//         if (first) {
//           const { width, height, x, y } = imgDivRef.current!.getBoundingClientRect();
//           const tx = ox - (x + width / 2);
//           const ty = oy - (y + height / 2);
//           memo = { x: style.x.get(), y: style.y.get(), tx, ty };
//           setInGesture(true);
//         }

//         if (last) {
//           console.log("🚀 - file: reactions-view.tsx:209 - PinchableImage - last:", last, event);
//           setInGesture(false);
//           api.start({ scale: 1, x: 0, y: 0 });
//           return { x: 0, y: 0, tx: 0, ty: 0, scale: 1 };
//         }

//         const x = memo.x - (reducedScale - 1) * memo.tx;
//         const y = memo.y - (reducedScale - 1) * memo.ty;
//         api.start({ scale: reducedScale, x, y });
//         return memo;
//       },
//     },
//     {
//       target: imgDivRef,
//       pinch: { scaleBounds: { min: 1.0, max: 6 }, rubberband: false },
//     }
//   );

//   const [fixedPositionStyle, setFixedPositionStyle] = React.useState<React.CSSProperties>({});
//   const updateOverlayPosition = () => {
//     if (!placementRef.current) return;
//     const rect = placementRef.current.getBoundingClientRect();
//     console.log("🚀 - file: reactions-view.tsx:248 - updateOverlayPosition - rect:", rect);
//     setFixedPositionStyle({
//       top: `${rect.top + window.scrollY}px`,
//       left: `${rect.left}px`,
//       width: `${rect.width}px`,
//       height: `${rect.height}px`,
//       position: "absolute",
//     });
//   };

//   const debouncedUpdateOverlayPosition = useDebouncedCallback(updateOverlayPosition, 100, {
//     leading: true,
//   });
//   React.useEffect(() => {
//     const imgElement = imgSizeRef.current;
//     if (imgElement) imgElement.onload = updateOverlayPosition;

//     // Listen for resize events
//     const resizeObserver = new ResizeObserver(() => {
//       updateOverlayPosition();
//     });

//     if (placementRef.current) resizeObserver.observe(placementRef.current);
//     window.addEventListener("scroll", debouncedUpdateOverlayPosition);

//     return () => {
//       if (imgElement) imgElement.onload = null;
//       window.removeEventListener("scroll", debouncedUpdateOverlayPosition);
//       resizeObserver.disconnect();
//     };
//   }, []); // Empty dependency array means this effect runs once on mount

//   const { log } = useOnScreenLogger();
//   React.useEffect(() => log({ id: "pinch", content: `inGesture: ${inGesture}` }), [inGesture]);
//   return (
//     <figure className={clsx("relative w-full  mx-auto")}>
//       <div
//         onTouchStart={(e) => {
//           console.log("🚀 - file: reactions-view.tsx:296 - PinchableImage - e:", e.touches);
//           setInGesture(e.touches.length > 1);
//         }}
//         onTouchEnd={() => setInGesture(false)}
//         ref={placementRef}
//         className={clsx(inGesture ? "opacity-20 " : "")}
//       >
//         <img ref={imgSizeRef} className="object-contain m-auto " src={imgUrl} alt="Image" />
//       </div>
//       {createPortal(
//         <div
//           className={clsx("w-full", inGesture ? "" : "opacity-0")}
//           style={{ ...fixedPositionStyle, pointerEvents: inGesture ? "auto" : "none" }}
//         >
//           <animated.div className="w-full flex-col-center-center" ref={imgDivRef} style={style}>
//             <div style={{ zIndex: 50 }}>
//               <img className="  object-contain m-auto " src={imgUrl} alt="Image" />
//             </div>
//           </animated.div>
//         </div>,
//         document.body
//       )}
//     </figure>
//   );
// }
