import React from "react";
import { Link } from "react-router-dom";
import { allRoutes } from "../util/routes";
import { useMySlug } from "../screens/bio/bio-settings";
import { classNames } from "shared/dist/util";

export function Mention({ slug }: { slug: string }): JSX.Element {
  const mySlug = useMySlug()?.slug;
  const trimmedSlug = slug.startsWith("@") ? slug.slice(1) : slug;
  const [seen, setSeen] = React.useState(false);
  const isMine = mySlug === trimmedSlug;
  return (
    <Link
      onMouseEnter={() => setSeen(true)}
      className={classNames(
        "Mention font-semibold link text-primary",
        isMine && !seen && "animate-pulse"
      )}
      to={allRoutes.PROFILE.buildPath({ slug: trimmedSlug })}
    >{`@${trimmedSlug}`}</Link>
  );
}
