import React from "react";
import { classNames } from "shared/dist/util";

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const inputBaseClass = classNames(
  "block",
  "w-full",
  "appearance-none",
  "border",
  "border-gray-300",
  "px-3",
  "py-2",
  "placeholder-gray-400",
  "shadow-sm",
  "focus:border-indigo-500",
  "focus:outline-none",
  "focus:ring-indigo-500",
  "sm:text-sm"
);

export const Input = React.forwardRef(
  ({ ...props }: InputProps, ref): JSX.Element => (
    <input
      ref={ref as any}
      className={classNames(inputBaseClass, "rounded-md", props.disabled ? "opacity-50" : null)}
      {...props}
    />
  )
);

export const InputLeftAddon = React.forwardRef(
  ({ children, ...props }: InputProps & { children: React.ReactNode }, ref) => (
    <div className="flex max-w-lg rounded-md shadow-sm">
      <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
        {children}
      </span>
      <input ref={ref as any} {...props} className={classNames(inputBaseClass, "rounded-r-md")} />
    </div>
  )
);
