import {
  SelectWithDetails,
  SelectWithDetailsOption,
} from "shared-web-react/dist/widgets/select-details";

import { ContentBoxInfoLine } from "../../../../widgets/content-box";
import { DiscoveryCommonProps } from "./dating-settings-utilities";
import { H5 } from "shared-web-react/dist/widgets/text";
import { RangeSlider } from "shared-web-react/dist/widgets/range-slider";
import React from "react";
import { classNames } from "shared/dist/util";
import { distanceOptionsKeyedInMeters } from "shared-web-react/dist/util/units";

export function DistanceSettings({ prefs, disabled, onUpdate }: DiscoveryCommonProps): JSX.Element {
  return (
    <React.Fragment>
      <ContentBoxInfoLine>
        <H5>Distance Preferences:</H5>
      </ContentBoxInfoLine>
      Show me people within
      {false && (
        <div className={classNames("py-2")}>
          <RangeSlider min={1} max={100} className="h-8" defaultValue={18} />
        </div>
      )}
      <div>
        <SelectWithDetails
          size="sm"
          disableOnLoading
          disabled={disabled}
          options={distanceOptionsKeyedInMeters}
          selected={
            distanceOptionsKeyedInMeters.find(
              (option) => prefs && option[0] === prefs.max_distance
            )?.[0] ?? distanceOptionsKeyedInMeters[distanceOptionsKeyedInMeters.length - 1]
          }
          setSelected={(value: SelectWithDetailsOption | null) =>
            value?.[0] && onUpdate({ max_distance: Number(value?.[0]) })
          }
        />
      </div>
    </React.Fragment>
  );
}
