import { ModalContainer } from "../../widgets/container";
import clsx from "clsx";
import { match } from "ts-pattern";
import obEventsImage from "../../assets/ob-events.jpg";
import obMatchImage from "../../assets/ob-match.jpg";
import obWelcomeImage from "../../assets/ob-welcome.jpg";

export type OnboardingScreen = {
  onNext?: () => void;
  screenType: "welcome" | "match-network" | "events";
  navIndexNumber?: number;
};

export function ObSplashScreen({
  onNext,
  screenType,
  navIndexNumber,
}: OnboardingScreen): JSX.Element {
  // const isPendingVerificationScreen = screenType === "pending-profile-verification";
  const { imageToDisplay, header, subheader } = match(screenType)
    .with("welcome", () => ({
      imageToDisplay: obWelcomeImage,
      header: "Welcome to Candid! your online ENM community",
      subheader:
        "We verify everyone, so you always connect with real people. Enjoy safe adventures with like-minded members.",
    }))
    .with("match-network", () => ({
      imageToDisplay: obMatchImage,
      header: "Find matches & grow your ENM network",
      subheader:
        "On Candid, you can meet new people and connect with friends you've met before. Building your network makes it easier to discover more opportunities.",
    }))
    .with("events", () => ({
      imageToDisplay: obEventsImage,
      header: "Have more fun with Events",
      subheader:
        "Want to meet more like-minded people in the wild? We create opportunities to connect before & after events popular in the ENM community.",
    }))
    .exhaustive();

  return (
    <ModalContainer
      size="lg"
      className={clsx(
        `!p-0 fixed top-0 left-0 right-0 bottom-0 bg-cover bg-center flex flex-col`,
        ` justify-end items-center text-center z-50`,
        "bg-neutral-content text-primary-content"
      )}
    >
      <div className="h-1/2 flex flex-col justify-center " onClick={onNext}>
        <div className={clsx("absolute top-0 left-0 right-0")}>
          <img className="w-full top-0 " src={imageToDisplay} alt="Profile interaction icons" />
          <div
            className={clsx(
              "absolute inset-0 w-full",
              "bg-gradient-to-b from-transparent via-80%  to-90% to-black z-30-main-ui-overlay-2"
            )}
          />
        </div>
        <div
          className={clsx(
            `w-full h-full max-w-screen-md px-6 flex flex-col justify-between items-center space-y z-30-main-ui-overlay-2`
          )}
        >
          <div
            className={"py-10 h-11/12 justify-around flex flex-col items-center space-y-4 w-full"}
          >
            <h1 className={clsx("mt-5 text-3xl sm:text-5xl md:text-5xl")}>{header}</h1>
            <p className="text-lg sm:text-xl md:text-2xl lg:text-2xl my-4">{subheader}</p>
          </div>
        </div>
        <div className="flex justify-center space-x-2 mb-10">
          {navIndexNumber &&
            Array.from({ length: 3 }, (_, index) => (
              <span
                key={index}
                className={`h-3 ${
                  navIndexNumber - 1 === index
                    ? "bg-purple-500 w-5 rounded-lg"
                    : "bg-gray-300 w-3 rounded-full"
                }`}
              />
            ))}
        </div>
      </div>
    </ModalContainer>
  );
}
