import { Link, useNavigate } from "react-router-dom";
import {
  Onboarding_Statuses_Enum,
  useUserUrlSlug410Query,
} from "shared/dist/__generated__/components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H1 } from "shared-web-react/dist/widgets/text";
import { ModalContainer } from "../../widgets/container";
import React from "react";
import { allRoutes } from "../../util/routes";
import clsx from "clsx";
import { faArrowLeftFromArc } from "@fortawesome/pro-solid-svg-icons";
import logoInterlock from "../../assets/icon-logos/c-interlock.png";
import { useMyId } from "shared/dist/auth-data";
import { ObMedia } from "./media";

export function ObPendingApproval(): JSX.Element {
  const user_id = useMyId();
  const navigate = useNavigate();
  const { data } = useUserUrlSlug410Query({
    pollInterval: 1000 * 60 * 1,
    fetchPolicy: "network-only",
    skip: !user_id,
    variables: { user_id: user_id ?? "" },
  });
  React.useEffect(() => {
    if (data?.users_by_pk?.onboarding_status === Onboarding_Statuses_Enum.V2_20LiveUser)
      navigate(allRoutes.HOME.buildPath({}));
  }, [data?.users_by_pk, navigate]);
  return (
    <ModalContainer
      size="lg"
      className={`!p-0 fixed top-0 left-0 right-0 bottom-0 bg-cover bg-center flex flex-col justify-end items-center text-center z-50  ${"bg-primary-content text-primary justify-between"}`}
    >
      <div className="h-1/2 flex flex-col justify-center">
        <div
          className={clsx(
            `w-full h-full max-w-screen-md px-6 flex flex-col justify-between items-center space-y`
          )}
        >
          <div
            className={clsx(
              "py-10 absolute top-[40%] transform -translate-y-1/2 px-5",
              "h-11/12 justify-around flex flex-col items-center space-y-4 w-full",
              "overflow-x-hidden mb-20"
            )}
          >
            <h1 className={clsx("mt-5 text-3xl sm:text-5xl md:text-5xl", "self-start")}>
              <img className="max-w-20" src={logoInterlock} alt="Profile interaction icons" />
            </h1>
            <div className="text-lg sm:text-xl md:text-2xl lg:text-2xl my-4">
              <div className="flex flex-col justify-center">
                <div>
                  <H1 className="text-left leading-10 my-2">We're taking a look at your profile</H1>
                  <div className="text-md md:text-xl lg:text-2xl mb-4 text-left">
                    Thanks for completing your profile! Our team reviews profiles daily to ensure
                    they align with our community guidelines.
                    <br />
                    <br />
                    You'll receive an update from us shortly.
                  </div>
                  <div className="mx-auto my-2 w-3/5 text-xs font-bold text-center text-gray-800">
                    Current Status: Under Review
                  </div>
                </div>
              </div>
            </div>
            <ObMedia isReviewScreen={true} setDisableButton={() => {}} />
          </div>
        </div>
      </div>

      <>
        <Link
          className="z-10 mt-auto w-full px-6"
          to={allRoutes.INVITATION_CODE_ENTRY.buildPath({})}
        >
          <button className="h-16 text-candid-purple-000 w-full py-2 rounded-full border-2 border-purple-500 shadow-md hover:bg-purple-300 hover:border-purple-600 transition duration-150 ease-in-out">
            I have an Invite Code
          </button>
        </Link>
      </>
      <div
        className={clsx(
          "flex-0 flex-row justify-self-end justify-end items-center w-full border-t flex px-4 py-2 bg-gray-300 mt-8"
        )}
      >
        <FontAwesomeIcon icon={faArrowLeftFromArc} style={{ cursor: "pointer" }} className="px-2" />
        <Link className={clsx("link ")} to={allRoutes.logout.buildPath({})}>
          Logout
        </Link>
      </div>
    </ModalContainer>
  );
}
