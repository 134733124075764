import { Capacitor, registerPlugin } from "@capacitor/core";

interface SecureStoragePlugin {
  set(options: { key: string; value: string }): Promise<void>;
  get(options: { key: string }): Promise<{ value: null | string }>;
  clear(options: { key: string }): Promise<void>;
}

const Storage = registerPlugin<SecureStoragePlugin>("SecureStorage");
const isNativePlatform = Capacitor.isNativePlatform();

export const secureStorageSet = async (key: string, value: string) => {
  if (!isNativePlatform) {
    throw new Error("storage.set() is only available on native platforms");
  }
  return Storage.set({ key, value });
};
export const secureStorageGet = async (key: string) => {
  if (!isNativePlatform) {
    throw new Error("storage.set() is only available on native platforms");
  }
  const { value } = await Storage.get({ key }).catch((e) => ({ value: null }));
  return value;
};

export const secureStorageClear = async (key: string) => {
  if (!isNativePlatform) {
    throw new Error("storage.set() is only available on native platforms");
  }
  return await Storage.clear({ key });
};
