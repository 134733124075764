import { ContentBox, ContentBoxInfoLine } from "../../widgets/content-box";
import {
  EventDetailsQuery,
  GqlOps,
  useEnterEventCodeMutation,
  useEventDetailsBySlug340Query,
  useEventSummaryByIdQuery,
  useEventSummaryBySlugQuery,
  useJoinPublicEvent440Mutation,
  useLeavePublicEventMutation,
} from "shared/dist/__generated__/components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ProfileList, profileListItemFromSummary } from "../../widgets/profile-list";
import { Spinner, SpinnerCentered, SpinnerFullScreen } from "shared-web-react/dist/widgets/spinner";
import { allRoutes, useMustBeLoggedIn } from "../../util/routes";
import { classNames, filterNulls } from "shared/dist/util";
import { faCheck, faLock } from "@fortawesome/pro-solid-svg-icons";
import { faGear, faMessages, faUpFromBracket } from "@fortawesome/pro-regular-svg-icons";
import { useSetPageTitle } from "shared-web-react/dist/widgets/nav-stack-view";
import { useEnv, useMkUrl } from "shared/dist/util/env";

import { DateTime } from "luxon";
import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputLeftAddon } from "../../widgets/input";
import Linkify from "linkify-react";
import React from "react";
import { SpinnerButton } from "../../widgets/spinner-button";
import { UserPageContainer } from "../user-page/common";
import { VerificationBox } from "../../widgets/verification-box";
import { faMagnifyingGlass } from "@fortawesome/pro-duotone-svg-icons";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { useForm } from "react-hook-form";
import { useIdentityVerifiedStatus } from "../user-page/settings/identity/common";
import { useMyId } from "shared/dist/auth-data";
import { useNavigateToLogin } from "../../util/redirects";
import { useShare } from "../../widgets/share";
import { useTypedParams } from "react-router-typesafe-routes/dom";

type JoinEventDialogProps = {
  onCancel: () => void;
  onJoin: () => void;
  eventSlug?: string;
};
export function JoinEventDialog({
  onCancel,
  eventSlug,
  onJoin,
}: JoinEventDialogProps): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { isValid, dirtyFields, errors, ...state },
  } = useForm<{ code: string }>({ mode: "all" });
  const hasErrors = filterNulls(Object.values(errors)).length > 0;
  useMustBeLoggedIn();
  const [apiError, setApiError] = React.useState<null | string>(null);
  const navigate = useNavigate();
  const [mutate, { loading }] = useEnterEventCodeMutation();
  const onSubmit = React.useCallback(
    async ({ code }: { code: string }) => {
      const trimmed = code?.trim() ?? "";
      if (trimmed.length < 3 || trimmed.length > 100) {
        console.log("Code too short or long");
        return;
      }
      const resp = await mutate({
        variables: { invitation_code: trimmed, event_url_slug: eventSlug },
        refetchQueries: [GqlOps.Query.EventDetailsBySlug340, GqlOps.Query.EventSummaryBySlug],
      });
      if (resp.data?.lm_join_event?.__typename === "L_Join_Event_Error") {
        setApiError(resp?.data?.lm_join_event?.human_readable_error);
        return;
      }
      onJoin();
      const event_id = resp?.data?.lm_join_event?.event_id;
      if (event_id) {
        navigate(allRoutes.EVENTS.DETAILS.buildPath({ event_id }));
      }
    },
    [mutate, setApiError, navigate]
  );
  return (
    <div>
      <div className="modal cursor-pointer modal-open text-primary !my-0">
        <div className="modal-box">
          <div className="form-control">
            <label className="label">
              <span className="label-text">Enter Event Invitation Code</span>
            </label>
            <label className="join">
              <input
                type="text"
                placeholder="aqswde"
                className="join-item input input-bordered focus:outline-none"
                {...register("code", {
                  required: true,
                  pattern: {
                    value: /[a-zA-Z0-9]*/i,
                    message: "Please enter only alphanumeric characters",
                  },
                  maxLength: {
                    message: "The code isnt that long",
                    value: 100,
                  },
                  minLength: {
                    message: "Please enter at least 3 characters",
                    value: 3,
                  },
                })}
              />
              <SpinnerButton
                className="join-item btn btn-primary"
                disabled={hasErrors || loading}
                loading={loading}
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </SpinnerButton>
              <button className="join-item btn btn-accent" onClick={() => onCancel()}>
                cancel
              </button>
            </label>
            <label className="label px-0 ">
              <span className="label-text-alt text-error">
                {apiError ?? errors.code?.message ?? ""}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

// function EventInfoBoxLine({
//   topic,
//   hideTopDivider,
//   children,
// }: React.PropsWithChildren<{
//   hideTopDivider?: boolean;
//   topic: string;
// }>): JSX.Element {
//   return (
//     <>
//       {!hideTopDivider && <div className=" my-0 divider" />}
//       <div className="w-full max-md:text-xs md:text-sm flex flex-row flex-nowrap justify-between ">
//         <dt className="flex-0 min-w-fit whitespace-nowrap text-right text-ellipsis overflow-hidden">
//           {topic}
//         </dt>
//         <dd className="flex-0 whitespace-nowrap text-right text-ellipsis overflow-hidden">
//           {children}
//         </dd>
//       </div>
//     </>
//   );
// }

type EventDataRoot = NonNullable<EventDetailsQuery["events_by_pk"]>;
type EventDataPublicFields =
  | "id"
  | "start"
  | "name"
  | "location_description"
  | "duration"
  | "url_slug";
type EventDataPublic = Pick<EventDataRoot, EventDataPublicFields>;
type EventDataPrivate = Omit<EventDataRoot, "__typename" | EventDataPublicFields>;
type EventData = EventDataPublic & Partial<EventDataPrivate>;
type EventDataProp = {
  eventData: EventData;
};

function isAttending(eventData: EventData): boolean {
  return !!eventData.newest_attendees;
}

function EventAttendingToggle({ eventData }: EventDataProp): JSX.Element {
  const { loading: verifiedLoading, verified } = useIdentityVerifiedStatus();
  const [leaveMutation, leaveMutationResults] = useLeavePublicEventMutation();
  const [joinMutation, joinMutationResults] = useJoinPublicEvent440Mutation();
  const [showPasscodeModal, setShowPasscodeModal] = React.useState(false);
  const loading = leaveMutationResults.loading || joinMutationResults.loading;
  const my_id = useMyId();
  const navigate = useNavigate();
  const navigateToLogin = useNavigateToLogin();
  const confirm = useConfirm();
  const verifiedConfirm = React.useCallback(async () => {
    if (verifiedLoading || verified) return;
    confirm({
      title: "Verify your profile",
      content:
        "To maintain trust and safety, all users of Candid need to verify their profile with government ID before joining public events",
      okButtonContent: "Verify ID",
      onOk() {
        navigate(allRoutes.SETTINGS.PRIVACY.buildPath({}));
      },
    });
  }, [verifiedLoading, verified, confirm]);
  const toggleAttending = React.useCallback(
    async (newState: boolean) => {
      if (verifiedLoading) return;
      if (!verified) return verifiedConfirm();
      if (!my_id) {
        console.error("missing ID in EventAttendingToggle");
        return;
      }
      if (newState) {
        joinMutation({
          variables: { event_id: eventData.id, user_id: my_id },
          refetchQueries: [
            GqlOps.Query.EventDetailsBySlug340,
            GqlOps.Query.EventSummaryBySlug,
            GqlOps.Query.EventDetails,
          ],
        });
      } else {
        confirm({
          title: "Leave this event?",
          okButtonContent: "Leave Event",
          onOk: async () => {
            return leaveMutation({
              variables: { my_id, event_id: eventData.id },
              refetchQueries: [
                GqlOps.Query.EventDetailsBySlug340,
                GqlOps.Query.EventSummaryBySlug,
                GqlOps.Query.EventDetails,
              ],
            });
          },
        });
      }
    },
    [
      joinMutation,
      leaveMutation,
      eventData.id,
      my_id,
      confirm,
      verified,
      verifiedLoading,
      verifiedConfirm,
    ]
  );
  if (loading) {
    return <Spinner />;
  }
  return isAttending(eventData) ? (
    <a className=" btn  btn-sm font-semibold" onClick={() => toggleAttending(false)}>
      <FontAwesomeIcon icon={faCheck} />
      Attending
    </a>
  ) : (
    <>
      <a
        className="btn btn-sm btn-primary font-semibold"
        onClick={() =>
          my_id
            ? eventData.is_public
              ? toggleAttending(true)
              : setShowPasscodeModal(true)
            : navigateToLogin()
        }
      >
        {eventData.is_public ? (
          my_id ? (
            "Join Event!"
          ) : (
            "Login / Register to Join"
          )
        ) : (
          <span>
            <FontAwesomeIcon icon={faLock} className="mr-1" />
            {my_id ? "Join with Passcode" : "Login / Register to Join"}
          </span>
        )}
      </a>
      {showPasscodeModal && (
        <JoinEventDialog
          eventSlug={eventData.url_slug}
          onCancel={() => setShowPasscodeModal(false)}
          onJoin={() => setShowPasscodeModal(false)}
        />
      )}
    </>
  );
}

function EventDescription({ eventData }: EventDataProp): JSX.Element {
  if (!eventData.description || eventData.description?.trim().length === 0) {
    return <></>;
  }
  const [expanded, setExpanded] = React.useState(false);
  return (
    <ContentBox>
      <div className="p-1 flex flex-col justify-between">
        <div className="flex flex-row justify-between">
          <p className="flex-0 font-bold md:text-lg max-md:text-md">Event Details:</p>
          <a className="cursor-pointer" onClick={() => setExpanded(!expanded)}>
            {expanded ? "Show Less" : "Show More"}
          </a>
        </div>
        <div className="divider my-0" />
        <div className={classNames(expanded ? "" : "max-h-36 space-y-1 overflow-y-auto")}>
          {eventData.description.split("\n").map((line, i) => (
            <Linkify options={{ target: "_blank", className: "link font-semibold" }} as="p" key={i}>
              {line}
              {"\u00A0"}
            </Linkify>
          ))}
        </div>
      </div>
    </ContentBox>
  );
}

function EventInfoBox({ eventData }: EventDataProp): JSX.Element {
  const my_id = useMyId();
  const count = eventData.attendee_count?.attendee_count ?? 0;
  const baseUri = useEnv("REACT_APP_BASE_URI").replace(/\/$/, "");
  return (
    <ContentBox>
      <div className="p-1 flex flex-col justify-between">
        <div className="flex flex-row justify-between ">
          <p className="flex-0 items-center font-bold overflow-hidden pr-2 md:text-xl max-md:text-lg whitespace-nowrap text-ellipsis">
            {eventData.name}
          </p>
          {my_id === eventData.owner_id ? (
            <p>owner</p>
          ) : (
            <EventAttendingToggle {...{ eventData }} />
          )}
        </div>
        <dl>
          <ContentBoxInfoLine>
            Date & Time
            {DateTime.fromISO(eventData.start).toLocaleString(DateTime.DATETIME_MED)}
          </ContentBoxInfoLine>
          {/* Only show this for the anonymous view */}
          {!isAttending(eventData) && count > 0 && (
            <ContentBoxInfoLine>{`${count} ${
              count > 1 ? "Members" : "Member"
            } attending`}</ContentBoxInfoLine>
          )}
          {eventData.location_description && (
            <ContentBoxInfoLine>
              General Location
              {eventData.location_description}
            </ContentBoxInfoLine>
          )}
          {eventData.address && (
            <ContentBoxInfoLine>
              Address
              {eventData.address}
            </ContentBoxInfoLine>
          )}
          {eventData.owner_summary?.slug && (
            <ContentBoxInfoLine>
              Hosted By
              <Link
                className="link text-primary whitespace-nowrap text-ellipsis overflow-hidden"
                to={allRoutes.PROFILE.buildPath({
                  slug: eventData.owner_summary.slug,
                })}
              >
                {"@" + eventData.owner_summary.screen_name}
              </Link>
            </ContentBoxInfoLine>
          )}
          {false && my_id === eventData.owner_id && (
            <ContentBoxInfoLine>
              {"Invitation Code"}
              {eventData.invitation_code}
            </ContentBoxInfoLine>
          )}
          {eventData.url_slug && (
            <ContentBoxInfoLine>
              Event Url
              <a
                className="link"
                onClick={() => {
                  navigator.clipboard.writeText(
                    baseUri +
                      allRoutes.EVENT_FROM_SLUG.buildPath({
                        slug: eventData.url_slug!,
                      })
                  );
                  alert("copied");
                }}
              >
                {baseUri +
                  allRoutes.EVENT_FROM_SLUG.buildPath({
                    slug: eventData.url_slug,
                  })}
              </a>
            </ContentBoxInfoLine>
          )}
        </dl>
      </div>
    </ContentBox>
  );
}

// function EventThreads({ eventData }: EventDataProp): JSX.Element {
//   const my_id = useMyId();
//   return (
//     <ContentBox>
//       <div className="p-1 flex flex-col justify-between">
//         <p className="font-bold md:text-lg max-md:text-md">
//           Event Message Threads
//         </p>
//         <div className=" my-0 divider" />
//         <ul>
//           {eventData.threads.map(({ id, title }, idx) => (
//             <React.Fragment key={id}>
//               {idx > 0 && <div className=" my-0 divider" />}
//               <li>
//                 <Link
//                   to={authenticatedRoutes.MESSAGING.THREAD.buildPath({
//                     thread_id: id,
//                   })}
//                 >
//                   <FontAwesomeIcon
//                     icon={faMessages}
//                     fixedWidth
//                     className="mr-1"
//                   />
//                   {title ?? `Thread ${idx + 1}`}
//                 </Link>
//               </li>
//             </React.Fragment>
//           ))}
//         </ul>
//       </div>
//     </ContentBox>
//   );
// }

export function EventDetails(): JSX.Element {
  // const my_id = useMyId();
  const { event_id } = useParams();
  const { data, loading, error } = useEventSummaryByIdQuery({
    variables: { event_id: event_id ?? "" },
  });
  const navigate = useNavigate();
  React.useEffect(() => {
    const slug = data?.events_by_pk?.url_slug;
    if (slug) {
      navigate(allRoutes.EVENT_FROM_SLUG.buildPath({ slug }));
    }
  }, [navigate, data]);
  React.useEffect(() => {
    if (!loading && !event_id) {
      navigate(allRoutes.HOME.buildPath({}));
    }
  }, [navigate, loading, event_id]);
  if (loading) {
    return <SpinnerFullScreen />;
  }
  if (error) {
    return <ErrorComp caption="error loading event" />;
  }
  return <></>;
  // useMustBeLoggedIn();
  // React.useEffect(() => {
  //   if (!event_id) {
  //     navigate(authenticatedRoutes.me.buildPath({}));
  //   } else {
  //     query({ variables: { event_id } });
  //   }
  // }, [event_id, navigate, query]);
  // const eventData = data?.events_by_pk;
  // if (loading) {
  //   return <SpinnerCentered />;
  // }
  // if (!event_id) {
  //   navigate(authenticatedRoutes.me.buildPath({}));
  //   return <></>;
  // }
  // if (!eventData) {
  //   return <ErrorComp caption={"Could not load event data"} />;
  // }
  // return <EventLayout eventData={eventData} />;
}

export function EventDetailsFromSlug(): JSX.Element {
  const my_id = useMyId();
  const { slug } = useTypedParams(allRoutes.EVENT_FROM_SLUG);
  // Include the details and summary query here so that we get a summary of
  // events for non-logged in users
  const { data, loading, error } = useEventDetailsBySlug340Query({
    skip: !slug || !my_id,
    variables: { url_slug: slug! },
  });

  const summaryQueryResults = useEventSummaryBySlugQuery({
    variables: { slug: slug ?? "" },
    skip: !slug,
  });
  const navigate = useNavigate();
  const eventData =
    data?.events?.[0] ??
    data?.event_public_summaries?.[0] ??
    summaryQueryResults?.data?.event_public_summaries?.[0];
  useSetPageTitle(loading ? "Event Loading" : eventData?.name ?? "Event");

  if (loading || summaryQueryResults.loading) {
    return <SpinnerCentered />;
  }
  if (!slug) {
    navigate(allRoutes.me.buildPath({}));
    return <></>;
  }
  if (!eventData) {
    return <ErrorComp caption={"Could not load event data"} />;
  }
  // Annoying little hack to make TS happy
  const { id, name, start, duration, url_slug } = eventData;
  if (!id || !name || !start || !duration || !url_slug) {
    return <ErrorComp caption={"Could not load event data"} />;
  }

  const eventDataChecked: EventData = {
    ...eventData,
    id,
    name,
    start,
    url_slug,
    duration,
    is_public: !!eventData.is_public,
  };
  return <EventLayout eventData={eventDataChecked} />;
}

function EventLayout({ eventData }: EventDataProp): JSX.Element {
  const event_id = eventData.id;
  const my_id = useMyId();
  const baseUri = useEnv("REACT_APP_BASE_URI");
  const eventImgUrl =
    baseUri.replace(/\/$/, "") +
    allRoutes.EVENT_FROM_SLUG.COVER_IMAGE.buildPath({
      slug: eventData.url_slug,
    }) +
    `?mid=${eventData.media_upload_id}}`;
  const [userFilter, setUserFilter] = React.useState("");
  const filteredAttendees = React.useMemo(() => {
    if (!eventData.newest_attendees) {
      return null;
    }
    const trimmedFilter = userFilter.trim().toLowerCase();
    if (trimmedFilter.length === 0) {
      return filterNulls(
        eventData.newest_attendees.map((a) => profileListItemFromSummary(a.user_summary))
      );
    }
    return filterNulls(
      eventData.newest_attendees
        .filter(
          (a) =>
            a.user_summary?.slug?.toLowerCase?.()?.match?.(trimmedFilter) ||
            a.user_summary?.screen_name?.toLowerCase()?.match?.(trimmedFilter)
        )
        .map((a) => profileListItemFromSummary(a.user_summary))
    );
  }, [userFilter, eventData]);
  const navigate = useNavigate();
  const totalAttendees = eventData.attendee_count?.attendee_count ?? 0;
  const thread_id = eventData.threads?.[0]?.id;
  const share = useShare();
  const { verified } = useIdentityVerifiedStatus();

  const eventUrl = useMkUrl()(
    allRoutes.EVENT_FROM_SLUG.buildPath({
      slug: eventData.url_slug,
    })
  );
  return (
    <UserPageContainer className="space-y-3 pb-32 ">
      <VerificationBox
        heading="✨ Verify for Full Event Features! ✨"
        text="Tap here to verify your identity and gain access to the attendee list and event chat. Verification is quick and opens up all the features!"
      />
      {eventImgUrl && (
        <ContentBox>
          <div
            className="w-full aspect-video bg-no-repeat bg-cover bg-center"
            style={{ backgroundImage: `url(${eventImgUrl})` }}
          />
        </ContentBox>
      )}
      <EventInfoBox eventData={eventData} />
      <EventDescription eventData={eventData} />

      <div className="p-1 lg:p-2 flex flex-row justify-center gap-2 max-md:text-sm whitespace-nowrap">
        <button
          className="flex-1 btn lg:btn-lg btn-primary"
          onClick={() => share({ url: eventUrl, title: eventData.name, fallbackText: eventUrl })}
        >
          <FontAwesomeIcon icon={faUpFromBracket} fixedWidth className=" text-lg mr-2" />
          <span className="max-lg:hidden">Share Event</span>
          <span className="lg:hidden">Share</span>
        </button>
        <button
          className="flex-1 btn lg:btn-lg btn-neutral"
          disabled={!verified || !thread_id}
          onClick={() =>
            thread_id && navigate(allRoutes.MESSAGING.LIST.THREAD.buildPath({ thread_id }))
          }
        >
          <FontAwesomeIcon icon={faMessages} fixedWidth className=" text-lg mr-2" />
          <span className="max-lg:hidden">Chat with attendees </span>
          <span className="lg:hidden">Chat</span>
        </button>
        {event_id && my_id === eventData?.owner_id && (
          <Link
            className="flex-1 btn lg:btn-lg btn-warning"
            to={allRoutes.EVENTS.DETAILS.EDIT.buildPath({ event_id })}
          >
            <FontAwesomeIcon icon={faGear} fixedWidth className="text-lg mr-2" />
            <span className="hidden lg:inline">Edit</span> {/* Adjusted classes for visibility */}
            <span className="lg:hidden">Edit</span>
          </Link>
        )}
      </div>
      {filteredAttendees && (
        <ContentBox className="">
          <div className="p-1">
            <p className="font-bold md:text-lg max-md:text-md">
              Attendees
              <span>
                {" "}
                {filteredAttendees.length === totalAttendees
                  ? `(${totalAttendees})`
                  : `(${filteredAttendees.length}/${totalAttendees})`}
              </span>
            </p>
            <div className=" my-0 divider" />
            <div className="my-2">
              <InputLeftAddon value={userFilter} onChange={(e) => setUserFilter(e.target.value)}>
                <FontAwesomeIcon icon={faMagnifyingGlass} fixedWidth />
              </InputLeftAddon>
            </div>
            <ProfileList
              profiles={filteredAttendees}
              className={!verified ? "pointer-events-none select-none blur-sm" : ""}
              mkRoute={(profile) =>
                profile.slug
                  ? {
                      to: allRoutes.PROFILE.buildPath({
                        slug: profile.slug ?? "",
                      }),
                    }
                  : null
              }
            />
          </div>
        </ContentBox>
      )}
    </UserPageContainer>
  );
}
