import { SubmitHandler, useForm } from "react-hook-form";
import {
  useGetMyEmailQuery,
  useResendVerificationMutation,
  useVerifyEmailMutation,
} from "shared/dist/__generated__/components";

import { DevTool } from "@hookform/devtools";
import React from "react";
import { SaveButton } from "../spinner-button";
import { Spinner } from "shared-web-react/dist/widgets/spinner";
import { filterNulls } from "shared/dist/util";
import { useIsDev } from "shared/dist/util/env";
import { useMustBeLoggedIn } from "../../util/routes";
import { useMyId } from "shared/dist/auth-data";

type Inputs = { otp: string };
export function EmailVerifier({
  onSave,
  labelOverride,
}: {
  labelOverride?: string;
  onSave: () => void;
}): JSX.Element {
  const id = useMyId()!;
  const resendEmail = useResendVerificationMutation();
  const getMyEmailQuery = useGetMyEmailQuery({
    variables: { id },
    fetchPolicy: "cache-and-network",
  });
  const { email, email_is_verified } = getMyEmailQuery?.data?.users_by_pk ?? {};
  const { register, control, handleSubmit, watch, getValues, setValue, formState } =
    useForm<Inputs>({
      mode: "all",
    });
  const { isValid, isDirty, dirtyFields, errors, ...state } = formState;
  const [remoteValidationLoading, setRemoteValidationLoading] = React.useState(false);
  const [setVerifyEmailMutation, { loading, error }] = useVerifyEmailMutation();
  const [formValidationErrors, setFormValidationErrors] = React.useState<Partial<Inputs>>({});
  const hasErrors =
    filterNulls(Object.values(formValidationErrors)).length > 0 ||
    filterNulls(Object.values(errors)).length > 0;
  React.useEffect(() => {
    setValue("otp", "");
  }, [email_is_verified, email]);
  const onSubmit: SubmitHandler<Inputs> = React.useCallback(
    async ({ otp }: { otp?: string }) => {
      if (!id || !email || !otp) {
        return;
      }
      if (hasErrors) {
        console.warn("Form validation failed");
        return;
      }
      await setVerifyEmailMutation({ variables: { email, otp } });
      getMyEmailQuery.refetch();
      onSave?.();
    },
    [id, setVerifyEmailMutation, email]
  );
  useMustBeLoggedIn();
  const isDev = useIsDev();
  if (!email) {
    return <></>;
  }
  return (
    <form className="form-control w-full " onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <label className="label px-0 indicator">
          {!email_is_verified && (
            <span className="indicator-item top-2 badge rounded-full bg-red-500 badge-sm max-lg:badge-xs" />
          )}
          <span className="label-text">
            {email_is_verified
              ? "Email Verified!"
              : labelOverride ?? `One time pin sent to ${email}`}
          </span>
          {!email_is_verified && (
            <>
              <a
                onClick={() => resendEmail[0]({ variables: { email } })}
                className="label-text mx-1 italic underline"
              >
                Resend?
              </a>
              {resendEmail[1].loading && <Spinner />}
            </>
          )}
        </label>
      </div>
      {!email_is_verified && (
        <div className="join">
          <input
            type="text"
            placeholder="123456"
            inputMode="numeric"
            disabled={email_is_verified}
            className="join-item input input-bordered w-full focus:outline-none "
            {...register("otp", {
              minLength: 6,
              maxLength: 6,
              pattern: {
                value: /[0-9]{6}/,
                message: "Your OTP should be 6 digits",
              },
              required: true,
            })}
          />

          <SaveButton
            className="join-item btn btn-primary"
            disabled={
              !isDirty ||
              email_is_verified ||
              loading ||
              hasErrors ||
              remoteValidationLoading ||
              getMyEmailQuery.loading ||
              watch("otp").length !== 6
            }
            loading={remoteValidationLoading || loading || getMyEmailQuery.loading}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      )}
      <div>
        <label className="label px-0 ">
          <span className="label-text-alt text-error">
            {formValidationErrors.otp ?? errors.otp?.message ?? ""}
          </span>
          {/* <span className="label-text-alt italic opacity-50">
            {watch("email")?.length ?? "0"} / {maxLength}
          </span> */}
        </label>
      </div>
      {isDev && <DevTool control={control} />}
    </form>
  );
}
