import { allRoutes, useMustBeLoggedIn } from "../../../util/routes";

import { Container } from "../../../widgets/container";
import { EnumSelect } from "shared-web-react/dist/widgets/enum-select";
import { H4 } from "shared-web-react/dist/widgets/text";
import React from "react";
import { classNames } from "shared/dist/util";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { useUserReportFxn } from "../settings/safety";

// TODO move these to a DB table
enum REPORT_ENUMS {
  UNDERAGE = "It contains explicit content involving minors",
  NON_CONSENSUAL = "It includes somone without their consent",
  HARASSMENT = "Its used to harass or bully",
  SPAM = "It is spam or unsolicited advertisement",
  COPYRIGHT = "It has potential copyright infringement",
  PERSONAL_DATA = "It reveals personal data or sensitive information",
  OTHER = "My own reason",
}
export function ReportMedia(): JSX.Element {
  const params = useTypedParams(allRoutes.REPORT_MEDIA);
  const [otherText, setOtherText] = React.useState("");
  const [isChecked, setIsChecked] = React.useState(false);
  const report = useUserReportFxn();
  const [reason, setReason] = React.useState<REPORT_ENUMS | null>(REPORT_ENUMS.OTHER);
  const addToast = useAddToast();
  useMustBeLoggedIn();
  const handleReasonChange = (val: REPORT_ENUMS | null) => setReason(val);
  const handleClick = React.useCallback(
    async (reason: REPORT_ENUMS, otherText: string) => {
      console.log("🚀 ~ file: report-media.tsx:33 ~ reason:", { reason, otherText, params });
      if (!reason && !otherText) {
        addToast({
          content: "Please select a Reason or leave a comment",
          color: "warning",
        });
        return;
      }

      report(params.reportType, reason, otherText);
    },
    [reason, otherText, params, report]
  );

  return (
    <Container
      size="xs"
      className={classNames("flex flex-col h-full gap-2 px-3 pb-4 bg-base-100 items-stretch")}
    >
      <H4 className="pt-5">Report an issue.</H4>
      <p className="font-bold text-sm pb-5">
        We're sorry that you're having an issue with another Candid member. Let us know the nature
        of the challenge and well do our best to support.
      </p>
      <div className="pb-5">
        <p>
          Which of these best describes the nature of the issue?{" "}
          <span className="text-red-500 tex t-sm">*</span>
        </p>
        <EnumSelect
          value={reason}
          possibleValues={REPORT_ENUMS}
          onChange={(val) => {
            handleReasonChange(val);
          }}
        />
      </div>
      <p>Please provide any additional details about the issue?</p>
      <div className="pb-4">
        <textarea
          className="w-full h-40 p-3 border rounded-md input input-bordered input-lg resize-none"
          placeholder="Type your text here..."
          value={otherText}
          onChange={(e) => setOtherText(e.target.value)}
        />
      </div>

      <div>
        <input
          type="checkbox"
          id="agreement"
          checked={isChecked}
          onChange={(event) => {
            setIsChecked(event.target.checked);
          }}
          className="mr-2"
        />
        <label htmlFor="agreement">
          I hereby certify that if this event is life-threatening, I have already contacted the
          appropriate authorities prior to submitting this report.
          <span className="text-red-500 tex t-sm">*</span>
        </label>
      </div>
      <button
        disabled={!isChecked}
        onClick={() => handleClick(reason as REPORT_ENUMS, otherText)}
        className={`btn bg-primary text-white`}
      >
        Report issue
      </button>
    </Container>
  );
}
