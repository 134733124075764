import { H2, H3 } from "shared-web-react/dist/widgets/text";

import { Container } from "../../widgets/container";
import { Link } from "react-router-dom";
import { SingleFeatureWidget } from "./widgets";
import { allRoutes } from "../../util/routes";
import discovery from "../../assets/discovery-screenshot.png";
import messages from "../../assets/messages-screenshot.png";
import newsFeed from "../../assets/news-feed-screenshot.png";
import profile from "../../assets/profile-screenshot.png";

export function LandingFeatures(): JSX.Element {
  return (
    <div className="relative bg-info">
      <div
        className="absolute left-0 w-full h-full z-10"
        style={{
          backgroundImage: "radial-gradient(at top left, rgba(0, 0, 0, 1) 0%, transparent 100%)",
          backgroundSize: "cover",
        }}
      ></div>
      <div id="l_features" className="w-screen p-4 z-20 relative">
        <div className="flex p-4 justify-center ">
          <H2 className="p-4 pb-2 my-20 max-w-5xl text-primary-content">
            Your next chapter of ENM adventures is now right at your fingertips.
          </H2>
        </div>
        <Container size="md">
          <div className="space-y-4">
            <SingleFeatureWidget imgSrc={profile} right={false}>
              <p className="text-sm text-left text-[color-mix(in_oklab,oklch(var(--a)),black_7%)] font-bold uppercase ">
                MORE THAN A DATING APP
              </p>
              <H3 className="text-primary-content normal-case">
                Candid is a <em>network</em>.
              </H3>
              <p>
                Add friends and follow along on their journey - your news feed consistently offers
                fresh opportunities to connect.
              </p>
              <Link to={allRoutes.login.buildPath({})} className="btn btn-primary">
                JOIN CANDID
              </Link>
            </SingleFeatureWidget>
            <SingleFeatureWidget imgSrc={discovery} right={true}>
              <p className="text-sm text-left text-[color-mix(in_oklab,oklch(var(--a)),black_7%)]  font-bold uppercase ">
                NO FAKE ACCOUNTS
              </p>

              <H3 className="text-primary-content normal-case">
                Connect with real, verified members
              </H3>
              <p>
                No more catfish, no more wasted time – just authentic connections waiting to happen.
              </p>
              <Link to={allRoutes.login.buildPath({})} className="btn btn-primary">
                JOIN CANDID
              </Link>
            </SingleFeatureWidget>
            <SingleFeatureWidget imgSrc={messages} right={false}>
              <p className="text-sm text-left  text-[color-mix(in_oklab,oklch(var(--a)),black_7%)]  font-bold uppercase ">
                CANDID EVENTS
              </p>

              <H3 className="text-primary-content normal-case">
                Meet in the wild at your favorite events
              </H3>
              <p>
                From private parties to concerts, Candid events allow you to connect with other
                members before and after the fun.
              </p>
              <Link to={allRoutes.login.buildPath({})} className="btn btn-primary">
                JOIN CANDID
              </Link>
            </SingleFeatureWidget>
          </div>
        </Container>
      </div>
    </div>
  );
}
