import {
  GqlOps,
  useShowCandidTeamSettingsQuery,
  useUpdateCandidTeamSettingsMutation,
} from "shared/dist/__generated__/components";

import { H5 } from "shared-web-react/dist/widgets/text";
import { SpinnerCheckbox } from "shared-web-react/dist/widgets/spinner-checkbox";
import { useMyId } from "shared/dist/auth-data";

export function ShowCandidTeamInFeedSettings(): JSX.Element {
  const my_id = useMyId()!;
  const { data, loading } = useShowCandidTeamSettingsQuery({
    variables: { my_id },
    skip: !my_id,
  });
  const [mutate] = useUpdateCandidTeamSettingsMutation();
  const checked = !!data?.users_by_pk?.show_candid_team_account_in_news_feed;
  return (
    <div className="flex flex-row">
      <div className="flex flex-1 flex-col justify-start gap-2">
        <H5 className="pb-2">Push Notifications</H5>
        <div className="flex justify-between items-center">
          <SpinnerCheckbox
            disabled={loading || !my_id}
            onChangeWrapped={async () =>
              my_id &&
              mutate({
                variables: { my_id, value: !checked },
                refetchQueries: [GqlOps.Query.ShowCandidTeamSettings],
              })
            }
            className="font-semibold w-full "
            checked={checked}
            checkboxAtEnd
          >
            Include Candid Team in Feed
          </SpinnerCheckbox>
        </div>
      </div>
    </div>
  );
}
