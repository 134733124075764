import { useEffect, useState } from "react";

import { LandingWidget } from "./widgets";
import { Link } from "react-router-dom";
import { allRoutes } from "../../util/routes";
import appStoreButton from "../../assets/app-store-button.svg";
import login0 from "../../assets/hero-1.jpg";
import login1 from "../../assets/hero-2.jpg";
import login2 from "../../assets/hero-3.jpg";
import login3 from "../../assets/hero-4.jpg";
import login4 from "../../assets/hero-5.jpg";
import login5 from "../../assets/hero-6.jpg";
import logoNeonWhite from "../../assets/neon-logos/candid-neon-white.png";

function preloadImages(images: string[]) {
  images.forEach((image) => {
    const img = new Image();
    img.src = image;
  });
}

const backgroundImages = [login0, login1, login2, login3, login4, login5];
preloadImages(backgroundImages);

export function LandingHero(): JSX.Element {
  const [currentBgIndex, setCurrentBgIndex] = useState(0);
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBgIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImages[currentBgIndex]})`,
        backgroundSize: "cover",
        backgroundPosition: "center 30%",
        height: "100vh",
        position: "relative",
        transition: isMobile ? "" : "background-image 1.5s ease-in-out",
      }}
      className="z-10 items-center"
    >
      <div className="flex absolute w-full max-w-[80vw] justify-start z-20 pt-safe">
        <img src={logoNeonWhite} className="w-40  md:mx-[15vw] mx-[10vw] mt-5" />
      </div>
      <div className="lg:flex md:flex md:justify-around md:items-center px-[10vw] h-full z-50 relative">
        <div className="lg:w-auto md:w-auto w-full md:max-w-[75%] lg:max-w-[50vw]">
          <LandingWidget
            topText="THE FIRST ONLINE ENM NETWORK"
            cta="JOIN CANDID"
            text="The premier discovery & connection platform for the ethically non-monogamous."
            btn={
              <a href="https://apps.apple.com/us/app/candid-enm/id6466639507">
                <img src={appStoreButton} />
              </a>
            }
          />
        </div>
        <div className="lg:w-auto md:w-auto w-full md:max-w-[75%] lg:max-w-[50vw]">
          <LandingWidget
            topText="Returning Users"
            cta="LOGIN"
            text="If you're already an approved Candid member, login below."
            btn={
              <Link className={`btn btn-primary uppercase`} to={allRoutes.login.buildPath({})}>
                <div>LOGIN</div>
              </Link>
            }
          />
        </div>
      </div>
      <div
        className="absolute bottom-0 left-0 w-full h-full z-1"
        style={{
          backgroundImage: "radial-gradient(at bottom left, rgba(0, 0, 0, 1) 0%, transparent 100%)",
          backgroundSize: "cover",
        }}
      ></div>{" "}
    </div>
  );
}
