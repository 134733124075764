import { H1 } from "shared-web-react/dist/widgets/text";
import React from "react";
import clsx from "clsx";

type ObScreenProps = React.PropsWithChildren<{
  title: string;
  subtitle?: string;
}>;
export function ObScreen({ children, title, subtitle }: ObScreenProps): JSX.Element {
  return (
    <>
      <div className="flex-0 ">
        <H1 className="mt-10 text-5xl mb-6  normal-case">{title}</H1>
        {subtitle && (
          <p className={clsx("text-md font-semibold my-4 text-candid-gray-600")}>{subtitle}</p>
        )}
      </div>
      <div className="flex-1 flex relative">{children}</div>
    </>
  );
}

export type ObPageProps = {
  setDisableButton: (b: boolean) => void;
};

export type ObPagePropsWithNext = ObPageProps & { onNext: () => Promise<unknown> };
