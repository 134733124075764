import { CURRENT_API_VERSION, MIN_API_VERSION } from "shared/dist/version";
import { useAuthData, useMyId } from "shared/dist/auth-data";
import { useEnv, useIsProd } from "shared/dist/util/env";

import { GITHUB_SHA } from "../util/norepo-github-sha";
import { H3 } from "shared-web-react/dist/widgets/text";
import { Spinner } from "shared-web-react/dist/widgets/spinner";
import clsx from "clsx";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useCallback } from "react";
import { useMySlug } from "../screens/bio/bio-settings";
import { useOnScreenLogger } from "shared-web-react/dist/util/on-screen-logger";
import { useServerApiVersionsQuery } from "shared/dist/__generated__/components";

export function DevInfo(): JSX.Element {
  const isProd = useIsProd();
  const myId = useMyId();
  const slugData = useMySlug();
  const auth = useAuthData();

  const { enabled, setEnabled } = useOnScreenLogger();
  const server = useEnv("REACT_APP_BASE_URI");
  const { data, loading } = useServerApiVersionsQuery({
    fetchPolicy: "cache-and-network",
  });
  const addToast = useAddToast();
  const copy = useCallback(
    (s: string) => {
      if (navigator?.clipboard?.writeText) {
        navigator?.clipboard?.writeText(s);
        addToast({ content: "copied!" });
      }
    },
    [addToast]
  );
  if (isProd) {
    return <div></div>;
  }
  return (
    <div className="space-y-4 relative p-3">
      <H3>dev info</H3>
      <div>
        <label className=" flex flex-row items-center justify-start gap-2">
          <input
            type="checkbox"
            className="checkbox"
            checked={enabled}
            onChange={() => setEnabled(!enabled)}
          />
          Enable onscreen logger
        </label>
      </div>
      <div className={clsx("relative max-w-full")}>
        <div className="font-bold min-w-0 max-w-full overflow-x-auto">
          <p>app version: {CURRENT_API_VERSION}</p>
          {loading && !data?.l_min_api_version ? (
            <span className={clsx("mx-2")}>
              <Spinner />
            </span>
          ) : (
            <>
              <p>server version: {data?.l_min_api_version?.current_version}</p>
              <p>server min version: {data?.l_min_api_version?.min_version}</p>
              <p>server git version: {data?.l_min_api_version?.git_revno}</p>
              <p>server: {server}</p>
            </>
          )}
        </div>
        <div className="divider my-1 " />
        <div className="font-bold min-w-0 max-w-full overflow-x-auto">
          user id: <span onClick={() => copy(myId ?? "")}>{myId ?? "<null>"}</span>
        </div>
        <div className="divider my-1 " />
        <div className="font-bold min-w-0 max-w-[70vw] overflow-x-hidden text-ellipsis">
          auth: <span onClick={() => copy(auth?.token ?? "")}>{auth?.token ?? "<null>"}</span>
        </div>
        <div className="divider my-1 " />
        <div className="font-bold min-w-0 max-w-full overflow-x-auto">
          slug:
          <span onClick={() => copy(slugData?.slug ?? "")}>{"@" + slugData?.slug}</span>
        </div>
        <div className="divider my-1 " />
        <div className="font-bold min-w-0 max-w-full overflow-x-auto">
          latest commit:
          <span onClick={() => copy(GITHUB_SHA)}>{GITHUB_SHA}</span>
        </div>
      </div>
    </div>
  );
}
