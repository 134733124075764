import {
  Bio_Basics_On_SuccessFragment,
  L_Bio_Access_Denied_Reason_Enum,
  L_Bio_Error_Enum,
  useBioV2FromSlugBasicsQuery,
} from "shared/dist/__generated__/components";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { P, match } from "ts-pattern";
import { Spinner, SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { UserPageTab, UserPageTabLabel } from "../../../widgets/tabs";
import { faBook, faCamera, faEyeSlash, faNewspaper } from "@fortawesome/pro-solid-svg-icons";

import { BioBasicsV2 } from "../bio-basics";
import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H4 } from "shared-web-react/dist/widgets/text";
import React from "react";
import { allRoutes } from "../../../util/routes";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useNavigateToLogin } from "../../../util/redirects";
import { useSetPageTitle } from "shared-web-react/dist/widgets/nav-stack-view";
import { useTypedParams } from "react-router-typesafe-routes/dom";

function BioAccessDenied({ reason }: { reason: L_Bio_Access_Denied_Reason_Enum }): JSX.Element {
  const navigateToLogin = useNavigateToLogin();
  return (
    <div className="fixed top-0 left-0 bottom-0 right-0 w-full h-full flex justify-center items-center p-4 ">
      <div className="my-[10vh] text-center p-6 card bg-accent max-w-[25rem] flex flex-col gap-2 rounded-xl">
        <H4 className="">
          <FontAwesomeIcon icon={faEyeSlash} />
        </H4>
        <H4>Access denied:</H4>
        {reason === L_Bio_Access_Denied_Reason_Enum.LoginAndVerificationRequired && (
          <>
            <p>You must login and then verify your account to view this profile</p>
            <a className="btn btn-primary" onClick={navigateToLogin}>
              Login
            </a>
          </>
        )}
        {reason === L_Bio_Access_Denied_Reason_Enum.LoginRequired && (
          <>
            <p>You must login to view this profile</p>
            <a className="btn btn-primary" onClick={navigateToLogin}>
              Login
            </a>
          </>
        )}
        {reason === L_Bio_Access_Denied_Reason_Enum.VerificationRequired && (
          <>
            <p>You must verify your account to view this profile</p>
            <Link className="btn btn-primary" to={allRoutes.SETTINGS.PRIVACY.buildPath({})}>
              Go to verifcation
            </Link>
          </>
        )}
        {reason === L_Bio_Access_Denied_Reason_Enum.RequesterBlockedThisUser && (
          <>
            <p>You must unblock this user to view their profile.</p>
            <Link className="btn btn-primary" to={allRoutes.SETTINGS.ACCOUNT.buildPath({})}>
              Go to blocked users
            </Link>
          </>
        )}
        {reason === L_Bio_Access_Denied_Reason_Enum.RequesterIsBlockedByThisUser && (
          <>
            <p>This user has blocked your account</p>
            <Link className="btn btn-primary" to={allRoutes.HOME.buildPath({})}>
              Ok
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

export function ProfilePageV2(): JSX.Element {
  const slug = useTypedParams(allRoutes.PROFILE).slug!;
  const { data, loading } = useBioV2FromSlugBasicsQuery({
    skip: !slug,
    variables: { slug },
    fetchPolicy: "cache-and-network",
  });
  const navigate = useNavigate();
  // const addToast = useAddToast();
  React.useEffect(() => {
    if (!slug) navigate(allRoutes.HOME.buildPath({}));
  }, []);
  if (loading && !data?.l_bio_v2) return <SpinnerCentered />;
  return match(data?.l_bio_v2)
    .with(P.nullish, () => <SpinnerCentered />)
    .with({ __typename: "L_Bio_Error", bio_error: L_Bio_Error_Enum.NotFound }, (_) => (
      <ErrorComp caption={"No such user: " + slug} />
    ))
    .with({ __typename: "L_Bio_Error" }, () => (
      <ErrorComp caption={"Could not load this page, please try again later"} />
    ))
    .with({ access_denied_reason: P.select() }, (reason) => <BioAccessDenied reason={reason} />)
    .with({ __typename: "L_Bio_V2_Success" }, (bioData) =>
      bioData?.id === slug ? <ProfilePageOnSuccessV2 {...{ slug, bioData }} /> : <SpinnerCentered />
    )
    .exhaustive();
}

function ProfilePageOnSuccessV2({
  slug,
  bioData,
}: {
  slug: string;
  bioData: Bio_Basics_On_SuccessFragment;
}): JSX.Element {
  const editable = bioData.editable ?? false;
  useSetPageTitle(bioData.user.screen_name);
  return (
    <div className=" max-w-screen max-lg:pb-20">
      <div className="relative">
        <div className="absolute bg-white w-screen top-0 bottom-0"></div>
        <BioBasicsV2
          screen_name={bioData.user.screen_name}
          friend_count={bioData.details.friend_count}
          profile_image_id={bioData.user.profile_image_id ?? undefined}
          editable={editable}
          status={bioData.current_status}
          verified={bioData.details.identity_verified ?? false}
          slug={slug}
        />
        <div className="tabs-xs md:tabs-sm lg:tabs-md tabs-bordered tabs flex w-full mt-2 flex-nowrap justify-center pb-1 ">
          {/* <div className="tabs"> */}
          <UserPageTab hideIndicator route={allRoutes.PROFILE.buildPath({ slug })}>
            <FontAwesomeIcon icon={faCamera} />
            <UserPageTabLabel>Media</UserPageTabLabel>
          </UserPageTab>
          {/* {editable && (
            <UserPageTab route={allRoutes.PROFILE.EVENTS.buildPath({ slug })}>
              <FontAwesomeIcon icon={faCakeCandles} />
              <UserPageTabLabel>my events</UserPageTabLabel>
            </UserPageTab>
          )} */}
          <UserPageTab route={allRoutes.PROFILE.ABOUT.buildPath({ slug })}>
            <FontAwesomeIcon icon={faBook} />
            <UserPageTabLabel>About Me</UserPageTabLabel>
          </UserPageTab>
          <UserPageTab route={allRoutes.PROFILE.FEED.buildPath({ slug })}>
            <FontAwesomeIcon icon={faNewspaper} />
            <UserPageTabLabel>Feed</UserPageTabLabel>
          </UserPageTab>
        </div>
      </div>
      <Outlet />
    </div>
  );
}
