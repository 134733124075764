import { classNames } from "shared/dist/util";
import React from "react";
import clsx from "clsx";
import { PortalDialog } from "shared-web-react/dist/widgets/confirm-provider";
import { useRelationshipFunctions } from "shared/dist/connections/relationships";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { Relationship_Types_Enum } from "shared/dist/__generated__/components";

export type MatchNotificationPopupProps = {
  className?: string;
  slug: string;
  showPopup?: string | null;
  displayPassModal: boolean;
  refetch: () => void;
};

export function MatchNotificationPopup({
  slug,
  showPopup,
  refetch,
  displayPassModal,
}: React.PropsWithChildren<MatchNotificationPopupProps>): JSX.Element {
  const [showDialog, setShowDialog] = React.useState(showPopup);
  const ref = React.createRef<HTMLDialogElement>();
  ref?.current?.showModal;
  React.useEffect(
    () => (showPopup ? ref?.current?.showModal?.() : ref?.current?.close?.()),
    [showPopup]
  );
  const [loading, setLoading] = React.useState(false);

  const addToast = useAddToast();
  const { acceptRequest, disconnect } = useRelationshipFunctions({
    setLoading,
    onError: (content) => addToast({ content, color: "error" }),
  });

  return (
    <PortalDialog
      onCancel={() => {
        refetch();
        ref?.current?.close?.();
      }}
      ref={ref}
      className={classNames(showDialog ? "" : "hidden")}
    >
      <div className="flex flex-col gap-2 items-stretch justify-center">
        <h3 className="font-bold mb-2 text-lg">
          Do you want to {displayPassModal ? "pass on" : "match with"} {slug}{" "}
          {!displayPassModal && "❤️"}
        </h3>
        {
          <div
            onClick={() => {
              displayPassModal
                ? disconnect(slug, Relationship_Types_Enum.Like).then(() => {
                    refetch();
                  })
                : acceptRequest(slug, Relationship_Types_Enum.Like).then(() => {
                    refetch();
                  });
            }}
            className={clsx("w-full flex-1 btn btn-secondary ")}
          >
            <div className="w-full flex-1 btn btn-secondary">
              {" "}
              {displayPassModal ? "Pass" : "Match"}{" "}
            </div>
          </div>
        }
        <button
          className="btn btn-outline"
          onClick={() => {
            refetch();
          }}
        >
          Cancel
        </button>
      </div>
    </PortalDialog>
  );
}
