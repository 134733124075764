import { ProfileList, ProfileListItem, ProfileListProps } from "../../../widgets/profile-list";
import React, { useEffect } from "react";
import { classNames, filterNulls } from "shared/dist/util";
import { AddThreadButton } from "../../../widgets/add-thread-button";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { ZeroScreen } from "../../../widgets/zero-screens";
import { allRoutes } from "../../../util/routes";
import { useMyId } from "shared/dist/auth-data";
import { useMyRelationshipsShortV410Query } from "shared/dist/__generated__/components";

export function DiscoveryConnections(): JSX.Element {
  const my_id = useMyId();
  const { data, loading, refetch } = useMyRelationshipsShortV410Query({
    skip: !my_id,
    variables: { type: "like", my_id: my_id! },
  });
  const mkRoute: ProfileListProps["mkRoute"] = React.useCallback(
    ({ slug, ...other }: ProfileListItem) =>
      slug
        ? {
            to: allRoutes.PROFILE.buildPath({ slug }),
          }
        : null,
    []
  );
  const mkActions: ProfileListProps["mkActions"] = React.useCallback(
    ({ slug, ...other }: ProfileListItem) => [
      slug
        ? {
            component: (
              <AddThreadButton slug={slug}>
                <div className="btn btn-sm btn-outline">Message</div>
              </AddThreadButton>
            ),
          }
        : null,
    ],
    []
  );
  const items: Array<ProfileListItem> = React.useMemo(
    () =>
      filterNulls(
        data?.relationships_union?.map((e) =>
          e.snd_user_summary?.slug && e.snd_user_summary?.screen_name
            ? {
                slug: e.snd_user_summary.slug,
                screenName: e.snd_user_summary.screen_name,
              }
            : null
        )
      ),
    [data]
  );

  useEffect(() => {
    refetch();
  }, []);
  return items.length === 0 ? (
    <div className="flex flex-col h-full w-full overflow-hidden">
      <ZeroScreen screenType="discovery-connections" />
    </div>
  ) : (
    <div
      className={classNames(
        "flex justify-start items-stretch  flex-col join join-vertical gap-4  h-full relative"
      )}
    >
      {loading ? (
        <SpinnerCentered />
      ) : (
        <div className={classNames("p-4 pb-16 max-h-full overflow-y-auto")}>
          <ProfileList profiles={items} {...{ mkActions, mkRoute, columnCountOverride: 1 }} />
        </div>
      )}
    </div>
  );
}
