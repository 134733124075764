import { useAuthIsLoading, useMyId } from "shared/dist/auth-data";
import { PrivacyText } from "./privacytext";
import { LandingFooter } from "../landing/footer";
import { TermsNavButtons } from "./widgets";
export function Privacy(): JSX.Element {
  return (
    <div className="w-screen bg-red-500">
      {/* <LandingHero /> */}
      {/* <LandingLoginButton />
      <LandingBuilding />
    <LandingMailchimp /> */}
      <PrivacyText />
      <LandingFooter />
    </div>
  );
}
