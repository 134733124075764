import {
  GqlOps,
  L_Geo_Fuzzy_Response,
  L_Geo_Point_Type,
  useSaveLocation500Mutation,
  useSearchLocationsLazyQuery,
  useSetUserLocationPreference500Mutation,
  useUserLocations500Query,
} from "shared/dist/__generated__/components";
import { faCheck, faSpinner } from "@fortawesome/pro-solid-svg-icons";

import { Combobox } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H4 } from "shared-web-react/dist/widgets/text";
import { Loc } from "shared/dist/util/loc";
import React from "react";
import { SpinnerCheckbox } from "shared-web-react/dist/widgets/spinner-checkbox";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useDebounce } from "use-debounce";
import { useMyId } from "shared/dist/auth-data";

export type LocationSettingsProps = {
  // onLoadingChange?: (loading: boolean) => void;
};

export type SingleLocation = L_Geo_Fuzzy_Response["results"][0];

export const exampleLocations: Record<string, { long: number; lat: number }> = {
  brickell: { long: -80.195574, lat: 25.768282 },
  downtown_miami: { long: -80.197046, lat: 25.771908 },
  edgewater: { long: -80.197046, lat: 25.781908 },
  fort_lauderdale: { long: -80.134756, lat: 26.126207 },
  philly: { long: -75.1756197, lat: 39.9499061 },
  lax: { long: -118.423013, lat: 33.943693 },
  pasadena: { long: -118.166093, lat: 34.161083 },
  ny: { long: -73.9856, lat: 40.7517 },
  hoboken: { long: -74.032796, lat: 40.74086 },
  brooklyn: { long: -73.949, lat: 40.752876 },
};
export const BasicLocations = {
  la: {
    country: "United States of America",
    label: "Los Angeles",
    long: -118.423013,
    lat: 33.943693,
  },
  sd: {
    country: "United States of America",
    label: "San Diego",
    long: -117.191834,
    lat: 32.733768,
  },
  miami: { country: "United States of America", label: "Miami", long: -80.195574, lat: 25.768282 },
  ny: { country: "United States of America", label: "New York", long: -73.9856, lat: 40.7517 },
  lv: {
    country: "United States of America",
    label: "Las Vegas",
    long: -115.157216,
    lat: 36.092244,
  },
  sf: {
    country: "United States of America",
    label: "San Francisco",
    lat: 37.773904,
    long: -122.411521,
  },
};

export function useSaveLocation({
  source,
  suppressToast,
}: {
  source: L_Geo_Point_Type;
  suppressToast?: boolean;
}) {
  const [saveMutation] = useSaveLocation500Mutation();
  const addToast = useAddToast();
  const save = React.useCallback(
    async ({ lat, long }: Loc) => {
      console.log("🚀 ~ file: location-picker.tsx:29 ~ l", { lat, long, source });
      const mutation = await saveMutation({
        refetchQueries: [
          GqlOps.Query.BioV2FromSlugBasics,
          GqlOps.Query.DiscoveryResults300,
          GqlOps.Query.NewsFeed440,
          GqlOps.Query.UserLocations500,
        ],
        variables: { lat, long, source },
      });
      if (!suppressToast) addToast({ content: "Location Saved" });
      return mutation;
    },
    [saveMutation]
  );
  return save;
}

// export function useUserHasLocation() {
//   const my_id = useMyId()!;
//   const { data, loading } = useUserLocations500Query({ variables: { user_id: my_id } });
//   return {
//     hasLocation:
//       data?.users_by_pk?.last_location !== null || data?.users_by_pk?.location_description !== null,
//     loading,
//   };
// }

export function LocationSettings({}: LocationSettingsProps): JSX.Element {
  const my_id = useMyId()!;
  const { data, loading, error, ...rest } = useUserLocations500Query({
    variables: { user_id: my_id },
    skip: !my_id,
    fetchPolicy: "cache-and-network",
  });
  // const [pickerLoading, setPickerLoading] = React.useState(false);
  const saveLocation = useSaveLocation({ source: L_Geo_Point_Type.Manual });

  const [setUseManualLocation] = useSetUserLocationPreference500Mutation();
  const gpsLocation = data?.users_by_pk?.location_gps?.description;
  // React.useEffect(() => {
  //   onLoadingChange?.(saveMutationResult.loading || pickerLoading);
  // }, [saveMutationResult.loading, pickerLoading]);
  const manual = data?.users_by_pk?.use_manual_location_over_gps;
  return (
    <div className="flex-col justify-start items-stretch pb-2">
      <H4>Location</H4>
      {gpsLocation && (
        <div className={clsx("", manual && "opacity-40")}>
          Current Location: <span className={clsx("text-primary")}>{gpsLocation}</span>
        </div>
      )}
      <div className={clsx("divider my-0")} />
      <div className="form-control flex-1">
        <div className="flex justify-between items-center">
          <SpinnerCheckbox
            onChangeWrapped={() =>
              setUseManualLocation({
                refetchQueries: [
                  GqlOps.Query.BioV2FromSlugBasics,
                  GqlOps.Query.DiscoveryResults300,
                  GqlOps.Query.NewsFeed440,
                  GqlOps.Query.UserLocations500,
                ],
                variables: { my_id, manual: !manual },
              })
            }
            className=" w-full "
            loading={loading}
            disabled={loading}
            checked={manual}
            checkboxAtEnd
          >
            Would like to to set your location manually?
          </SpinnerCheckbox>
        </div>
      </div>
      <div className="form-control flex-1 pt-2">
        <LocationPicker
          onSelect={saveLocation}
          disabled={loading || !manual}
          onLoadingChange={() => {}}
          defaultLabel={manual ? data?.users_by_pk?.location_manual?.description ?? null : null}
        />
      </div>
    </div>
  );
}

type LocationPickerProps = {
  onSelect?: (l: Loc) => void;
  onLoadingChange?: (loading: boolean) => void;
  defaultLabel?: string | null;
  disabled?: boolean;
  hideIcon?: boolean;
  hideValueOnDisabled?: boolean;
  loading?: boolean;
};
// eventually refactor this into a separate file
export function LocationPicker({
  defaultLabel,
  onSelect,
  onLoadingChange,
  hideValueOnDisabled,
  hideIcon,
  disabled,
  loading,
}: LocationPickerProps): JSX.Element {
  const [query, setQuery] = React.useState("Los Angeles");
  const [queryDebounced] = useDebounce(query, 500);
  const [search, searchResults] = useSearchLocationsLazyQuery();
  const locationOptions: Array<SingleLocation> =
    searchResults.data?.l_geo_fuzzy_search?.results ?? [];
  const [loadingOptions, setLoadingOptions] = React.useState(false);
  const [selectedLocation, setSelectedLocationInner] = React.useState<null | SingleLocation>(null);
  const setSelectedLocation = React.useCallback((loc: SingleLocation) => {
    setSelectedLocationInner(loc);
    onSelect?.(loc);
  }, []);
  // const searchDebounced = React.useRef(
  //   debounce(500, async (q: string, search: (_: String) => Promise<any>) => search(q))
  // );
  React.useEffect(() => {
    onLoadingChange?.(loadingOptions || searchResults.loading);
  }, [loadingOptions, searchResults.loading]);
  React.useEffect(() => {
    setLoadingOptions(true);
    search({ variables: { search: queryDebounced } })
      .catch((e: any) => {
        console.error("🚀 - file: location-picker.tsx:130 - e:", e);
      })
      .finally(() => setLoadingOptions(false));
  }, [queryDebounced, search, setLoadingOptions]);
  // const setQuery = React.useCallback(
  //   async (q: string) => {
  //     setLoadingOptions(true);
  //     searchDebounced.current(q, () =>
  //       search({ variables: { search: q } })
  //         .catch((e: any) => {
  //           console.error("🚀 - file: location-picker.tsx:130 - e:", e);
  //         })
  //         .finally(() => setLoadingOptions(false))
  //     );
  //   },
  //   [setLoadingOptions]
  // );
  const anyLoading = loadingOptions || searchResults.loading || loading;
  return (
    <Combobox value={selectedLocation} disabled={disabled} onChange={setSelectedLocation}>
      <div className="form-control">
        <label className={clsx(!hideIcon && "join")}>
          <Combobox.Input<SingleLocation | null, "input">
            className="join-item input input-bordered focus:outline-none w-full"
            onChange={(event) => !disabled && setQuery(event.target.value)}
            displayValue={(loc: SingleLocation | null) =>
              disabled && hideValueOnDisabled ? "" : loc?.label ?? defaultLabel ?? ""
            }
          />
          {!hideIcon && (
            <span className={clsx("join-item btn")}>
              <FontAwesomeIcon
                className={clsx(disabled && hideValueOnDisabled && "opacity-0")}
                icon={anyLoading ? faSpinner : faCheck}
                spin={anyLoading}
                fixedWidth
              />
            </span>
          )}
        </label>
      </div>
      <div
        className={classNames(
          "dropdown",
          !disabled && locationOptions.length > 0 && "dropdown-open"
        )}
      >
        <Combobox.Options
          as="ul"
          className={classNames(
            "dropdown-content bg-primary-content menu rounded-box p-1 shadow ",
            "w-fit max-w-full text-ellipsis overflow-hidden z-20-main-ui-overlay"
          )}
        >
          {locationOptions.slice(0, 5).map((loc) => (
            <Combobox.Option as="li" key={loc.label} value={loc} className="max-w-full my-0">
              <a className="whitespace-nowrap max-w-full overflow-x-hidden block text-ellipsis">
                {loc.label}
              </a>
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}
