import React from "react";
import {
  ProfileList,
  ProfileListItem,
  ProfileListProps,
  profileListItemFromSummary,
} from "../../../widgets/profile-list";
import {
  Relationship_Types_Enum,
  useInboundRelationshipRequestsForUserV410Query,
} from "shared/dist/__generated__/components";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { ZeroScreen } from "../../../widgets/zero-screens";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { useMyId } from "shared/dist/auth-data";
import { useRelationshipFunctions } from "shared/dist/connections/relationships";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { DateTime } from "luxon";
import { useSecureStorage } from "../../../widgets/use-secure-storage";
import { useIndicators } from "../../../widgets/indicators";
import { allRoutes } from "../../../util/routes";

type FriendsRequestListProps = {};

export function InboundFriendsRequestList({}: FriendsRequestListProps): JSX.Element {
  const my_id = useMyId() ?? "";
  const confirm = useConfirm();
  const addToast = useAddToast();
  const { slug } = useTypedParams(allRoutes.PROFILE.INVITES);
  const [relBtnsLoading, setRelBtnsLoading] = React.useState(false);
  const [initialLastChecked, setInitialLastChecked] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const { getSecureValue, setSecureValue } = useSecureStorage("friends_last_checked");
  const { clearInboundRequestsIndicator } = useIndicators();

  React.useEffect(() => {
    const fetchInitialDate = async () => {
      const value = await getSecureValue();
      setInitialLastChecked(value);
      setIsLoading(false);
      clearInboundRequestsIndicator();
    };
    fetchInitialDate();
  }, [getSecureValue]);

  const { acceptRequest, disconnect } = useRelationshipFunctions({
    onError: (content) => addToast({ content, color: "error" }),
    setLoading: setRelBtnsLoading,
  });
  const { data, loading, refetch } = useInboundRelationshipRequestsForUserV410Query({
    variables: { types: ["friend"], my_id },
  });

  React.useEffect(() => {
    if (!isLoading && !loading) {
      const newLastChecked = new Date().toISOString();
      setSecureValue(newLastChecked);
    }
  }, [isLoading, loading, setSecureValue]);

  const profiles = React.useMemo(() => {
    return (
      data?.relationships_union.map((r) => {
        const createdAt = r.created_at ? DateTime.fromISO(r.created_at) : null;
        const initialLastCheckedDate = initialLastChecked
          ? DateTime.fromISO(initialLastChecked)
          : null;
        const showNewBadge =
          createdAt && initialLastCheckedDate ? createdAt > initialLastCheckedDate : false;

        const profileItem = profileListItemFromSummary(r.snd_user_summary);
        if (profileItem) {
          return { ...profileItem, showNewBadge };
        }
        return null;
      }) ?? []
    );
  }, [data, my_id]);

  const mkActions: ProfileListProps["mkActions"] = React.useCallback(
    ({ slug, screenName, ...other }: ProfileListItem) => {
      return slug
        ? [
            {
              component: (
                <div
                  className="btn btn-sm btn-primary"
                  onClick={() =>
                    new Promise((resolve) => {
                      if (!my_id) return resolve(null);
                      confirm({
                        title: `Do you want to accept this request from ${screenName}?`,
                        onOk: async () => {
                          const result = await acceptRequest(slug, Relationship_Types_Enum.Friend);
                          if (result) {
                            addToast({
                              content: `Added ${slug} as a Friend!`,
                              color: "success",
                            });
                            await refetch();
                            return resolve(null);
                          }
                          addToast({
                            content: `Unable to complete`,
                            color: "error",
                          });
                          await refetch();
                          resolve(null);
                        },
                      });
                    })
                  }
                >
                  Accept
                </div>
              ),
            },
            {
              component: (
                <div
                  className="btn btn-sm btn-outline"
                  onClick={() =>
                    new Promise((resolve) => {
                      if (!my_id) return resolve(null);
                      confirm({
                        title: `Do you want to cancel this friend request from ${screenName}?`,
                        onOk: async () => {
                          await disconnect(slug, Relationship_Types_Enum.Friend);
                          addToast({
                            content: `Canceled Request!`,
                            color: "success",
                          });
                          await refetch();
                          resolve(null);
                        },
                      });
                    })
                  }
                >
                  Cancel
                </div>
              ),
            },
          ]
        : [];
    },
    [my_id, confirm, acceptRequest, disconnect, addToast, refetch, data?.relationships_union.length]
  );

  const hasBadge = React.useCallback((profile: ProfileListItem) => {
    return profile.showNewBadge || false;
  }, []);

  if (loading || relBtnsLoading || isLoading) {
    return <SpinnerCentered />;
  }
  if (profiles.length === 0 || !slug) {
    return (
      <div className="flex flex-col h-full w-full overflow-hidden">
        <ZeroScreen screenType="friends-inbound-request" />
      </div>
    );
  }
  return (
    <div className="InboundFriendsRequestList p-5">
      <ProfileList {...{ profiles, mkActions }} hasBadge={hasBadge} />
      {/* <div className="flex justify-between p-2 bg-[#EBEADD]">
        <p className="text-black text-sm md:text-lg max-w-md w-2/3">
          Know someone who’s a good fit for Candid? Let’s invite them! 🎁
        </p>
        <Link
          to={allRoutes.PROFILE.INVITES.buildPath({ slug })}
          className="btn bg-warning-content text-primary-content"
        >
          Invite
        </Link>
      </div> */}
    </div>
  );
}
