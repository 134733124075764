import {
  GqlOps,
  useCheckCanInitiatePersonaIdentityQuery,
  useCompletePersonaIdentityVerificationMutation,
  useRevokeVerifications430Mutation,
} from "shared/dist/__generated__/components";
import { P, match } from "ts-pattern";

import type { ClientOptions } from "persona";
import { ErrorBoundary } from "react-error-boundary";
import { InquiryError } from "persona/dist/lib/interfaces";
import PersonaReact from "persona-react";
import { PortalDialog } from "shared-web-react/dist/widgets/confirm-provider";
import React from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { VerifiedIdentityIcon } from "../identity";
import clsx from "clsx";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useEnv } from "shared/dist/util/env";
import { useIdentityVerifiedStatus } from "./common";
import { useMyId } from "shared/dist/auth-data";

function PersonaIframe({
  onCancel,
  onComplete,
  onError,
}: Pick<PersonaButtonProps, "onComplete" | "onCancel" | "onError">): JSX.Element {
  console.log("🚀 - file: persona.tsx:13 - PersonaIframe -");
  const initiateQuery = useCheckCanInitiatePersonaIdentityQuery();
  const [completeInquiry, {}] = useCompletePersonaIdentityVerificationMutation();
  const environmentId = useEnv("REACT_APP_PERSONA_ENV_ID");
  const templateId = useEnv("REACT_APP_PERSONA_TEMPLATE_ID");
  const onCompleteWrapped = React.useCallback(
    async ({
      status,
      inquiryId,
      fields,
    }: Parameters<NonNullable<ClientOptions["onComplete"]>>[0]) => {
      console.log("🚀 - file: persona.tsx:24 - PersonaIframe", { inquiryId, status, fields });
      if (status === "completed") {
        await completeInquiry({
          variables: {
            inquiry_id: inquiryId,
          },
        });
        onComplete?.();
      } else {
        onError?.("Failed to complete inquiry");
      }
    },
    []
  );
  if (initiateQuery.loading) return <SpinnerCentered className="pt-8" />;
  return (
    <PersonaReact
      {...{
        templateId,
        environmentId,
        onComplete: onCompleteWrapped,
      }}
      onCancel={onCancel}
      onError={(error) => {
        console.error("🚀 - file: persona.tsx:27 - PersonaIframe - error:", error);
        onError?.(error);
      }}
    />
  );
}

function PersonaError({ error }: any) {
  return (
    <div>
      <p>Something went wrong 😭</p>

      {error.message && <span>Here's the error: {error.message}</span>}
      <pre>{error?.cause ?? ""}</pre>
    </div>
  );
}

export type PersonaButtonProps = {
  onComplete?: () => unknown;
  onCancel?: () => unknown;
  onError?: (error: InquiryError | string) => unknown;
  revokeBtnContent?: React.ReactNode;
  beginBtnContent?: React.ReactNode;
  revokeBtnClassName?: string;
  beginBtnClassName?: string;
  suppressToast?: boolean;
  hideRevokedOption?: boolean;
};

/**
 * A button component that handles the Persona identity verification process.
 *
 * @param {Function} props.onCancel - Callback function called when the process is canceled.
 * @param {Function} props.onComplete - Callback function called when the process completes successfully.
 * @param {Function} props.onError - Callback function called when an error occurs during the process.
 * @param {string} [props.revokeBtnClassName] - Optional CSS class for the revoke button.
 * @param {boolean} [props.suppressToast] - Optional flag to suppress toast notifications.
 * @param {boolean} [props.hideRevokedOption] - Optional flag to hide the button if we're already verified.
 * @param {React.ReactNode} [props.revokeBtnContent] - Optional content for the revoke button.
 * @param {string} [props.beginBtnClassName] - Optional CSS class for the begin button.
 * @param {React.ReactNode} [props.beginBtnContent] - Optional content for the begin button.
 *
 * @returns {JSX.Element} The rendered PersonaButton component.
 */

export function PersonaButton(props: PersonaButtonProps): JSX.Element {
  const addToast = useAddToast();
  return (
    <ErrorBoundary
      FallbackComponent={PersonaError}
      onError={(err) => addToast({ content: err.message, color: "warning" })}
    >
      <PersonaButtonInner {...props} />
    </ErrorBoundary>
  );
}

const PersonaDialogContext = React.createContext<{
  hide: () => void;
  show: (
    props: Pick<PersonaButtonProps, "onError" | "onCancel" | "onComplete">,
    suppressToast: boolean
  ) => void;
}>({
  hide() {},
  show() {},
});

export function PersonaDialogProvider({ children }: { children: React.ReactNode }) {
  const ref = React.useRef<HTMLDialogElement>(null);
  const addToast = useAddToast();
  const [handlers, setHandlers] = React.useState<null | Pick<
    PersonaButtonProps,
    "onError" | "onCancel" | "onComplete"
  >>(null);
  const [suppressToast, setSuppressToast] = React.useState(false);
  const { refetch } = useIdentityVerifiedStatus();
  return (
    <PersonaDialogContext.Provider
      value={{
        hide: () => {
          setHandlers(null);
          ref.current?.close?.();
        },
        show: (handlersArg, suppressToastArg) => {
          console.log("🚀 - file: persona.tsx:149 - suppressToastArg:", suppressToastArg);
          ref?.current?.showModal?.();
          setHandlers(handlersArg);
          setSuppressToast(suppressToastArg);
        },
      }}
    >
      <PortalDialog
        ref={ref}
        onCancel={() => {
          ref.current?.close();
        }}
        modalBoxClassName="p-0 "
      >
        <div
          className={clsx(
            "min-h-[80vh] relative z-1000-navbar ",
            "[&>*]:inset-0 [&>*]:absolute [&>*]:h-full [&>*]:w-full"
          )}
        >
          <ErrorBoundary
            FallbackComponent={PersonaError}
            onError={(err) => addToast({ content: err.message, color: "warning" })}
          >
            <PersonaIframe
              onComplete={async () => {
                if (!suppressToast)
                  addToast({
                    content: "Verification complete - saving your data",
                    color: "info",
                  });
                await handlers?.onComplete?.();
                ref?.current?.close?.();
                refetch();
              }}
              onError={async (err) => {
                console.log("🚀 - file: persona.tsx:169 - onError={ - err:", err);
                await handlers?.onError?.(err);
                if (!suppressToast) addToast({ content: "An error occurred", color: "warning" });
                ref?.current?.close?.();
              }}
              onCancel={() => {
                handlers?.onCancel?.();
                ref?.current?.close?.();
              }}
            />
          </ErrorBoundary>
        </div>
      </PortalDialog>
      {children}
    </PersonaDialogContext.Provider>
  );
}

function PersonaButtonInner({
  onCancel,
  onComplete,
  onError,
  revokeBtnClassName,
  suppressToast,
  revokeBtnContent,
  hideRevokedOption,
  beginBtnClassName,
  beginBtnContent,
}: PersonaButtonProps): JSX.Element {
  const { verified, loading } = useIdentityVerifiedStatus();
  const { revoke, loading: revokeLoading } = useRevokeVerification();
  const show = React.useContext(PersonaDialogContext).show;
  const anyLoading = loading || revokeLoading;
  return (
    <>
      {match([anyLoading, verified])
        .with([true, P._], () => <SpinnerCentered className="py-2" />)
        .with([P._, true], () =>
          hideRevokedOption ? (
            <></>
          ) : (
            <div>
              <button className={revokeBtnClassName ?? "text-primary"} onClick={() => revoke()}>
                {revokeBtnContent ?? (
                  <span>
                    <VerifiedIdentityIcon dark /> verified
                  </span>
                )}
              </button>
            </div>
          )
        )
        .with([P._, false], () => (
          <button
            onClick={() => show({ onError, onCancel, onComplete }, !!suppressToast)}
            className={beginBtnClassName ?? "block btn btn-sm btn-primary"}
          >
            {beginBtnContent ?? "Verify Now"}
          </button>
        ))
        .exhaustive()}
    </>
  );
}

function useRevokeVerification() {
  const [revoke, { loading }] = useRevokeVerifications430Mutation();
  const my_id = useMyId();
  const fxn = React.useCallback(async () => {
    if (!my_id) return;
    const res = confirm("Are you sure you would like to revoke your verification?");
    if (!res) return;
    const res2 = confirm("This is not reversible");
    if (!res2) return;
    await revoke({
      variables: { my_id },
      refetchQueries: [GqlOps.Query.IdentityStatus, GqlOps.Query.BioFromSlug],
    });
  }, [revoke]);
  return { revoke: fxn, loading };
}
