import { ProfileList, ProfileListItem, ProfileListProps } from "../../widgets/profile-list";
import {
  Relationship_Types_Enum,
  useInboundRelationshipRequestsForUserV410Query,
} from "shared/dist/__generated__/components";
import { classNames, filterNulls } from "shared/dist/util";

import { IconButtonWrapper } from "../../widgets/icon-button-wrapper";
import { MultipleTypeRelationshipControlButton } from "../../widgets/add-relationship-button";
import React from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { allRoutes } from "../../util/routes";
import { navHeaderState } from "shared-web-react/dist/widgets/nav-stack-view";
import { useMyId } from "shared/dist/auth-data";

type RequestListItem = {
  slug: string;
  screen_name: string;
};
type RequestListGroupProps = {
  title: String;
  open?: boolean;
  items: Array<RequestListItem>;
};
function RequestListGroup({ open, title, items }: RequestListGroupProps) {
  const mkRoute: ProfileListProps["mkRoute"] = React.useCallback(
    ({ slug, ...other }: ProfileListItem) =>
      slug
        ? {
            to: allRoutes.PROFILE.buildPath({ slug }),
            state: navHeaderState("Back to requests"),
          }
        : null,
    []
  );
  const mkActions: ProfileListProps["mkActions"] = React.useCallback(
    ({ slug, ...other }: ProfileListItem) => {
      return [
        slug
          ? {
              component: (
                <IconButtonWrapper className="!p-[0.15rem] text-xl text-primary bg-primary-content">
                  <MultipleTypeRelationshipControlButton
                    slug={slug}
                    rel_types={[Relationship_Types_Enum.Like, Relationship_Types_Enum.Friend]}
                  />
                </IconButtonWrapper>
              ),
            }
          : null,
      ];
    },
    []
  );
  return (
    <details className="join-item collapse  " open={open}>
      <summary className={classNames("w-full")}>
        <div className="font-medium px-4 flex flex-row flex-nowrap justify-between w-full">
          <div className={classNames("flex-1")}>{title}</div>
          <div className={classNames("flex-0")}>({items.length})</div>
        </div>
      </summary>
      <div className="max-h-[50vh] overflow-auto collapse-content">
        <ProfileList
          {...{ mkActions, mkRoute, columnCountOverride: 1 }}
          profiles={items.map((i) => ({
            screenName: i.screen_name,
            slug: i.slug,
          }))}
        />
      </div>
    </details>
  );
}

export function RequestList(): JSX.Element {
  const my_id = useMyId();
  const { data, loading } = useInboundRelationshipRequestsForUserV410Query({
    skip: !my_id,
    variables: { types: ["like", "friend"], my_id: my_id! },
  });
  console.log(
    "🚀 ~ file: request-list.tsx:90 ~ RequestList ~ data:",
    JSON.stringify(data?.relationships_union, null, "  ")
  );
  const [likes, friends]: [Array<RequestListItem>, Array<RequestListItem>] = React.useMemo(
    () => [
      filterNulls(
        data?.relationships_union
          ?.filter((e) => e.type === "like")
          ?.map((e) =>
            e.snd_user_summary?.slug && e.snd_user_summary?.screen_name
              ? {
                  slug: e.snd_user_summary.slug,
                  screen_name: e.snd_user_summary.screen_name,
                }
              : null
          )
      ),
      filterNulls(
        data?.relationships_union
          ?.filter((e) => e.type === "friend")
          ?.map((e) =>
            e.snd_user_summary?.slug && e.snd_user_summary?.screen_name
              ? {
                  slug: e.snd_user_summary.slug,
                  screen_name: e.snd_user_summary.screen_name,
                }
              : null
          )
      ),
    ],
    [data]
  );
  return (
    <div
      className={classNames("flex justify-start items-stretch  flex-col join join-vertical gap-4")}
    >
      {loading ? (
        <SpinnerCentered />
      ) : (
        <>
          <RequestListGroup title="Incoming Friend Requests" items={friends} open />
          <RequestListGroup title="Incoming Likes" items={likes} open />
        </>
      )}
      {/* <RequestListGroup title="Recently Accepted" items={[] as any} /> */}
      {/* <RequestListGroup title="Recently Declined" items={[] as any} /> */}
    </div>
  );
}
