import { Link, useNavigate } from "react-router-dom";
import { ReportType, useBlockUserById } from "../screens/user-page/settings/safety";
import { useBlockUserMutation, useUserIdFromSlugQuery } from "shared/dist/__generated__/components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PortalDialog } from "shared-web-react/dist/widgets/confirm-provider";
import React from "react";
import { SpinnerButton } from "./spinner-button";
import { VouchControl } from "../screens/user-page/vouching/vouch-controls";
import { allRoutes } from "../util/routes";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useMySlug } from "../screens/bio/bio-settings";

export type EllipsisActionsProps = {
  reportData?: null | { reportType: ReportType; reportItemId: string };
  className?: string;
  slug?: string;
  skip?: boolean;
};

/**
 * EllipsisActions
 * @param {EllipsisActionsProps} props
 * @param {ReportType} props.reportType if the actions should make some kind of report
 * @param {string} props.slug if the actions should include blocking a user
 * @param {boolean} props.skip allow this to be "noop'ed" out so we can disable it programmatically, i.e. for chat bubbles "from me"
 * @param {null | React.ReactNode} props.children use other content for the ellipsis instead of the 3 dots
 * @returns
 */

export function EllipsisActions({
  slug,
  reportData,
  children,
  className,
  skip,
}: React.PropsWithChildren<EllipsisActionsProps>): JSX.Element {
  const [showDialog, setShowDialog] = React.useState(false);
  const mySlug = useMySlug()?.slug;
  const getIdQuery = useUserIdFromSlugQuery({
    skip: !slug,
    fetchPolicy: "cache-first",
    variables: { slug: slug! },
  });
  const slugUserId = getIdQuery?.data?.user_summaries?.[0]?.id;
  const ref = React.createRef<HTMLDialogElement>();
  ref?.current?.showModal;
  const block = useBlockUserById(slug, slugUserId);
  React.useEffect(
    () => (showDialog ? ref?.current?.showModal?.() : ref?.current?.close?.()),
    [showDialog]
  );
  return (
    <>
      <button
        className={clsx(
          "EllipsisActions z-50",
          className,
          skip && "SkippedEllipsisActions",
          children && "text-left"
        )}
        onClick={(e) => {
          if (skip) return;
          setShowDialog(!showDialog);
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {children || <FontAwesomeIcon icon={faEllipsis} fixedWidth className="z-50" />}
      </button>
      <PortalDialog
        onCancel={() => ref?.current?.close?.()}
        ref={ref}
        className={classNames(showDialog ? "" : "hidden")}
      >
        <div className="flex flex-col gap-2 items-stretch justify-center">
          <h3 className="font-bold mb-2 text-lg">User Actions:</h3>
          {slug && slug !== mySlug && (
            <VouchControl
              size="full"
              slug={slug}
              // onDismiss={() => setTimeout(() => confirm(null), 1000)}
            />
          )}
          {reportData && (
            <Link
              className={clsx("w-full flex-1 btn btn-secondary ")}
              to={allRoutes.REPORT_MEDIA.buildPath({
                reportType: reportData.reportType,
                media_upload_id: reportData.reportItemId,
              })}
            >
              Report Content
            </Link>
          )}
          {slug && slug !== mySlug && (
            <SpinnerButton
              loading={getIdQuery?.loading}
              disabled={!slugUserId}
              className={clsx("w-full flex-1 btn  btn-warning")}
              onClick={(e) => {
                e.stopPropagation();
                block();
              }}
            >
              Block @{slug}
            </SpinnerButton>
          )}
          <button className="btn btn-neutral" onClick={() => ref?.current?.close?.()}>
            Cancel
          </button>
        </div>
      </PortalDialog>
    </>
  );
}
