import { ObBirthdayPicker } from "../../widgets/pickers/birthday-picker";
import { ObGenderPicker } from "shared-web-react/dist/widgets/gender-picker";
import { ObOrientationPicker } from "../../widgets/bio/orientation-picker";
import { ObScreen } from "./widgets";
import React from "react";
import { classNames } from "shared/dist/util";
import { useMyId } from "shared/dist/auth-data";

export function ObBirthday({
  setDisableButton,
}: {
  setDisableButton: (b: boolean) => void;
}): JSX.Element {
  const [_, setFakeLoading] = React.useState(true);
  React.useEffect(() => {
    const tm = setTimeout(() => {
      setFakeLoading(false);
    }, 2500);
    return () => tm && clearTimeout(tm);
  });
  return (
    <ObScreen title="When were you born?">
      <div className={classNames("flex flex-col h-full w-full gap-2 pb-4 items-stretch")}>
        <ObBirthdayPicker setDisableButton={setDisableButton} />
      </div>
    </ObScreen>
  );
}

export function ObGender({
  setDisableButton,
}: {
  setLoading: (state: boolean) => void;
  setDisableButton: (state: boolean) => void;
}): JSX.Element {
  const myId = useMyId();

  return (
    <ObScreen title="What's your gender?">
      <div className="flex flex-col min-h-0 w-full">
        <div className="flex-grow overflow-y-auto">
          {" "}
          <ObGenderPicker
            forUserId={myId}
            defaultGenderId="gender_man"
            // buttonClassName="!pl-2"
            // suppressToast
            setDisabled={setDisableButton}
          />
        </div>
      </div>
    </ObScreen>
  );
}

// There may be a better place or this component
export function ObOrientation({
  setDisableButton,
}: {
  setDisableButton: (b: boolean) => void;
}): JSX.Element {
  return (
    <ObScreen title="What's your Orientation?">
      <div className="flex flex-col min-h-0 w-full">
        <div className="flex-grow overflow-y-auto">
          <ObOrientationPicker setDisableButton={setDisableButton} />
        </div>
      </div>
    </ObScreen>
  );
}
