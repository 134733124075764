import { ContentBox, ContentBoxInfoLine } from "../../../../widgets/content-box";
import {
  GqlOps,
  Relationship_Types_Enum,
  useMyRelationshipsShortV410Query,
  usePartnershipLinksQuery,
  useRegeneratePartnershipLinkMutation,
} from "shared/dist/__generated__/components";
import { H4, H5 } from "shared-web-react/dist/widgets/text";
import { ProfileListHorizontal, ProfileListItem } from "../../../../widgets/profile-list";
import { allRoutes, useMustBeLoggedIn } from "../../../../util/routes";
import { faCircleI, faUpFromBracket } from "@fortawesome/pro-solid-svg-icons";
import { useMkUrl, useOrigin } from "shared/dist/util/env";

import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { filterNulls } from "shared/dist/util";
import { useShare } from "../../../../widgets/share";

export function PartnerSettings(): JSX.Element {
  const [link, _regenerateLink] = usePartnerLink();
  return (
    // <ContentBox className="px-3 pb-1">
    <div>
      <H4>Partner Settings</H4>
      {/* <p className="font-semibold text-lg">Partner Settings</p> */}
      {link && (
        <dl>
          <ContentBoxInfoLine>
            <div className="tooltip" data-tip="Share this link to add romatic partners on Candid">
              <FontAwesomeIcon icon={faCircleI} className="mr-1" />
              Add a partner:
            </div>
            <PartnerLink link={link} />
          </ContentBoxInfoLine>
        </dl>
      )}
      <PartnerList />
    </div>
    // </ContentBox>
  );
}

function PartnerLink({ link }: { link: string }): JSX.Element {
  const share = useShare();
  const copy = () => {
    const text = `I want to add you as my relationship partner on Candid! ${link}`;
    console.log("🚀 ~ file: partner-settings.tsx:53 ~ copy ~ link:", link);
    share({
      title: `Candid!`,
      text,
      fallbackText: text,
    });
  };
  return (
    <span
      onClick={copy}
      className="btn btn-xs btn-primary cursor-pointer whitespace-nowrap min-w-0 text-right text-ellipsis overflow-hidden"
    >
      <FontAwesomeIcon icon={faUpFromBracket} className="mr-2" />
      Share Link
    </span>
  );
}

function PartnerList({}: {}): JSX.Element {
  const my_id = useMustBeLoggedIn();
  const { data, loading } = useMyRelationshipsShortV410Query({
    variables: { my_id: my_id!, type: Relationship_Types_Enum.Partner },
    skip: !my_id,
  });
  const partners = filterNulls(
    (data?.relationships_union ?? []).map((p) =>
      p?.snd_user_summary?.slug && p?.snd_user_summary?.screen_name
        ? {
            slug: p?.snd_user_summary?.slug,
            screenName: p?.snd_user_summary?.screen_name,
          }
        : null
    )
  );
  if (loading) {
    return <SpinnerCentered />;
  }
  return partners.length ? (
    <>
      <div className="divider my-0" />
      <ProfileListHorizontal columnLayout profiles={partners} />
    </>
  ) : (
    <></>
  );
}

function usePartnerLink(): [string | null, () => Promise<void>] {
  const my_id = useMustBeLoggedIn() ?? "";
  const { data, loading, called } = usePartnershipLinksQuery({
    variables: { my_id },
  });
  const [mutate] = useRegeneratePartnershipLinkMutation();
  React.useEffect(() => {
    if (loading) { return; } // prettier-ignore
    if (data === undefined) { return; } //  prettier-ignore
    if (data?.relationship_invitations_by_pk === null) {
      mutate({
        refetchQueries: [GqlOps.Query.PartnershipLinks],
        variables: { my_id },
      });
    }
  }, [data, loading, called]);
  const invitation_code = data?.relationship_invitations_by_pk?.invitation_code;
  const mkUrl = useMkUrl();
  const link = invitation_code
    ? mkUrl(
        allRoutes.RELATIONSHIP_INVITE.buildPath({
          invitation_code,
        })
      )
    : null;
  return [
    link,
    async () => {
      mutate({ variables: { my_id } });
    },
  ];
}
