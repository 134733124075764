import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import React, { HTMLProps } from "react";
import {
  faCakeCandles,
  faCamera,
  faLock,
  faPenToSquare,
  faPeopleGroup,
  faRecycle,
  faSquarePen,
} from "@fortawesome/pro-solid-svg-icons";
import { useRefetchBioV2, useRefetchMyBio } from "../../screens/bio/bio-settings";

import { ImageUploader } from "../../widgets/images";
import { IndicatorNavLink } from "../indicator-nav-link";
import { JoinEventDialog } from "../../screens/other-pages/event-detail";
import { StatusUpdate } from "../../screens/user-page/profile/status-update";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { createPortal } from "react-dom";
import { faCirclePlus } from "@fortawesome/pro-duotone-svg-icons";
import { useApolloClient } from "shared/dist/apollo";
import { useIsDev } from "shared/dist/util/env";
import { useMyId } from "shared/dist/auth-data";
import { useNavigate } from "react-router-dom";
import { useRefetchMyBioSummary } from "../../screens/user-page/common";

type NavbarActions = { onClick: HTMLProps<HTMLAnchorElement>["onClick"] } | { to: string } | {};
export type NavbarDropdownProps = {
  children: React.ReactNode;
  status?: "coming soon" | "beta";
} & Pick<FontAwesomeIconProps, "icon"> &
  NavbarActions;

export function NavbarDropdownItem({ status, icon, children, ...props }: NavbarDropdownProps) {
  if ("onClick" in props) {
    return (
      <li>
        <a
          onClick={props.onClick}
          className={classNames(
            "text-base-100 hover:!text-base-200 whitespace-nowrap",
            status === "coming soon" && "opacity-70"
          )}
        >
          <FontAwesomeIcon icon={icon} fixedWidth />
          {children} {status && <span className="italic">{status}</span>}
        </a>
      </li>
    );
  }
  if ("to" in props) {
    return (
      <li>
        <IndicatorNavLink
          indicatorItemClassName="indicator-start top-2 left-3"
          baseClassName="w-full"
          to={props.to}
          className={classNames(
            "whitespace-nowrap",
            "text-base-100 hover:!text-base-200 gap-2 flex justify-start items-center ",
            status === "coming soon" && "opacity-70"
          )}
        >
          <FontAwesomeIcon icon={icon} fixedWidth />
          {children} {status && <span className="italic">{status}</span>}
        </IndicatorNavLink>
      </li>
    );
  }
  return (
    <li>
      <span
        className={classNames(
          "whitespace-nowrap",
          "text-base-100  hover:!text-base-200 gap-2 flex justify-start items-center",
          status === "coming soon" && "opacity-70"
        )}
      >
        <FontAwesomeIcon icon={icon} fixedWidth />
        {children} {status && <span className="italic">{status}</span>}
      </span>
    </li>
  );
}

// Only seems to work on desktop
export function useTriggerAddContentMenu() {
  return () => document.getElementById("add_content_menu_trigger")?.focus();
}

export function AddContentMenu({
  hideMenuLabelsInDesktop,
}: {
  hideMenuLabelsInDesktop?: boolean;
}): JSX.Element {
  // const navigate = useNavigate();
  // const my_id = useMyId();
  // const isDev = useIsDev();
  const [showWidget, setShowWidget] = React.useState<null | "image" | "event">(null);
  // const cache = useApolloClient().cache;
  const refetch = useRefetchMyBio();
  const ref = React.useRef<HTMLLabelElement>(null);

  const refetchV2 = useRefetchBioV2();
  return (
    <>
      <div className={`dropdown dropdown-top lg:dropdown-right `}>
        <label
          ref={ref}
          id="add_content_menu_trigger"
          tabIndex={0}
          className="lg:flex lg:flex-row items-center"
        >
          <FontAwesomeIcon fixedWidth size="2x" icon={faCirclePlus} />
          <span
            className={classNames("max-lg:hidden lg:pl-3", hideMenuLabelsInDesktop && "hidden")}
          >
            Add Content
          </span>
        </label>

        <div
          className={classNames(
            "dropdown-content !fixed max-lg:!bottom-12 max-lg:pb-safe lg:!bottom-0",
            "!left-0 right-0 lg:top-0 flex flex-row z-55-ui-modal bg-transparent",
            "justify-center items-center"
          )}
        >
          <div
            className="modal-backdrop mt-[-2000px] z-10  mb-[-64px] fixed bottom-0 top-0 left-0 right-0 "
            onClick={(e) => {
              e.stopPropagation();
              ref?.current?.blur();
            }}
          />
          <ul
            tabIndex={0}
            className="z-50-toast mb-0 max-md:w-screen space-y-1 whitespace-nowrap text-base menu p-2 lg:shadow-black lg:shadow-2xl mt-5 bg-primary "
          >
            <li>
              <span
                className={clsx(
                  "text-base-100 hover:text-base-200 active:text-base-200 gap-2 flex justify-start items-center",
                  status === "coming soon" && "opacity-70"
                )}
              >
                <FontAwesomeIcon icon={faPenToSquare} fixedWidth />
                <StatusUpdate
                  setCurrentStatus={() =>
                    document.activeElement instanceof HTMLElement && document.activeElement.blur()
                  }
                />
              </span>
            </li>
            <NavbarDropdownItem icon={faCamera} onClick={() => setShowWidget("image")}>
              Add Media
            </NavbarDropdownItem>
            {/* <NavbarDropdownItem
              icon={faCakeCandles}
              status="beta"
              onClick={() => setShowWidget("event")}
            >
              Join Upcoming Event (with code)
            </NavbarDropdownItem>
            <NavbarDropdownItem status="coming soon" icon={faLock}>
              Add Travel
            </NavbarDropdownItem> */}
          </ul>
        </div>
      </div>
      {showWidget === "event" && (
        <div className="absolute">
          <JoinEventDialog
            onCancel={() => setShowWidget(null)}
            onJoin={() => setShowWidget(null)}
          />
        </div>
      )}
      {showWidget === "image" && (
        <div className="absolute">
          <div className="modal cursor-pointer modal-open text-candid-plum-700 !my-0">
            <div className="modal-box">
              <ImageUploader
                onComplete={() => {
                  setShowWidget(null);
                  refetchV2?.();
                  refetch?.();
                }}
                onError={() => {
                  console.log("🚀 ~ file: thumbnails.tsx ~ line 101 ~ onError");
                  setShowWidget(null);
                  // setShowImageUploaderError(true);
                }}
                onDismiss={async () => {
                  setShowWidget(null);
                  // setLoadingImg(true);
                  await new Promise((res) =>
                    setTimeout(() => {
                      refetch?.();
                      // setLoadingImg(false);
                      res(null);
                    }, 100)
                  );
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
