import { classNames } from "shared/dist/util";

export function IconButtonWrapper({
  className,
  children,
}: Pick<React.HTMLAttributes<HTMLSpanElement>, "children" | "className">): JSX.Element {
  return (
    <span
      className={classNames(
        "border p-1 border-candid-purple-700 inline-flex justify-center items-center rounded-md",
        className
      )}
    >
      {children}
    </span>
  );
}
