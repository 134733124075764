import {
  L_Relationship_Type_Enum,
  Relationship_Types_Enum,
  useBioFromSlugLazyQuery,
  useMyRelationshipsShortV410Query,
} from "shared/dist/__generated__/components";
import { Link, useNavigate } from "react-router-dom";
import {
  ProfileList,
  ProfileListItem,
  ProfileListProps,
  profileListItemFromSummary,
} from "../../../widgets/profile-list";
import React, { useState } from "react";
import {
  ZeroScreenActiveFriendsList,
  ZeroScreenMutualFriendsList,
} from "../../../widgets/zero-screens";

import { AddThreadButton } from "../../../widgets/add-thread-button";
import { IconButtonWrapper } from "../../../widgets/icon-button-wrapper";
import { MultipleTypeRelationshipControlButton } from "../../../widgets/add-relationship-button";
import { allRoutes } from "../../../util/routes";
import { filterNulls } from "shared/dist/util";
import { match } from "ts-pattern";
import { useMyId } from "shared/dist/auth-data";
import { useTypedParams } from "react-router-typesafe-routes/dom";

type FriendsListProps = {
  friendType: "active" | "mutual-friends";
};

export function FriendsList({ friendType }: FriendsListProps): JSX.Element {
  const my_id = useMyId();
  const [searchTerm, setSearchTerm] = useState<string | null>();
  // TODO optimize this
  const { slug } = useTypedParams(allRoutes.PROFILE.FRIENDS);
  const [runBioQuery, { data }] = useBioFromSlugLazyQuery();
  React.useEffect(() => {
    slug && runBioQuery({ variables: { slug } });
  }, [slug, runBioQuery]);

  const navigate = useNavigate();
  const profilesFromBio = filterNulls(
    data?.l_bio_public?.relationships
      ?.filter((f) => f.type === L_Relationship_Type_Enum.Friend)
      ?.map((f) => profileListItemFromSummary(f)) ?? []
  );
  const mutualsQueryResult = useMyRelationshipsShortV410Query({
    variables: { my_id: my_id ?? "", type: "friend" },
  });
  const isMutual = React.useCallback(
    ({ slug }: { slug?: null | string }) => {
      if (!slug) return false;
      const myFriendsSlugs =
        mutualsQueryResult.data?.relationships_union.map((r) => r.snd_user_summary?.slug) ?? [];
      return myFriendsSlugs.includes(slug);
    },
    [mutualsQueryResult]
  );
  const profiles = React.useMemo(() => {
    if (friendType !== "mutual-friends") {
      return profilesFromBio;
    }
    return profilesFromBio.filter(isMutual);
  }, [my_id, data, profilesFromBio, mutualsQueryResult]);
  console.log(
    "🚀 ~ file: friends-list.tsx:59 ~ profiles ~ profiles:",
    profiles,
    profilesFromBio,
    data?.l_bio_public
  );

  let sortedProfiles = searchTerm
    ? profiles.filter((bio) => bio.screenName?.toLowerCase()?.includes(searchTerm.toLowerCase()))
    : profiles;

  const isMyPage = data?.l_bio_public?.editable ?? false;
  const mkActions: ProfileListProps["mkActions"] = React.useCallback(
    ({ slug }: ProfileListItem) => {
      return filterNulls([
        slug && isMyPage
          ? {
              component: (
                <AddThreadButton slug={slug}>
                  <div className="btn btn-sm btn-outline">Message</div>
                </AddThreadButton>
              ),
            }
          : null,
        isMyPage || !slug
          ? null
          : {
              component: (
                // <IconButtonWrapper className="!p-[0.15rem] text-xl">
                <MultipleTypeRelationshipControlButton
                  slug={slug}
                  rel_types={[Relationship_Types_Enum.Like, Relationship_Types_Enum.Friend]}
                />
                // </IconButtonWrapper>
              ),
            },
      ]);
    },
    [isMyPage]
  );
  if (!my_id) {
    // shouldn't happen
    slug && navigate(allRoutes.PROFILE.buildPath({ slug }));
    return <></>;
  }
  if (profiles.length === 0) {
    return (
      <div className="flex flex-col h-full w-full overflow-hidden">
        {match(friendType)
          .with("active", () => <ZeroScreenActiveFriendsList />)
          .with("mutual-friends", () => <ZeroScreenMutualFriendsList slug={slug} />)
          .otherwise(() => (
            <></>
          ))}
      </div>
    );
  }
  return (
    <>
      <div className="flex my-2 px-2 grid-cols-2">
        <input
          onChange={(e) => setSearchTerm(e.target.value)}
          type="text"
          placeholder="Search"
          className="FriendsList input  bg-candid-gray-500 input-bordered w-full"
        />
        {/*
        {slug && (
          <Link
            to={allRoutes.PROFILE.INVITES.buildPath({ slug })}
            className="btn bg-warning-content text-primary-content"
          >
            Invite
          </Link>
        )} */}
      </div>
      <div className="px-2">
        <ProfileList mkActions={mkActions} profiles={sortedProfiles} />
      </div>
    </>
  );
}
