import { P, match } from "ts-pattern";
import { faArrowLeft, faUpFromBracket } from "@fortawesome/pro-solid-svg-icons";
import {
  useAddInvitationMutation,
  useMyInvitationsQuery,
  useNewUserToUserInvitationMutation,
} from "shared/dist/__generated__/components";

import { AddThreadButton } from "../../widgets/add-thread-button";
import { Container } from "../../widgets/container";
import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import { Feature_Flags_Enum } from "shared/dist/__generated__/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/pro-regular-svg-icons";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useAsset } from "shared-web-react/dist/util/assets-provider";
import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";
import { useMyId } from "shared/dist/auth-data";
import { useNavigate } from "react-router-dom";
import { useShare } from "../../widgets/share";

export function Invites(): JSX.Element {
  const my_id = useMyId();
  const navigate = useNavigate();
  const share = useShare();
  const addToast = useAddToast();

  const inviteFeatureFlag = useIsFeatureEnabled(Feature_Flags_Enum.UserToUserInviteV00);
  if (!my_id) {
    return (
      <ErrorComp
        caption={
          "Were sorry an error occurred. It has been logged and will be investigated. Please log in and try again."
        }
      />
    );
  }

  const { data, refetch } = useMyInvitationsQuery({
    variables: { user_id: my_id! },
    skip: !my_id,
  });

  const [addInvite] = useNewUserToUserInvitationMutation();
  const invitationData = data?.invitation_usage_and_allowances?.[0];

  if (!invitationData) {
    return <ErrorComp caption={"No invitation data found."} />;
  }

  const totalUsedInvitations =
    invitationData.total_invitation_count - (invitationData.unused_invitation_count ?? 0);

  const userCanInvite = inviteFeatureFlag && invitationData.can_add_invitations;

  const message = `Hey! Here's your invite to join me on Candid. It's invite only, but I have a referral code for you. Just use your phone number to login and enter referral code: `;

  const logoInterlock = useAsset("logoCircleNoPadding");
  return (
    <Container
      size="md"
      className="bg-secondary flex flex-col items-center justify-evenly h-full w-screen m-0"
    >
      <FontAwesomeIcon
        icon={faArrowLeft}
        fixedWidth
        className="absolute left-8 top-[2vh] text-primary-content text-xl"
        onClick={() => navigate(-1)}
      />
      <span className="mt-[7vw] w-4/5 flex flex-col items-center text-center rounded-xl bg-white p-5">
        <img src={logoInterlock} className="w-20 pt-4 pb-6" />
        <p className="text-black text-xl md:text-2xl text-center max-w-md">
          Share Candid with your friends. We’ll expedite their access & add them to your friends
          list automatically.
        </p>
      </span>
      <hr className="border-t-1 border-[secondary] w-4/5" />

      <p className="text-white text-xl md:text-2xl text-center max-w-md">
        {`${totalUsedInvitations}/${invitationData?.total_allowed}`} INVITES CLAIMED
      </p>
      {!userCanInvite && invitationData?.unused_invitation_count >= 1 && (
        <p className="text-warning text-sm md:text-md text-center max-w-md">
          You can have a total of {invitationData?.total_unused_allowed}{" "}
          {invitationData?.unused_invitation_count > 1 ? "invites" : "invite"} sent that are unused.
          You currently have{" "}
          {`${invitationData?.unused_invitation_count}/${invitationData?.total_unused_allowed}`}{" "}
          sent unused invites.
        </p>
      )}
      <div className="flex justify-between w-4/5">
        <button
          disabled={!userCanInvite}
          onClick={async () => {
            const { data, errors } = await addInvite();
            if (!data) {
              addToast({
                content: "An unexpected error occurred, please try again",
                color: "error",
              });
              return;
            }
            return match(data?.lm_new_user_to_user_invitation_code)
              .with({ __typename: "L_Simple_Response_Error" }, (response) => {
                addToast({
                  content: response.human_readable_error || "An error occurred, please try again",
                  color: "error",
                });
              })
              .with({ id: P.select() }, async (code) => {
                share({
                  title: `Candid!`,
                  text: message + code + ` \n\n https://candid.bio`,
                  fallbackText: "I want you to check out Candid.",
                });
                await refetch();
              })
              .otherwise(() => {
                errors && console.error(errors);
                addToast({
                  content: "An unexpected error occurred, please try again",
                  color: "error",
                });
              });
          }}
          className="btn flex flex-col px-6 bg-white"
        >
          <FontAwesomeIcon icon={faUpFromBracket} fixedWidth onClick={() => navigate(-1)} />
          Share Invites
        </button>
        <button
          disabled={!userCanInvite}
          onClick={async () => {
            const { data, errors } = await addInvite();
            if (!data) {
              addToast({
                content: "An unexpected error occurred, please try again",
                color: "error",
              });
              return;
            }
            return match(data?.lm_new_user_to_user_invitation_code)
              .with({ __typename: "L_Simple_Response_Error" }, (response) => {
                addToast({
                  content: response.human_readable_error || "An error occurred, please try again",
                  color: "error",
                });
              })
              .with({ id: P.select() }, async (code) => {
                const smsLink = `sms:?&body=${encodeURIComponent(
                  message + `${code} \n\n https://candid.bio`
                )}`;
                window.open(smsLink, "_blank");
                await refetch();
              })
              .otherwise(() => {
                errors && console.error(errors);
                addToast({
                  content: "An unexpected error occurred, please try again",
                  color: "error",
                });
              });
          }}
          className="btn flex flex-col px-6 bg-white"
        >
          <FontAwesomeIcon icon={faPaperPlane} fixedWidth />
          Send Message
        </button>
      </div>
      <a
        href="https://trustcandid.typeform.com/invites"
        className="text-white text-xs md:text-xl underline text-center bottom-5"
      >
        Request more invites?
      </a>
    </Container>
  );
}
