import { Link, LinkProps } from "react-router-dom";

export function DisableableLink({
  disabled,
  onDisabledClick,
  onClick,
  ...props
}: LinkProps & {
  disabled?: boolean;
  onDisabledClick?: () => void;
}): JSX.Element {
  return (
    <Link
      {...props}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
          onDisabledClick?.();
        } else {
          onClick?.(e);
        }
      }}
    />
  );
}
