import {
  GqlOps,
  useBlockUserMutation,
  useBlockedUsersQuery,
  useHiddenDiscoveryUsers431Query,
  useHideDiscoveryResult300Mutation,
  useNewUserReportMutation,
  useUnblockUserMutation,
  useUserIdFromSlugLazyQuery,
  useUserIdFromSlugQuery,
} from "shared/dist/__generated__/components";
import { H3, H4 } from "shared-web-react/dist/widgets/text";
import { Maybe, filterNulls } from "shared/dist/util";
import { ProfileList, ProfileListItem } from "../../../widgets/profile-list";
import { useNavigate, useParams } from "react-router-dom";

import { Container } from "../../../widgets/container";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paginator } from "shared-web-react/dist/widgets/paginator";
import { ParamType } from "react-router-typesafe-routes";
import React from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { VouchControl } from "../vouching/vouch-controls";
import { allRoutes } from "../../../util/routes";
import clsx from "clsx";
import { faHeart } from "@fortawesome/pro-solid-svg-icons";
import { match } from "ts-pattern";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { useMyId } from "shared/dist/auth-data";

const refetchQueries = [
  GqlOps.Query.BlockedUsers,
  GqlOps.Query.UserThreads,
  GqlOps.Query.UserThreads412,
  "BioFromSlug",
];

export function useBlockUser(slug: Maybe<string>) {
  const getIdQuery = useUserIdFromSlugQuery({
    skip: !slug,
    fetchPolicy: "cache-first",
    variables: { slug: slug! },
  });
  const slugUserId = getIdQuery?.data?.user_summaries?.[0]?.id;
  return useBlockUserById(slug, slugUserId);
}

export function useBlockUserById(slug: Maybe<string>, userToBlockId: Maybe<string>) {
  const [blockUserMutation] = useBlockUserMutation();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const addToast = useAddToast();
  const block = React.useCallback(async () => {
    if (!userToBlockId || !slug) return;
    const result = await blockUserMutation({
      refetchQueries: [
        GqlOps.Query.BlockedUsers,
        GqlOps.Query.UserThreads,
        GqlOps.Query.BioFromSlug,
      ],
      variables: { user_to_block: userToBlockId },
    });
    if (result?.data?.lm_relationship_disconnect_all?.__typename === "L_Simple_Response_Error") {
      addToast({
        content: `Error removing relationships with @${slug}, please try again`,
        color: "warning",
      });
      return;
    }
    if (result?.errors) {
      addToast({
        content: "Error reaching our server, please try again",
        color: "warning",
      });
      return;
    }
    navigate(allRoutes.SETTINGS.ACCOUNT.buildPath({}));
  }, [blockUserMutation, addToast, navigate]);
  return () => {
    confirm({
      title: "Block @" + slug + "?",
      content: "This will disconnect any friendships or partnerships you have with them",
      okButtonContent: "Yes, Block",
      onOk: block,
    });
  };
}

const limit = 50;
export function BlockedUsers(): JSX.Element {
  const addToast = useAddToast();
  const my_id = useMyId()!;
  const [unblock] = useUnblockUserMutation();
  const [page, setPage] = React.useState(1);
  const { data, loading } = useBlockedUsersQuery({
    skip: !my_id,
    variables: { limit, offset: (page - 1) * limit, my_id },
  });
  const [getIdQuery] = useUserIdFromSlugLazyQuery();
  const count = data?.blocked_users_aggregate?.aggregate?.count ?? 0;
  const pageCount = Math.ceil(count / limit) ?? 1;
  const unblockUser = React.useCallback(
    async (slug: string) => {
      const slugResult = (await getIdQuery({ variables: { slug } }))?.data?.user_summaries?.[0]?.id;
      if (!slugResult) {
        addToast({
          content: "Could not find user: @" + slug,
          color: "warning",
        });
        return;
      }
      const result = await unblock({
        refetchQueries,
        variables: { my_id, user_to_unblock: slugResult },
      });
      if (result?.errors) {
        addToast({
          content: "Error reaching our server, please try again",
          color: "warning",
        });
        return;
      }
    },
    [getIdQuery, unblock]
  );
  const userList: Array<ProfileListItem> = React.useMemo(
    () =>
      filterNulls(
        data?.blocked_users?.map((u) =>
          u.blocked_user_summary
            ? {
                screenName: u.blocked_user_summary.screen_name ?? "unknown",
                slug: u.blocked_user_summary.slug,
                caption: `Blocked on ${DateTime.fromISO(u.created_at).toLocaleString(
                  DateTime.DATETIME_SHORT
                )}`,
              }
            : null
        )
      ),
    [data]
  );
  return (
    <div>
      <H3>Blocked Users:</H3>
      <p className={clsx("italic")}>
        These users will be hidden from you and cannot interact with you.
      </p>
      {loading && !data?.blocked_users && <SpinnerCentered />}
      {count > limit && (
        <Paginator className="mb-3" btnClassName="btn-sm" {...{ pageCount, setPage, page }} />
      )}
      <ProfileList
        profiles={userList}
        mkActions={() => [
          {
            label: "unblock",
            handler: async (item) => item.slug && unblockUser(item.slug!),
          },
        ]}
      />
    </div>
  );
}

export function DiscoveryHiddenUsers(): JSX.Element {
  const addToast = useAddToast();
  const my_id = useMyId()!;
  const [hideMutation] = useHideDiscoveryResult300Mutation();
  const [page, setPage] = React.useState(1);
  const { data, loading } = useHiddenDiscoveryUsers431Query({
    skip: !my_id,
    variables: { limit, offset: (page - 1) * limit, my_id },
  });
  const [getIdQuery] = useUserIdFromSlugLazyQuery();
  const count = data?.agg?.aggregate?.count ?? 0;
  const pageCount = count > 0 ? Math.ceil(count / limit) ?? 1 : 0;
  const unhideUser = React.useCallback(
    async (slug: string) => {
      const result = await hideMutation({
        refetchQueries: [
          GqlOps.Query.HiddenDiscoveryUsers431,
          GqlOps.Query.DiscoveryResults300,
          GqlOps.Query.NewsFeed440,
        ],
        variables: { unhide: true, slug } /* cSpell:disable-line */,
      });
      if (result?.errors) {
        addToast({
          content: "Error reaching our server, please try again",
          color: "warning",
        });
        return;
      }
    },
    [getIdQuery, hideMutation]
  );
  const userList: Array<ProfileListItem> = React.useMemo(
    () =>
      filterNulls(
        data?.users?.map((u) =>
          u.hidden_user_summary
            ? {
                screenName: u.hidden_user_summary.screen_name ?? "unknown",
                slug: u.hidden_user_summary.slug,
                caption: `Hidden on ${DateTime.fromISO(u.created_at).toLocaleString(
                  DateTime.DATETIME_SHORT
                )}`,
              }
            : null
        )
      ),
    [data]
  );
  return (
    <div>
      <H3>Hidden Users:</H3>
      <p className="italic">
        You can still interact with these users but they won't show up in your discovery grid or
        news feed.
      </p>
      {loading && !data?.users && <SpinnerCentered />}
      {count > limit && (
        <Paginator className="mb-3" btnClassName="btn-sm" {...{ pageCount, setPage, page }} />
      )}
      <ProfileList
        profiles={userList}
        mkActions={() => [
          {
            label: "unhide",
            handler: async (item) => item.slug && unhideUser(item.slug!),
          },
        ]}
      />
    </div>
  );
}

export type ReportType = "image" | "status" | "message";

export function ReportTypeParam(): ParamType<ReportType, string> {
  return {
    getPlainParam: (value: string) => value,
    getTypedParam: (value: string | undefined): ReportType =>
      match(value)
        .returnType<ReportType>()
        .with("image", () => "image")
        .with("message", () => "message")
        .with("status", () => "status")
        .otherwise(() => {
          throw new Error("Bad report type: " + value);
        }),
  };
}

/** hook which gives a function to send a user report */
export function useUserReportFxn() {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const addToast = useAddToast();
  const my_id = useMyId()!;
  const [newUserReportMutation] = useNewUserReportMutation();
  const { media_upload_id, reportType } = useParams();

  const report = React.useCallback(
    async (reportType: ReportType, reason: string, other: string) => {
      const reportItem = match(reportType)
        .with("image", () => ({ reported_media_upload_id: media_upload_id }))
        .with("status", () => ({ reported_thread_status_update_id: media_upload_id }))
        .with("message", () => ({ reported_thread_message_id: media_upload_id }))
        .exhaustive();
      const result = await newUserReportMutation({
        variables: { owner_id: my_id, reason, other, ...reportItem },
      });

      if (result?.data?.insert_user_reports_one?.__typename !== "user_reports") {
        addToast({
          content: `Error creating report, please try again`,
          color: "warning",
        });
        return;
      }

      if (result?.errors) {
        addToast({
          content: "Error reaching our server, please try again",
          color: "warning",
        });
        return;
      }

      addToast({
        color: "info",
        content: "We have received your report and a member of our staff will investigate.",
      });

      navigate(-1);
    },
    [useNewUserReportMutation, addToast, reportType]
  );

  return (reportType: ReportType, reason: string, other: string) => {
    confirm({
      title: "Report content for reason: " + reportType + "?",
      content: null,
      okButtonContent: "Confirm Report",
      onOk: () => report(reportType, reason, other),
    });
  };
}

export type UserReportOpts = {
  showAdditionalUserActions?: boolean;
};

export const userReportConfirm = (opts?: UserReportOpts) => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [getIdQuery] = useUserIdFromSlugLazyQuery();
  const [blockUserMutation] = useBlockUserMutation();
  const addToast = useAddToast();

  const block = React.useCallback(
    async (slug: string) => {
      const slugQueryResult = (await getIdQuery({ variables: { slug } }))?.data
        ?.user_summaries?.[0];
      const userId = slugQueryResult?.id;
      if (!slugQueryResult || !userId) {
        addToast({
          content: "Could not find user: @" + slug,
          color: "warning",
        });
        return;
      }
      const result = await blockUserMutation({
        refetchQueries,
        variables: { user_to_block: userId },
      });
      if (result?.data?.lm_relationship_disconnect_all?.__typename === "L_Simple_Response_Error") {
        addToast({
          content: `Error removing relationships with @${slug}, please try again`,
          color: "warning",
        });
        return;
      }
      if (result?.errors) {
        addToast({
          content: "Error reaching our server, please try again",
          color: "warning",
        });
        return;
      }
      navigate(allRoutes.SETTINGS.ACCOUNT.buildPath({}));
    },
    [getIdQuery, blockUserMutation, addToast, navigate]
  );

  return (
    reportType:
      | "message"
      | "L_News_Feed_Item_Event"
      | "L_News_Feed_Item_Media"
      | "L_News_Feed_Item_Media_Reaction"
      | "L_News_Feed_Item_Mention"
      | "L_News_Feed_Item_Message_Reaction"
      | "L_News_Feed_Item_Status"
      | "L_News_Feed_Item_Status_Reaction",
    reportedItemItem: string,
    slug: string
  ) => {
    const dbReportType = match(reportType)
      .returnType<null | "message" | "image" | "status">()
      .with("message", () => "message")
      .with("L_News_Feed_Item_Media", () => "image")
      .with("L_News_Feed_Item_Status", () => "status")
      .otherwise(() => null);

    (confirm as any)({
      title: opts?.showAdditionalUserActions ? "User Actions" : "Report Content",
      content: null,
      actions: [
        ...(opts?.showAdditionalUserActions
          ? [
              {
                btn: (
                  <React.Fragment>
                    <VouchControl
                      size="full"
                      slug={slug}
                      // onDismiss={() => setTimeout(() => confirm(null), 1000)}
                    />
                  </React.Fragment>
                ),
                // onClick: async () => alert("great"),
                // className: "btn-success",
              },
            ]
          : []),
        ...(dbReportType
          ? [
              {
                btnContent: "Report Content",
                onClick: async () =>
                  navigate(
                    allRoutes.REPORT_MEDIA.buildPath({
                      reportType: dbReportType,
                      media_upload_id: reportedItemItem,
                    })
                  ),
                className: "btn-secondary",
              },
            ]
          : []),
        {
          btnContent: "Block User",
          className: "btn-warning",
          onClick: async () =>
            confirm({
              title: "Block @" + slug + "?",
              content: "This will disconnect any friendships or partnerships you have with them",
              okButtonContent: "Yes, Block",
              onOk: () => block(slug),
            }),
        },
      ],
    });
  };
};
