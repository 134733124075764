import { BioFromSlugContext, useBioAndSlug } from "../bio-context";
import {
  Feature_Flags_Enum,
  L_Bio_Access_Denied_Reason_Enum,
  useBioFromSlugQuery,
} from "shared/dist/__generated__/components";
import { Link, Outlet } from "react-router-dom";
import { UserPageTab, UserPageTabLabel } from "../../../widgets/tabs";
import { faBook, faCamera, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";

import { BioBasicsV1 } from "../bio-basics";
import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H4 } from "shared-web-react/dist/widgets/text";
import { ProfilePageV2 } from "./profile-page-v2";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
// import { useMyId } from "shared/dist/auth-data";
import { allRoutes } from "../../../util/routes";
import clsx from "clsx";
import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";
import { useNavigateToLogin } from "../../../util/redirects";

function BioAccessDenied({ reason }: { reason: L_Bio_Access_Denied_Reason_Enum }): JSX.Element {
  const navigateToLogin = useNavigateToLogin();
  return (
    <div className="fixed top-0 left-0 bottom-0 right-0 w-full h-full flex justify-center items-center p-4 ">
      <div className="my-[10vh] text-center p-6 card bg-accent max-w-[25rem] flex flex-col gap-2 rounded-xl">
        <H4 className="">
          <FontAwesomeIcon icon={faEyeSlash} />
        </H4>
        <H4>Access denied:</H4>
        {reason === L_Bio_Access_Denied_Reason_Enum.LoginAndVerificationRequired && (
          <>
            <p>You must login and then verify your account to view this profile</p>
            <a className="btn btn-primary" onClick={navigateToLogin}>
              Login
            </a>
          </>
        )}
        {reason === L_Bio_Access_Denied_Reason_Enum.LoginRequired && (
          <>
            <p>You must login to view this profile</p>
            <a className="btn btn-primary" onClick={navigateToLogin}>
              Login
            </a>
          </>
        )}
        {reason === L_Bio_Access_Denied_Reason_Enum.VerificationRequired && (
          <>
            <p>You must verify your account to view this profile</p>
            <Link className="btn btn-primary" to={allRoutes.SETTINGS.PRIVACY.buildPath({})}>
              Go to verifcation
            </Link>
          </>
        )}
        {reason === L_Bio_Access_Denied_Reason_Enum.RequesterBlockedThisUser && (
          <>
            <p>You must unblock this user to view their profile.</p>
            <Link className="btn btn-primary" to={allRoutes.SETTINGS.ACCOUNT.buildPath({})}>
              Go to blocked users
            </Link>
          </>
        )}
        {reason === L_Bio_Access_Denied_Reason_Enum.RequesterIsBlockedByThisUser && (
          <>
            <p>This user has blocked your account</p>
            <Link className="btn btn-primary" to={allRoutes.HOME.buildPath({})}>
              Ok
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

export function ProfilePage(): JSX.Element {
  const hasV2 = useIsFeatureEnabled(Feature_Flags_Enum.ProfileAndDiscoveryV02);
  return hasV2 ? <ProfilePageV2 /> : <ProfilePageV1 />;
}

export function ProfilePageV1(): JSX.Element {
  const bioAndSlug = useBioAndSlug();
  const [slug, { data, loading }] = bioAndSlug;
  console.log("🚀 ~ file: profile-page.tsx:123 ~ ProfilePage ~ slug:", slug);
  if (!slug) {
    console.error("Missing slug");
  }
  if (!slug) {
    return <ErrorComp caption={"Missing Bio"} />;
  }
  const bio = data?.l_bio_public;
  if (!bio) {
    if (loading) {
      return <SpinnerCentered />;
    }
    return <ErrorComp caption={`404: We're sorry, but can't find @${slug}'s profile...`} />;
  }
  const editable = data?.l_bio_public?.editable ?? false;
  return (
    <div className=" max-w-screen ">
      <div className="relative">
        <div className="absolute bg-white w-screen top-0 bottom-0"></div>
        <BioBasicsV1
          screen_name={bio.user.screen_name}
          profile_image_id={bio.user.profile_image_id ?? undefined}
          editable={editable}
          verified={bio.details.identity_verified ?? false}
          status={bio?.current_status}
          slug={slug}
          stat_one_label={data?.l_bio_public?.details.stat_one_label ?? ""}
          stat_one_value={data?.l_bio_public?.details.stat_one_value ?? 0}
          stat_two_label={data?.l_bio_public?.details.stat_two_label ?? ""}
          stat_two_value={data?.l_bio_public?.details.stat_two_value ?? 0}
        />
        <div className="tabs-xs md:tabs-sm lg:tabs-md tabs-bordered tabs flex w-full mt-2 flex-nowrap justify-center pb-1 ">
          {/* <div className="tabs"> */}
          <UserPageTab hideIndicator route={allRoutes.PROFILE.buildPath({ slug })}>
            <FontAwesomeIcon icon={faCamera} />
            <UserPageTabLabel>Media</UserPageTabLabel>
          </UserPageTab>
          {/* {editable && (
            <UserPageTab route={allRoutes.PROFILE.EVENTS.buildPath({ slug })}>
              <FontAwesomeIcon icon={faCakeCandles} />
              <UserPageTabLabel>my events</UserPageTabLabel>
            </UserPageTab>
          )} */}
          <UserPageTab route={allRoutes.PROFILE.ABOUT.buildPath({ slug })}>
            <FontAwesomeIcon icon={faBook} />
            <UserPageTabLabel>About Me</UserPageTabLabel>
          </UserPageTab>
        </div>
      </div>
      {bio.access_denied_reason ? (
        <BioAccessDenied reason={bio.access_denied_reason} />
      ) : (
        <BioFromSlugContext.Provider
          value={bioAndSlug as [string, ReturnType<typeof useBioFromSlugQuery>]}
        >
          <Outlet />
        </BioFromSlugContext.Provider>
      )}
    </div>
  );
}
