import { Container } from "../../widgets/container";
import { Link } from "react-router-dom";
import { allRoutes } from "../../util/routes";
import logoPurple from "../../assets/neon-logos/candid-neon-purple.png";

export function LandingFooter(): JSX.Element {
  return (
    <div className="py-8 w-full px-4">
      <Container size="xl">
        <div className="relative w-20 sm:w-64">
          <img src={logoPurple} />
        </div>
        <div className="flex-col lg:flex-row mt-4 flex text-sm text-candid-gray-200">
          <div className="flex-0">
            <span className="text-candid-gray-500">
              © Principled Software Inc. DBA Candid 2023. All Rights Reserved
            </span>
          </div>
          <div className="flex-1 max-lg:hidden"></div>
          <div className="sm:w-2/5 flex justify-between w-3/5 ">
            <div className="flex-0 underline">
              <a href="mailto:info@trustcandid.com">Contact Us</a>
            </div>
            <div className="flex-0 underline">
              <Link
                // className={`btn max-lg:btn-sm btn-primary rounded-full capitalize`}
                to={allRoutes.terms.buildPath({})}
              >
                Terms
              </Link>
            </div>
            <div className="flex-0 underline">
              <Link
                // className={`btn max-lg:btn-sm btn-primary rounded-full capitalize`}
                to={allRoutes.privacy.buildPath({})}
              >
                Privacy
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
